import React from 'react';
import '../../CSS/Spinner.css';

export function Spinner() {
  return (
    <div className="overlay">
      <div className="loading-spinner "></div>
    </div>
  );
}

export function ButtonSpinner() {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
