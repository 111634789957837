// import React, { useEffect, useState } from 'react';
// import Swiper from 'swiper/bundle';
// import 'swiper/swiper-bundle.css';
// import './../../CSS/ImageSlider.css';
// import Card from '@mui/material/Card';
// import User from '../../Assests/imgs/user2.png';
// import { getAge } from '../../Utils/getAge';
// import { useNavigate } from 'react-router-dom';
// import { addTopVisitor, getRelationship } from '../../Services/Profile/expressionOfInterest';
// import { getProfilePhoto } from '../../Services/Profile/photoService.js';
// import { useMember } from "../../Contexts/memberContext.js";
// import { ButtonSpinner } from "../Common/Spinner.js";

// const ImageSlider = ({ suggestionProfiles }) => {

//   const [slidesPerView, setSlidesPerView] = useState(2); // Use state to dynamically manage slidesPerView
//   const navigate = useNavigate();
//   const [interestStatus, setInterestStatus] = useState("NONE");
//   const [rearrangedProfiles, setRearrangedProfiles] = useState(suggestionProfiles);
//   const { profile, allConnectedProfiles, allImgReqConnectedProfiles } = useMember();
//   const [loading, setLoading] = useState(false);
//   const [considerCountTotal, setConsiderCountTotal] = useState(0);

//   useEffect(() => {
//     if (profile.partnerCriterion && suggestionProfiles.length > 0) {
//       let considerCount = 0;
//       for (const key in profile.partnerCriterion) {
//         if (Object.keys(profile.partnerCriterion[key]).length > 0) {
//           considerCount++;
//         }
//       }
//       setConsiderCountTotal(considerCount);
//       suggestionProfiles.forEach((suggestionProfile) => {
//         let matchingCount = 0;
//         if (profile.partnerCriterion.ageLowerBound?.name !== null && profile.partnerCriterion.ageUpperBound?.name !== null && suggestionProfile.age !== null) {
//           if (
//             profile.partnerCriterion.ageLowerBound.name <= suggestionProfile.age &&
//             profile.partnerCriterion.ageUpperBound.name >= suggestionProfile.age
//           ) {
//             matchingCount++;
//           }
//         }
//         if (profile.partnerCriterion.heightLowerBound?.centimetreValue !== null && profile.partnerCriterion.heightUpperBound?.centimetreValue !== null && suggestionProfile.height?.centimetreValue !== null) {
//           if (
//             profile.partnerCriterion.heightLowerBound?.centimetreValue <= suggestionProfile.height?.centimetreValue &&
//             profile.partnerCriterion.heightUpperBound?.centimetreValue >= suggestionProfile.height?.centimetreValue
//           ) {
//             matchingCount++;
//           }
//         }
//         if (suggestionProfile.educationAndProfession?.highestQualification?.id && profile.partnerCriterion.qualifications?.length > 0) {
//           if (
//             profile.partnerCriterion.qualifications.some(
//               (qualification) => qualification.id === suggestionProfile.educationAndProfession.highestQualification.id)
//           ) {
//             matchingCount++;
//           }
//         }
//         if (suggestionProfile.educationAndProfession?.jobSector?.id && profile.partnerCriterion.jobSectors?.length > 0) {
//           if (
//             profile.partnerCriterion.jobSectors.some((jobSectors) => jobSectors.id === suggestionProfile.educationAndProfession.jobSector.id)
//           ) {
//             matchingCount++;
//           }
//         }
//         if (suggestionProfile.educationAndProfession?.profession?.id && profile.partnerCriterion.professions?.length > 0) {
//           if (
//             profile.partnerCriterion.professions.some(
//               (profession) =>
//                 profession.id === suggestionProfile.educationAndProfession.profession.id
//             )
//           ) {
//             matchingCount++;
//           }
//         }
//         if (suggestionProfile.location?.religion?.id && profile.partnerCriterion.religions?.length > 0) {
//           if (
//             profile.partnerCriterion.religions.some(
//               (religion) => religion.id === suggestionProfile.location.religion.id
//             )
//           ) {
//             matchingCount++;
//           }
//         }
//         if (suggestionProfile.location?.motherTongue?.id && profile.partnerCriterion.motherTongues?.length > 0) {
//           if (
//             profile.partnerCriterion.motherTongues.some(
//               (motherTongue) =>
//                 motherTongue.id === suggestionProfile.location.motherTongue.id
//             )
//           ) {
//             matchingCount++;
//           }
//         }
//         if (suggestionProfile.maritalStatus && suggestionProfile.maritalStatus?.id && profile.partnerCriterion.maritalStatuses?.length > 0) {
//           if (
//             profile.partnerCriterion.maritalStatuses.some(
//               (maritalStatus) => maritalStatus.id === suggestionProfile.maritalStatus.id
//             )
//           ) {
//             matchingCount++;
//           }
//         }
//         if (suggestionProfile.location?.country?.id && profile.partnerCriterion.countries?.length > 0) {
//           if (
//             profile.partnerCriterion.countries.some(
//               (country) => country.id === suggestionProfile.location.country.id
//             )
//           ) {
//             matchingCount++;
//           }
//         }
//         if (profile.partnerCriterion.creators?.length > 0 && suggestionProfile.createdBy?.id) {
//           if (
//             profile.partnerCriterion.creators.some(
//               (creator) => creator.id === suggestionProfile.createdBy.id
//             )
//           ) {
//             matchingCount++;
//           }
//         }
//         if (suggestionProfile.dietHabbit?.id && profile.partnerCriterion.dietHabbits?.length > 0) {
//           if (
//             profile.partnerCriterion.dietHabbits.some(
//               (dietHabbit) => dietHabbit.id === suggestionProfile.dietHabbit.id
//             )
//           ) {
//             matchingCount++;
//           }
//         }
//         if (profile.partnerCriterion.drinkingHabbits?.length > 0 && suggestionProfile.drinkingHabbit?.id) {
//           if (
//             profile.partnerCriterion.drinkingHabbits.some(
//               (drinkingHabbit) =>
//                 drinkingHabbit.id === suggestionProfile.drinkingHabbit.id
//             )
//           ) {
//             matchingCount++;
//           }
//         }
//         if (suggestionProfile.smokingHabbit?.id && profile.partnerCriterion.smokingHabbits?.length > 0) {
//           if (
//             profile.partnerCriterion.smokingHabbits.some(
//               (smokingHabbit) => smokingHabbit.id === suggestionProfile.smokingHabbit.id
//             )
//           ) {
//             matchingCount++;
//           }
//         }
//         if (
//           profile.partnerCriterion.hasChildren?.name === "No" &&
//           !suggestionProfile.hasChildren
//         ) {
//           matchingCount++;
//         }
//         if (profile.partnerCriterion.hasChildren?.name === "Does not care") {
//           matchingCount++;
//         }
//         if (
//           profile.partnerCriterion.hasChildren?.name === "Yes, but not living together" &&
//           suggestionProfile.hasChildren
//         ) {
//           matchingCount++;
//         }
//         // Update suggestionProfile with matchingCount
//         suggestionProfile.matchingCount = matchingCount;
//       });
//       setRearrangedProfiles(suggestionProfiles);
//     }

//     const swiper = new Swiper('.swiper', {
//       loop: true,
//       slidesPerView: slidesPerView, // Use the dynamic slidesPerView value
//       spaceBetween: 10, // adjust as needed
//       navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//       },
//       keyboard: {
//         enabled: true,
//         onlyInViewport: false,
//       },
//     });
//     getMainProfilePhoto(rearrangedProfiles);
//     return () => {
//       swiper.destroy();
//     };
//   }, [slidesPerView]);

//   const getMainProfilePhoto = async (rearrangedProfiles) => {
//     const updatedProfiles = [];
//     for (const profile of rearrangedProfiles) {
//       let photoData = null;
//       const isProfileInArray = allConnectedProfiles.some(profileConnected => {
//         return ((profileConnected.requesteeProfile?.id || profileConnected.requesterProfile?.id) === profile.id);
//       });
//       const isPhotosApproved = allImgReqConnectedProfiles.some(data => {
//         return ((data.photoRequesteeProfile?.id) === profile.id);
//       });

//       if (isProfileInArray || isPhotosApproved || profile.photoStatus === "Public") {
//         if (profile.mainProfilePhotoId) {
//           try {
//             const photoResponse = await getProfilePhoto(profile.mainProfilePhotoId);
//             photoData = photoResponse.data;
//           } catch (error) {
//             console.error("Error fetching profile photo for profile ID", profile.bookMarkedProfile.id, ":", error);
//           }
//         }
//       }
//       profile.photoData = photoData;
//       const updatedProfile = {
//         ...profile,
//         photoData: photoData
//       };
//       updatedProfiles.push(updatedProfile);
//     }
//     setRearrangedProfiles(updatedProfiles);
//   };

//   const navigateToProfile = async (resultProfileId) => {
//     setLoading(true);
//     const relationshipResponse = await getRelationship(resultProfileId);
//     if (relationshipResponse.status === 200) {
//       if (relationshipResponse.data.status === "Approved") {
//         if (relationshipResponse.data.requesterProfile) {
//           setInterestStatus("APPROVEDBYME");
//         }
//         if (relationshipResponse.data.requesteeProfile) {
//           setInterestStatus("APPROVED");
//         }
//       } else if (relationshipResponse.data.status === "Await") {
//         if (relationshipResponse.data.requesterProfile) {
//           setInterestStatus("PENDING");
//         }
//         if (relationshipResponse.data.requesteeProfile) {
//           setInterestStatus("SENT");
//         }
//       } else if (relationshipResponse.data.status === "Rejected") {
//         if (relationshipResponse.data.requesterProfile) {
//           setInterestStatus("REJECTEDBYME");
//         }
//         if (relationshipResponse.data.requesteeProfile) {
//           setInterestStatus("REJECTED");
//         }
//       } else if (!relationshipResponse.data.status) {
//         setInterestStatus("NONE");
//       }
//       if (interestStatus !== "APPROVEDBYME") {
//         let obj = {};
//         obj.requesterProfileId = profile.id;
//         obj.requesteeProfileId = resultProfileId;
//         const AddTopVisitor = await addTopVisitor(obj);
//         if (AddTopVisitor.status === 500) {
//           console.log("Error while saving");
//         }
//       }
//     } else {
//       console.log("Error in calling getRelationship");
//     }
//     navigate('/otherprofiles', { state: { resultProfileId } });
//   };

//   return (
//     <div className="wrapper" >
//       <div className="swiper">
//         <div className="swiper-wrapper">
//           {loading ? <ButtonSpinner></ButtonSpinner> :
//             <>
//               {
//                 rearrangedProfiles.length > 0 && rearrangedProfiles.map((profile, index) => (
//                   <div key={index} className="swiper-slide">
//                     <Card className="card6">
//                       <img
//                         style={{ height: '100px'
//                         // , filter: (profile.photoData === null || profile.photoData?.isApproved) ? 'none' : (!profile.photoData?.isApproved) && 'blur(4px)' 
//                       }}
//                         src={profile.photoData === null ? User : (profile.photoData?.fileContents && (profile.photoData?.isApproved) ? `data:image;base64,${profile.photoData.fileContents}` : User)}
//                         onClick={() => navigateToProfile(profile.id)} alt="slide" />
//                       <div className="text">
//                         <p8>{profile.firstName || profile.profileName},</p8><p8>{getAge(profile.dob)}</p8>
//                       </div>
//                       <div className="text">
//                         <p8> {profile.matchingCount > 0 && <p8>Match {profile.matchingCount}/{considerCountTotal}</p8>}</p8>
//                       </div>
//                     </Card>
//                   </div>
//                 ))
//               }
//             </>
//           }
//         </div>&nbsp;
//         <div className="swiper-button-next"></div>
//         <div className="swiper-button-prev"></div>
//       </div>
//     </div>
//   );
// };

// export default ImageSlider;

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import './../../CSS/ImageSlider.css';
import User from '../../Assests/imgs/user2.png';
import { getAge } from '../../Utils/getAge';
import { useNavigate } from 'react-router-dom';
import { addTopVisitor, getRelationship } from '../../Services/Profile/expressionOfInterest';
import { getProfilePhoto } from '../../Services/Profile/photoService.js';
import { useMember } from "../../Contexts/memberContext.js";
import { ButtonSpinner } from "../Common/Spinner.js";


function MyCarousel({ suggestionProfiles }) {
  const [numCards, setNumCards] = useState(10); // Default number of cards per item
  const navigate = useNavigate();
  const [interestStatus, setInterestStatus] = useState("NONE");
  const [rearrangedProfiles, setRearrangedProfiles] = useState(suggestionProfiles);
  const { profile, allConnectedProfiles, allImgReqConnectedProfiles } = useMember();
  const [loading, setLoading] = useState(false);
  const [considerCountTotal, setConsiderCountTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);


  const navigateToProfile = async (resultProfileId, externalID) => {
    setLoading(true);
    const relationshipResponse = await getRelationship(resultProfileId, externalID);
    if (relationshipResponse.status === 200) {
      if (relationshipResponse.data.status === "Approved") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("APPROVEDBYME");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("APPROVED");
        }
      } else if (relationshipResponse.data.status === "Await") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("PENDING");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("SENT");
        }
      } else if (relationshipResponse.data.status === "Rejected") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("REJECTEDBYME");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("REJECTED");
        }
      } else if (!relationshipResponse.data.status) {
        setInterestStatus("NONE");
      }
      if (interestStatus !== "APPROVEDBYME") {
        let obj = {};
        obj.requesterProfileId = profile.id;
        obj.requesteeProfileId = resultProfileId;
        const AddTopVisitor = await addTopVisitor(obj);
        if (AddTopVisitor.status === 500) {
          console.log("Error while saving");
        }
      }
    } else {
      console.log("Error in calling getRelationship");
    }
    navigate('/otherprofiles', { state: { resultProfileId } });
  };

  const getMainProfilePhoto = async (rearrangedProfiles) => {
    const updatedProfiles = [];
    for (const profile of rearrangedProfiles) {
      let photoData = null;
      const isProfileInArray = allConnectedProfiles.some(profileConnected => {
        return ((profileConnected.requesteeProfile?.id || profileConnected.requesterProfile?.id) === profile.id);
      });
      const isPhotosApproved = allImgReqConnectedProfiles.some(data => {
        return ((data.photoRequesteeProfile?.id) === profile.id);
      });

      if (isProfileInArray || isPhotosApproved || profile.photoStatus === "Public") {
        if (profile.mainProfilePhotoId) {
          try {
            const photoResponse = await getProfilePhoto(profile.mainProfilePhotoId);
            photoData = photoResponse.data;
          } catch (error) {
            console.error("Error fetching profile photo for profile ID", profile.bookMarkedProfile.id, ":", error);
          }
        }
      }
      profile.photoData = photoData;
      const updatedProfile = {
        ...profile,
        photoData: photoData
      };
      updatedProfiles.push(updatedProfile);
    }
    setRearrangedProfiles(updatedProfiles);
  };

  // function Card({ imageUrl, title1, title2, title3 }) {
  //   return (
  //     <div className="card" style={{ width: '', margin: '5px' }} onClick={() => navigateToProfile(profile.id,externalID)}
  //     >
  //       <img src={imageUrl} className="card-img-top" alt="..." style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
  //       <div className="card-body">
  //         <h5 className="card-title">{title1}</h5>
  //         <h6 className="card-subtitle mb-2 text-muted">{title2}</h6>
  //         {title3 && <p className="card-text">{title3}</p>}
  //       </div>
  //     </div>
  //   );
  // }

  useEffect(() => {
    if (profile.partnerCriterion && suggestionProfiles.length > 0) {
      let considerCount = 0;
      for (const key in profile.partnerCriterion) {
        if (Object.keys(profile.partnerCriterion[key]).length > 0) {
          considerCount++;
        }
      }
      setConsiderCountTotal(considerCount - 2);
      suggestionProfiles.forEach((suggestionProfile) => {
        let matchingCount = 0;
        if (profile.partnerCriterion.ageLowerBound?.name !== null && profile.partnerCriterion.ageUpperBound?.name !== null && suggestionProfile.dob !== null) {
          if (
            profile.partnerCriterion.ageLowerBound.name <= getAge(suggestionProfile.dob) &&
            profile.partnerCriterion.ageUpperBound.name >= getAge(suggestionProfile.dob)
          ) {
            matchingCount++;
          }
        }
        if (profile.partnerCriterion.heightLowerBound?.centimetreValue !== null && profile.partnerCriterion.heightUpperBound?.centimetreValue !== null && suggestionProfile.height?.centimetreValue !== null) {
          if (
            profile.partnerCriterion.heightLowerBound?.centimetreValue <= suggestionProfile.height?.centimetreValue &&
            profile.partnerCriterion.heightUpperBound?.centimetreValue >= suggestionProfile.height?.centimetreValue
          ) {
            matchingCount++;
          }
        }
        if (suggestionProfile.educationAndProfession?.highestQualification?.id && profile.partnerCriterion.qualifications?.length > 0) {
          if (
            profile.partnerCriterion.qualifications.some(
              (qualification) => qualification.id === suggestionProfile.educationAndProfession.highestQualification.id)
          ) {
            matchingCount++;
          }
        }
        if (suggestionProfile.educationAndProfession?.jobSector?.id && profile.partnerCriterion.jobSectors?.length > 0) {
          if (
            profile.partnerCriterion.jobSectors.some((jobSectors) => jobSectors.id === suggestionProfile.educationAndProfession.jobSector.id)
          ) {
            matchingCount++;
          }
        }
        if (suggestionProfile.educationAndProfession?.profession?.id && profile.partnerCriterion.professions?.length > 0) {
          if (
            profile.partnerCriterion.professions.some(
              (profession) =>
                profession.id === suggestionProfile.educationAndProfession.profession.id
            )
          ) {
            matchingCount++;
          }
        }
        if (suggestionProfile.location?.religion?.id && profile.partnerCriterion.religions?.length > 0) {
          if (
            profile.partnerCriterion.religions.some(
              (religion) => religion.id === suggestionProfile.location.religion.id
            )
          ) {
            matchingCount++;
          }
        }
        if (suggestionProfile.location?.motherTongue?.id && profile.partnerCriterion.motherTongues?.length > 0) {
          if (
            profile.partnerCriterion.motherTongues.some(
              (motherTongue) =>
                motherTongue.id === suggestionProfile.location.motherTongue.id
            )
          ) {
            matchingCount++;
          }
        }
        if (suggestionProfile.maritalStatus && suggestionProfile.maritalStatus?.id && profile.partnerCriterion.maritalStatuses?.length > 0) {
          if (
            profile.partnerCriterion.maritalStatuses.some(
              (maritalStatus) => maritalStatus.id === suggestionProfile.maritalStatus.id
            )
          ) {
            matchingCount++;
          }
        }
        if (suggestionProfile.location?.country?.id && profile.partnerCriterion.countries?.length > 0) {
          if (
            profile.partnerCriterion.countries.some(
              (country) => country.id === suggestionProfile.location.country.id
            )
          ) {
            matchingCount++;
          }
        }
        if (profile.partnerCriterion.creators?.length > 0 && suggestionProfile.createdBy?.id) {
          if (
            profile.partnerCriterion.creators.some(
              (creator) => creator.id === suggestionProfile.createdBy.id
            )
          ) {
            matchingCount++;
          }
        }
        if (suggestionProfile.dietHabbit?.id && profile.partnerCriterion.dietHabbits?.length > 0) {
          if (
            profile.partnerCriterion.dietHabbits.some(
              (dietHabbit) => dietHabbit.id === suggestionProfile.dietHabbit.id
            )
          ) {
            matchingCount++;
          }
        }
        if (profile.partnerCriterion.drinkingHabbits?.length > 0 && suggestionProfile.drinkingHabbit?.id) {
          if (
            profile.partnerCriterion.drinkingHabbits.some(
              (drinkingHabbit) =>
                drinkingHabbit.id === suggestionProfile.drinkingHabbit.id
            )
          ) {
            matchingCount++;
          }
        }
        if (suggestionProfile.smokingHabbit?.id && profile.partnerCriterion.smokingHabbits?.length > 0) {
          if (
            profile.partnerCriterion.smokingHabbits.some(
              (smokingHabbit) => smokingHabbit.id === suggestionProfile.smokingHabbit.id
            )
          ) {
            matchingCount++;
          }
        }
        if (
          profile.partnerCriterion.hasChildren?.name === "No" &&
          !suggestionProfile.hasChildren
        ) {
          matchingCount++;
        }
        if (profile.partnerCriterion.hasChildren?.name === "Does not care") {
          matchingCount++;
        }
        if (
          profile.partnerCriterion.hasChildren?.name === "Yes, but not living together" &&
          suggestionProfile.hasChildren
        ) {
          matchingCount++;
        }
        // Update suggestionProfile with matchingCount
        suggestionProfile.matchingCount = matchingCount;
      });
      setRearrangedProfiles(suggestionProfiles);
    }
    const duplicatedProfiles = rearrangedProfiles.length > 0 ? [...rearrangedProfiles, ...rearrangedProfiles, ...rearrangedProfiles] : [];

    // Update the number of cards based on screen width
    const updateNumCards = () => {
      let cardsToShow;
      if (window.innerWidth < 350) {
        cardsToShow = 2;
      } else if (window.innerWidth >= 350 && window.innerWidth < 600) {
        cardsToShow = 3;
      } else if (window.innerWidth >= 600 && window.innerWidth < 900) {
        cardsToShow = 5;
      } else if (window.innerWidth >= 900 && window.innerWidth < 1100) {
        cardsToShow = 7;
      } else {
        cardsToShow = 10;
      }
      setNumCards(cardsToShow);
      getMainProfilePhoto(rearrangedProfiles);
    };

    // Event listener for window resize
    window.addEventListener('resize', updateNumCards);

    // Initial call to set the number of cards
    updateNumCards();

    // Clean up the event listener
    return () => window.removeEventListener('resize', updateNumCards);
  }, []);

  const totalCarouselItems = Math.ceil(rearrangedProfiles.length / numCards);
  const showArrows = rearrangedProfiles.length > numCards;

  return (
    <Carousel interval={null}
      nextIcon={<span className="carousel-control-next-icon"></span>}
      prevIcon={<span className="carousel-control-prev-icon"></span>}
      controls={showArrows}
    >
      {[...Array(totalCarouselItems)].map((_, index) => (
        <Carousel.Item key={index}>
          <div className="card-wrapper container-sm d-flex justify-content-around">
            {rearrangedProfiles.slice(index * numCards, (index + 1) * numCards).map((profileData, cardIndex) => (
              <div className="card suggetioncard" key={cardIndex} onClick={() => navigateToProfile(profileData.id, profile.externalId)}>
                <img src={profileData.photoData === null ? User : (profileData.photoData?.fileContents && (profileData.photoData?.isApproved) ? `data:image;base64,${profileData.photoData.fileContents}` : User)} className="card-img-top" alt="..." />
                <div className="card-body">
                <p className="card-title">{profileData.firstName             ? (profileData.firstName.length > 11 ? `${profileData.firstName.slice(0, 11)}...` : profileData.firstName) : (profileData.profileName.length > 11 ? `${profileData.profileName.slice(0, 11)}...` : profileData.profileName)} </p>
                   <p className="card-subtitle mb-2 text-muted">{getAge(profileData.dob)}&nbsp;yrs</p>
                  {profileData.matchingCount > 0 && <p className="card-text">Match {profileData.matchingCount}/{considerCountTotal}</p>}
                </div>
              </div>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default MyCarousel;