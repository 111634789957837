import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { uploadPhotos } from '../../Services/Profile/photoService';
import './../../CSS/Proflies.css';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ImageUpload = ({ profileId, profilePhotos, handlePhotosLoading }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const uploadButton = (
    <button
      className="uploadbtn"
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div className="uploadbtntext"
      >
        Upload profile images
      </div>
    </button>
  );

  const customRequest = async ({ file, onSuccess, onError }) => {
    let imgArr = [];
    window.scrollTo({ top: 0, behavior: "smooth" });
    const formData = new FormData();
    formData.append('Photos', file);
    const res = await uploadPhotos(profileId, formData);
    if (res.status === 201) {
      onSuccess();
      message.success("Profile picture uploaded successfully and please wait for moderator approval. ");
      imgArr.push(file);
      setFileList((file) => [
        ...file,
        imgArr
      ]);
      handlePhotosLoading();
    } else {
      message.error("Upload unsuccessfull!");
      handlePhotosLoading();
    }
  };

  return (
    <>
      <ImgCrop rotate>
        <Upload
          //action={`https://matrimony-api.azurewebsites.net/api/v1/profiles/${profileId}/photos`}
          accept="image/*"
          listType="picture-card"
          fileList={fileList}
          //onPreview={handlePreview}
          //onChange={handleChange}
          customRequest={customRequest}
        >
          {profilePhotos.length >= 3 ? null : uploadButton}
        </Upload>
      </ImgCrop>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '200px',
            maxWidth: '800px'
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
export default ImageUpload;