import http from "../httpService";

const apiEndpoint = "/hided-profiles";

export function hideProfile(profileId) {
  const body = { profileId: profileId };
  return http.post(apiEndpoint, body);
}

export function unhideProfile(profileId) {
  return http.delete(`${apiEndpoint}/${profileId}`);
}

export function softDelete(body) {
  //const body = { profileId: profileId, reason: reason }; 
  return http.post("/soft-deleted-profiles", body);
}

export function unDelete(profileId) {
  return http.delete(`/soft-deleted-profiles/${profileId}`);
}
