import React, { useContext, useEffect, useState } from "react";
import { getProfile, getBookmarks } from "../Services/Profile/profileService";
import { getTopVisitors } from '../Services/Profile/expressionOfInterest';
import { useIsAuthenticated } from "@azure/msal-react";
const MemberContext = React.createContext();

export const MemberProvider = ({ children }) => {
  const [profile, setProfile] = useState();
  const [profileLoading, setProfileLoading] = useState(true);
  const [pendingReqCount, setPendingReqCount] = useState(0);
  const [sentReqCount, setSentReqCount] = useState(0);
  const [topVisitorCount, setTopVisitorCount] = useState(0);
  const [bookMarkedProfiles, setBookMarkedProfiles] = useState([]);
  const [allConnectedProfiles, setAllConnectedProfiles] = useState([]);
  const [allImgReqConnectedProfiles, setAllImgReqConnectedProfiles] = useState([]);
  const [sentImgRequestsProfiles, setSentImgRequiestsProfiles] = useState([]);
  const [pendingImgRequestsProfiles, setPendingImgRequiestsProfiles] = useState([]);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const fetchData = async () => {
      //Get profile details of user
      try {
        const { status: profileStatus, data: profile } = await getProfile("me");
        if (profileStatus === 200) setProfile(profile);
        const bookmarks = await getBookmarks(profile.id);
        if (bookmarks.status === 201) {
          setBookMarkedProfiles(bookmarks.data);
        }
        const topVisitors = await getTopVisitors(profile.id);
        const topVisitorsIds = topVisitors ? topVisitors.data.map(profile => profile.requesterProfileId) : [];
        //mapping the profile details to an array using id
        const promises = topVisitorsIds.map(async (id) => {
          const response = await getProfile(id);
          if (response.status === 200) {
            return response.data;
          }
        });
        //fire all the requests simultaneously using Promise.all().
        const profiles = await Promise.all(promises);
        const filteredProfiles = profiles.filter((profile) =>
          profile &&
          !profile.isSoftDeleted &&
          !profile.isHided &&
          profile.status === "Active"
        );
        setTopVisitorCount(filteredProfiles.length);
      } catch (error) {
        console.log("Error in getProfile ", error);
      }
      setProfileLoading(false);
    };
    fetchData();
  }, [isAuthenticated]);

  return (
    <MemberContext.Provider
      value={{
        profile,
        setProfile,
        profileLoading,
        setProfileLoading,
        setSentReqCount,
        sentReqCount,
        setPendingReqCount,
        pendingReqCount,
        topVisitorCount,
        setTopVisitorCount,
        bookMarkedProfiles,
        setBookMarkedProfiles,
        allConnectedProfiles,
        setAllConnectedProfiles,
        allImgReqConnectedProfiles,
        setAllImgReqConnectedProfiles,
        sentImgRequestsProfiles,
        setSentImgRequiestsProfiles,
        pendingImgRequestsProfiles,
        setPendingImgRequiestsProfiles
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export const useMember = () => useContext(MemberContext);
