import * as msal from "@azure/msal-browser";
/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_B2C_SI_AUTHORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.REACT_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_LOGOUT_URI, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

class MicrosoftLoginAgent {
  constructor() {
    this.username = "";
    this.account = "";
    this.msalInstance = null; // Initialize as null initially
    this.init(); // Call init function asynchronously
  }

  async init() {
    try {
      this.msalInstance = new msal.PublicClientApplication(msalConfig);
    } catch (ex) {
      console.error("Initialization Failure", ex);
    }
  }

  async login() {
    const request = {
      scopes: process.env.REACT_APP_SCOPES.split(","),
    };
    try {
      await this.msalInstance.loginRedirect(request);
    } catch (ex) {
      return false;
    }
  }

  async logout() {
    try {
      await this.msalInstance.logoutRedirect();
      return true;
    } catch (error) {
      return false;
    }
  }

  async getAccessToken() {
    const request = {
      scopes: process.env.REACT_APP_SCOPES.split(","),
      account: {},
    };
    if (!this.msalInstance) {
      console.error("MSAL instance not initialized");
      return null;
    }

    try {
      const { accessToken } = await this.msalInstance.acquireTokenSilent(
        request
      );
      return accessToken;
    } catch (ex) {
      console.log("ex", ex);
      if (ex.name === "BrowserAuthError") {
        try {
          const response = await this.msalInstance.acquireTokenSilent(request);
          window.location.reload();
          return response.accessToken;
        } catch (ex) { }
      }
    }
  }

  //   async  getAccessToken() {
  //     const request = {
  //           scopes: process.env.REACT_APP_SCOPES.split(","),
  //           account: {},
  //         };
  //     return await this.msalInstance.acquireTokenSilent(request).catch(error => {
  //         this.logger.info("silent token acquisition fails. acquiring token using redirect");
  //         // fallback to interaction when silent call fails
  //         const token = this.msalInstance.acquireTokenRedirect(request);
  //         console.log("this.msalInstance.acquireTokenRedirect(request) ", token)
  //         return token;
  //     });
  // }

  getAccount() {
    return this.msalInstance.getAllAccounts()[0];
  }

  // async GetTokenFromRedirect() {
  //   try {
  //     console.log("rrrr");
  //     let tokenResponse = await this.msalInstance.handleRedirectPromise();
  //     console.log("tokenResponse",tokenResponse)
  //     return tokenResponse;
  //   } catch (error) { 
  //     console.log("error ", error)
  //   }
  // }

  async GetTokenFromRedirect() {
    try {
      if (!this.msalInstance) {
        console.error("MSAL instance not initialized");
        return null;
      }
      let tokenResponse = await this.msalInstance.handleRedirectPromise();
      return tokenResponse;
    } catch (error) {
      console.error("Error handling redirect", error);
      return null;
    }
  }

}

const microsoftLoginAgent = new MicrosoftLoginAgent();

export { microsoftLoginAgent as MicrosoftLoginAgent };
