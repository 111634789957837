import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './../../CSS/ResultPage.css';
import { getAge } from '../../Utils/getAge';
import { addTopVisitor, getRelationship } from '../../Services/Profile/expressionOfInterest';
import { getProfilePhoto } from '../../Services/Profile/photoService.js';
import { useMember } from "../../Contexts/memberContext.js";
import User from '../../Assests/imgs/user2.png';
import { ButtonSpinner } from "../Common/Spinner.js";

export default function RecipeReviewCard({ resultProfile }) {
  const { profile, allConnectedProfiles, allImgReqConnectedProfiles } = useMember();
  const [interestStatus, setInterestStatus] = useState("NONE");
  const navigate = useNavigate();
  const [btnLoad, setBtnLoad] = useState(false);
  const [mainProfilePhoto, setMainProfilePhoto] = useState(null);

  useEffect(() => {
    const isProfileInArray = allConnectedProfiles.some(profile => {
      // Check if the id of the profile matches resultProfile.id
      return ((profile.requesteeProfile?.id || profile.requesterProfile?.id) === resultProfile.id);
    });
    const isPhotosApproved = allImgReqConnectedProfiles.some(profile => {
      return ((profile.photoRequesteeProfile?.id) === resultProfile.id);
    });
    if (isProfileInArray || isPhotosApproved || resultProfile.photoStatus === "Public") {
      getMainProfilePhoto();
    }
  }, [resultProfile]);

  const getMainProfilePhoto = async () => {
    if (resultProfile?.mainProfilePhotoId === 0) return;
    const photoResponse = await getProfilePhoto(resultProfile?.mainProfilePhotoId);
    if (photoResponse.status === 200) {
      setMainProfilePhoto(photoResponse.data);
    } else if (photoResponse.status === 404) {
      setMainProfilePhoto(null);
    } else {
      console.log("Unable to load your profile photos");
    }
  };

  const navigateToProfile = async (resultProfileId) => {
    setBtnLoad(true);
    const relationshipResponse = await getRelationship(resultProfileId, profile.externalId);
    if (relationshipResponse.status === 200) {
      if (relationshipResponse.data.status === "Approved") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("APPROVEDBYME");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("APPROVED");
        }
      } else if (relationshipResponse.data.status === "Await") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("PENDING");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("SENT");
        }
      } else if (relationshipResponse.data.status === "Rejected") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("REJECTEDBYME");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("REJECTED");
        }
      } else if (!relationshipResponse.data.status) {
        setInterestStatus("NONE");
      }
      if (interestStatus !== "APPROVEDBYME") {
        let obj = {};
        obj.requesterProfileId = profile.id;
        obj.requesteeProfileId = resultProfile.id;
        const AddTopVisitor = await addTopVisitor(obj);
        if (AddTopVisitor.status === 500) {
          console.log("Error while saving");
        }
      }
    } else {
      console.log("Error in calling getRelationship");
    }
    navigate('/otherprofiles', { state: { resultProfileId } });
  };
  return (
    <Card className="resultcards" sx={{ maxWidth: 150 }} style={{}}>
      <CardMedia
        className="cardimage"
        component="img"
        height="194"
        style={{
          height: '120px'
          // , filter: (!mainProfilePhoto ? 'none' : mainProfilePhoto?.isApproved) ? 'none' : (!mainProfilePhoto?.isApproved && resultProfile?.mainProfilePhotoId !== 0) && 'blur(4px)' 
        }}
        src={resultProfile?.mainProfilePhotoId === 0 ? User : (mainProfilePhoto && mainProfilePhoto?.isApproved ? `data:image;base64,${mainProfilePhoto?.fileContents}` : User)}
        alt="Image"
      />
      <CardContent className="rcard" style={{ display: 'flex', flexDirection: 'column', padding: '5px', height: '110px' }}>
        <Typography variant="body2" color="text.secondary" >
          {(resultProfile?.firstName || resultProfile?.profileName) && <p>{resultProfile.firstName ? (resultProfile.firstName.length > 20 ? `${resultProfile.firstName.slice(0, 20)}...` : resultProfile.firstName) : (resultProfile.profileName.length > 20 ? `${resultProfile.profileName.slice(0, 20)}...` : resultProfile.profileName)}</p>}
          {resultProfile?.dob && <p>{getAge(resultProfile.dob)} years old</p>}
          {resultProfile?.location?.religion?.name && <p>{resultProfile.location.religion.name}</p>}
          {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> */}
          {resultProfile?.location?.community?.name && <p>{resultProfile.location.community.name}</p>}
          {/* <Button onClick={createExpressionOfEnterest} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }}>Connect</Button> */}
          {/* </div> */}
        </Typography>
        <div className="morebut" style={{ display: 'flex', justifyContent: 'end', alignSelf: 'end', marginTop: 'auto' }}>
          <Button onClick={() => navigateToProfile(resultProfile.id)} className="dmsans morebut" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }}>{btnLoad ? <ButtonSpinner></ButtonSpinner> : 'More'}</Button>
        </div>
      </CardContent>
    </Card>
  );
}