import { Grid, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import PrivacyPolicy from "./privacyPolicy";
import Footer from "../FooterPages/Footer";
import Header from "../NavBar/Header";
import React, { useEffect } from "react";

const DetailsWrap = styled(Box)(({ theme }) => ({
  padding: "0 30px",

  "& .label": {
    padding: 0,
    [theme.breakpoints.down("md")]: {
      overflowY: "scroll",
    },
  },

  [theme.breakpoints.down("md")]: {
    padding: "0 10px",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 22,
  fontWeight: 700,
  paddingBottom: "13px",
  marginBottom: "10px",
  borderBottom: "2px solid #e3e3e3",
}));

const Policy = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />
      <div className="bottomspacedash">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: "center", fontSize: 28, fontWeight: 700, my: 5 }}
              variant="h4"
            >
              Privacy Policy
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{
            marginBottom: {
              '@media (min-width: 820px) and (min-height: 1180px)': {
                marginBottom: '340px'
              },
              '@media (min-width: 1024px) and (min-height: 1366px)': {
                marginBottom: '385px'
              },
              '@media (min-width: 1440px) and (min-height: 900px)': {
                marginBottom: '95px'
              },
              '@media (min-width: 2560px)': {
                marginBottom: '240px'
              }
            }
          }}>
            <DetailsWrap>
              <Title variant="h3">Read Carefully</Title>
              <div align="justify">
                <PrivacyPolicy />
              </div>
            </DetailsWrap>
          </Grid>
        </Grid>
        <Footer />
      </div>
    </>
  );
};

export default Policy;
