import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './../../CSS/Proflies.css';
import Button from '@mui/material/Button';
import FavoriteToggleButton from './../Mini Components/FavoriteToggleButton.js';
import Chip from '@mui/material/Chip';
import { useLocation } from 'react-router-dom';
import { Spinner, ButtonSpinner } from "../Common/Spinner.js";
import { getAge } from '../../Utils/getAge.js';
import { toast } from "react-toastify";
import { useMember } from "../../Contexts/memberContext.js";
import {
  getProfilePhoto, imageRequest, cancelImageRequest, getSentImageRequests,
  disconnectApprovedImageRequest,
  rejectImageRequest,
  getPendingImageRequests,
  approveImageRequest,
  getAcceptedImageRequestsAsRequester,
  getAcceptedImageRequestsAsRequestee
} from "../../Services/Profile/photoService.js";
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import {
  approveRequest,
  cancelRequest,
  createRequest,
  disconnectRelationship,
  getRelationship,
  rejectRequest,
  addTopVisitor
} from '../../Services/Profile/requests.js';
import User from '../../Assests/imgs/user2.png';
import CarouselCommon from '../Mini Components/CarouselCommon.js';
import Divider from '@mui/material/Divider';
import { EmojiEmotions, Group, School, Work, LocationOn, Favorite, Star, Person, } from '@mui/icons-material';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import InfoIcon from '@mui/icons-material/Info';
import PhoneIcon from '@mui/icons-material/Phone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function OtherProfile() {

  const location = useLocation();
  const { profile, profileLoading, allImgReqConnectedProfiles, allConnectedProfiles, sentImgRequestsProfiles, setSentImgRequiestsProfiles, pendingImgRequestsProfiles, setPendingImgRequiestsProfiles, setAllImgReqConnectedProfiles, setAllConnectedProfiles } = useMember();
  const [loading, setLoading] = useState(true);
  const [loadingImgs, setLoadingImgs] = useState(true);
  const [searchedProfile, setSearchedProfile] = useState({});
  const [relationshipData, setRelationshipData] = useState({});
  const [shortProfileLoading, setShortProfileLoading] = useState(true);
  const [relationShipStatusLoading, setRelationShipStatusLoading] = useState(true);
  const [connectedStatus, setConnectedStatus] = useState(false);
  const [photoConnectedStatus, setPhotoConnectedStatus] = useState();
  const [photoConnectedByMeStatus, setPhotoConnectedByMeStatus] = useState();
  const [photoReqSentStatus, setPhotoReqSentStatus] = useState();
  const [photoReqReceivedStatus, setPhotoReqReceivedStatus] = useState();
  const [relationShipStatus, setRelationShipStatus] = useState("NONE");
  const [expressionOfInterestId, setExpressionOfInterestId] = useState();
  const separator = ", ";
  const [myProfilePhoto, setMyProfilePhoto] = useState([]);
  const selectedProfileID = location.state.resultProfileId;
  const [allPhotos, setAllPhotos] = useState([]);
  const [horoscopePhotos, setHoroscopePhotos] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const shouldBlur = true;
  const navigate = useNavigate();

  // Function to render the value with a comma if it exists
  function renderWithComma(value) {
    return value ? `${value}, ` : '';
  }

  const createExpressionOfEnterest = async () => {
    setDisableBtn(true);
    let obj = {};
    obj.requesterProfileId = profile.id;
    obj.requesteeProfileId = searchedProfile.id;
    const createRequestResponse = await createRequest(obj);
    if (
      createRequestResponse.status === 201 ||
      createRequestResponse.status === 200
    ) {
      setRelationShipStatusLoading(true);
      setRelationShipStatus("SENT");
    } else {
      toast.error("Error while inviting");
    }
    setDisableBtn(false);
  };

  const sentImageRequests = async () => {
    setDisableBtn(true);
    try {
      const sentImageRequestsResponse = await getSentImageRequests(profile.id);
      if (sentImageRequestsResponse.status === 200) {
        setSentImgRequiestsProfiles(sentImageRequestsResponse.data);
      }
    } catch (error) {
      console.log("Error in get sent image requests");
    } finally {
      setDisableBtn(false);
    }
  };

  const requestForImages = async () => {
    let obj = {};
    obj.photoRequesterProfileId = profile.id;
    obj.photoRequesteeUserId = searchedProfile.id;
    setDisableBtn(true);
    const createRequestResponse = await imageRequest(obj, profile.id);
    if (
      createRequestResponse.status === 201 ||
      createRequestResponse.status === 200
    ) {
      sentImageRequests();
      swal({
        text: "Success", icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
    } else {
      toast.error("Error while req for images");
    }
    setDisableBtn(false);
  };

  const pendingImageRequests = async () => {
    try {
      const pendingImageRequestsResponse = await getPendingImageRequests(profile.id);
      if (pendingImageRequestsResponse.status === 200) {
        setPendingImgRequiestsProfiles(pendingImageRequestsResponse.data);
      }
    } catch (error) {
      console.log("Error in get pending image requests");
    }
  };

  const getAcceptedPhotoRequests = async () => {
    try {
      let acceptedPhotoRequests = [];
      const [requesteeImgConnectedProfiles, requesterImgConnectedProfiles] = await Promise.all([
        getAcceptedImageRequestsAsRequestee(profile.id),
        getAcceptedImageRequestsAsRequester(profile.id)
      ]);
      if (requesteeImgConnectedProfiles.status === 200) {
        if (requesteeImgConnectedProfiles.data.length > 0) {
          const updatedProfilesReq = [];
          for (const profile of requesteeImgConnectedProfiles.data) {
            let photoData = null;
            if (profile.photoRequesterProfile.mainProfilePhotoId) {
              try {
                const photoResponse = await getProfilePhoto(profile.photoRequesterProfile.mainProfilePhotoId);
                photoData = photoResponse.data;
              } catch (error) {
                console.error("Error fetching profile photo for profile ID", profile.photoRequesterProfile.id, ":", error);
              }
            }
            profile.photoData = photoData;
            const updatedProfile = {
              ...profile,
              photoData: photoData
            };
            updatedProfilesReq.push(updatedProfile);
          }
          acceptedPhotoRequests.push(...updatedProfilesReq);
        }
      } else {
        console.log("Error in get requestee img connected profiles");
      }
      if (requesterImgConnectedProfiles.status === 200) {
        if (requesterImgConnectedProfiles.data.length > 0) {
          const updatedProfiles = [];
          for (const profile of requesterImgConnectedProfiles.data) {
            let photoData = null;
            if (profile.photoRequesteeProfile.mainProfilePhotoId) {
              try {
                const photoResponse = await getProfilePhoto(profile.photoRequesteeProfile.mainProfilePhotoId);
                photoData = photoResponse.data;
              } catch (error) {
                console.error("Error fetching profile photo for profile ID", profile.photoRequesteeProfile.id, ":", error);
              }
            }
            profile.photoData = photoData;
            const updatedProfile = {
              ...profile,
              photoData: photoData
            };
            updatedProfiles.push(updatedProfile);
          }
          acceptedPhotoRequests.push(...updatedProfiles);
        }
      } else {
        console.log("Error in get requester img connected profiles");
      }
      setAllImgReqConnectedProfiles(acceptedPhotoRequests);
    } catch (error) {
      console.log("Error in get img connected profiles", error);
    }
    fetchImageConnections();
  };

  const approveReceivedImageRequest = async () => {
    setDisableBtn(true);
    const matchingRecord = pendingImgRequestsProfiles.find(element => element.photoRequesterProfile?.id === searchedProfile.id);
    const approveRequestResponse = await approveImageRequest(matchingRecord?.id);
    if (approveRequestResponse.status === 200) {
      pendingImageRequests();
      getAcceptedPhotoRequests();
      swal({
        text: "Success", icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
    } else {
      console.log("Error while accept");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
    setDisableBtn(false);
  };

  const rejectReceivedImageRequest = async () => {
    setDisableBtn(true);
    const matchingRecord = pendingImgRequestsProfiles.find(element => element.photoRequesterProfile?.id === searchedProfile.id);
    const rejectRequestResponse = await rejectImageRequest(matchingRecord.id);
    if (rejectRequestResponse.status === 200) {
      pendingImageRequests();
      swal({
        text: "Success", icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
    } else {
      console.log("Error while reject");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
    setDisableBtn(false);
  };

  const disconnectApprovedImgRequest = async () => {
    setDisableBtn(true);
    const matchingRecord = allImgReqConnectedProfiles.find(element => element.photoRequesteeProfile?.id || element.photoRequesterProfile?.id === searchedProfile.id);
    const disconnectResponse = await disconnectApprovedImageRequest(matchingRecord?.id);
    if (disconnectResponse.status === 200) {
      const indexToRemove = allImgReqConnectedProfiles.findIndex(profiles => profiles.id === matchingRecord?.id);
      if (indexToRemove !== -1) {
        const updatedallConnectedProfiles = [...allImgReqConnectedProfiles];
        updatedallConnectedProfiles.splice(indexToRemove, 1);
        setAllImgReqConnectedProfiles(updatedallConnectedProfiles);
        swal({
          text: "Success", icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        console.log("Record with id not found in disconnectApprovedImgRequest array");
        swal({
          text: 'Unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    } else {
      console.log("Error while cancelling");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
    setDisableBtn(false);
  };

  const cancelExpressionOfEnterest = async (id) => {
    setDisableBtn(true);
    const cancelRequestResponse = await cancelRequest(id);
    if (cancelRequestResponse.status === 204) {
      setRelationShipStatusLoading(true);
      setRelationShipStatus("NONE");
    } else {
      toast.error("Error while cancel");
    }
    setDisableBtn(false);
  };

  const approveExpressionOfEnterest = async (id) => {
    setDisableBtn(true);
    const approveRequestResponse = await approveRequest(id);
    if (approveRequestResponse.status === 200) {
      setRelationShipStatusLoading(true);
      setShortProfileLoading(true);
      setRelationShipStatus("APPROVEDBYME");
    } else {
      toast.error("Error while accepting");
    }
    setDisableBtn(false);
  };

  const rejectExpressionOfEnterest = async (id) => {
    setDisableBtn(true);
    const rejectRequestResponse = await rejectRequest(id);
    if (rejectRequestResponse.status === 200) {
      setRelationShipStatusLoading(true);
      setShortProfileLoading(true);
      setRelationShipStatus("REJECTEDBYME");
    } else {
      toast.error("Error while declining");
    }
    setDisableBtn(false);
  };

  const disconnectExpressionOfEnterest = async (id) => {
    setDisableBtn(true);
    const disconnectResponse = await disconnectRelationship(id);
    if (disconnectResponse.status === 200) {
      setRelationShipStatusLoading(true);
      setShortProfileLoading(true);
      setRelationShipStatus("NONE");
      const indexToRemove = allConnectedProfiles.findIndex(profile => profile.id === id);
      if (indexToRemove !== -1) {
        // If found, remove the record from the array
        const updatedallConnectedProfiles = [...allConnectedProfiles];
        updatedallConnectedProfiles.splice(indexToRemove, 1);
        setAllConnectedProfiles(updatedallConnectedProfiles);
      } else {
        toast.error("Error while removing invitation");
      }
    };
    setDisableBtn(false);
  };

  const cancelSentImageRequest = async () => {
    setDisableBtn(true);
    const matchingElement = sentImgRequestsProfiles.find(element => element.photoRequesteeProfile.id === searchedProfile.id);
    const cancelImgRequestResponse = await cancelImageRequest(matchingElement?.id);
    if (cancelImgRequestResponse.status === 204) {
      const indexToRemove = sentImgRequestsProfiles.findIndex(profiles => profiles.id === matchingElement?.id);
      if (indexToRemove !== -1) {
        const updatedSentRequests = [...sentImgRequestsProfiles];
        updatedSentRequests.splice(indexToRemove, 1);
        setSentImgRequiestsProfiles(updatedSentRequests);
        swal({
          text: "Success", icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        console.log("Record with id not found in sentImgRequestsProfiles array");
        swal({
          text: 'Unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    } else {
      console.log("Error while cancel");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
    setDisableBtn(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchData = async () => {
      setLoading(true);
      try {
        const relationshipResponse = await getRelationship(selectedProfileID, profile.externalId);
        console.log("relationshipResponse", relationshipResponse.data);
        if (relationshipResponse.status !== 200) {
          console.error("Error in getting relationship data");
          setLoading(false);
          return;
        }

        const relationshipData = relationshipResponse.data;
        setRelationshipData(relationshipData);
        setExpressionOfInterestId(relationshipData.id);

        switch (relationshipData.status) {
          case "Approved":
            if (relationshipData.requesterProfile) {
              setRelationShipStatus("APPROVEDBYME");
            }
            if (relationshipData.requesteeProfile) {
              setRelationShipStatus("APPROVED");
            }
            break;
          case "Await":
            if (relationshipData.requesterProfile) {
              setRelationShipStatus("PENDING");
            }
            if (relationshipData.requesteeProfile) {
              setRelationShipStatus("SENT");
            }
            break;
          case "Rejected":
            if (relationshipData.requesterProfile) {
              setRelationShipStatus("REJECTEDBYME");
            }
            if (relationshipData.requesteeProfile) {
              setRelationShipStatus("REJECTED");
            }
            break;
          default:
            console.error("Unknown relationship status");
        }

        setRelationShipStatusLoading(false);
        setShortProfileLoading(true);
        const searchedProfileData =
          relationshipData.requesteeProfile ||
          relationshipData.requesterProfile ||
          relationshipData;

        setSearchedProfile(searchedProfileData);

        const myProfilePhotoResponse = await getProfilePhoto(
          searchedProfileData.mainProfilePhotoId
        );

        if (myProfilePhotoResponse.status === 200 && myProfilePhotoResponse.data.isApproved) {
          setMyProfilePhoto(myProfilePhotoResponse.data);
        }

        if (searchedProfileData.mainProfilePhoto) {
          setProfilePhoto(searchedProfileData.mainProfilePhoto);
        }

        if (searchedProfileData.photos) {
          setAllPhotos(searchedProfileData.photos);
        }

        if (searchedProfileData.horoscopePhotos) {
          setHoroscopePhotos(searchedProfileData.horoscopePhotos);
        }

        setShortProfileLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedProfileID, profileLoading, relationShipStatus, allImgReqConnectedProfiles]);

  const fetchImageConnections = async () => {
    const hasRequesteeProfile = !!relationshipData.requesteeProfile;
    const hasRequesterProfile = !!relationshipData.requesterProfile;
    const isProfileInArray = allConnectedProfiles.some(profiled => {
      if (relationShipStatus === "NONE" && !hasRequesteeProfile && !hasRequesterProfile) {
        return (
          (profiled.requesteeProfile?.id || profiled.requesterProfile?.id) ===
          (relationshipData?.id ||
            relationshipData?.id)
        );
      } else {
        return (
          (profiled.requesteeProfile?.id || profiled.requesterProfile?.id) ===
          (relationshipData.requesterProfile?.id ||
            relationshipData.requesteeProfile?.id)
        );
      }

    });

    const isPhotosApproved = allImgReqConnectedProfiles.some(profiled => {
      if (relationShipStatus === "NONE" && !hasRequesteeProfile && !hasRequesterProfile) {
        return (
          (profiled.photoRequesterProfile?.id ||
            profiled.photoRequesteeProfile?.id) ===
          (relationshipData?.id)
        );
      } else {
        return (
          (profiled.photoRequesterProfile?.id ||
            profiled.photoRequesteeProfile?.id) ===
          (relationshipData.requesteeProfile?.id || relationshipData.requesterProfile?.id)
        );
      }

    });

    const isPhotosApprovedByMe = allImgReqConnectedProfiles.some(profiled => {
      if (relationShipStatus === "NONE" && !hasRequesteeProfile && !hasRequesterProfile) {
        return (
          (profiled.photoRequesterProfile?.id) ===
          (relationshipData?.id)
        );
      } else {
        // if (relationshipData.requesterProfile?.id) {
        //   if (profiled.photoRequesteeProfile?.id ===
        //     relationshipData.requesterProfile?.id) {
        //     console.log("available");
        //     return true;
        //   }
        // }
        if (relationshipData.requesteeProfile?.id) {
          if (profiled.photoRequesterProfile?.id ===
            relationshipData.requesteeProfile?.id) {
            console.log("available another");
            return true;
          }
        }


        if (relationshipData.requesterProfile?.id) {
          if (profiled.photoRequesterProfile?.id ===
            relationshipData.requesterProfile?.id) {
            console.log("availableee");
            return true;
          }
        }
        // if (relationshipData.requesteeProfile?.id) {
        //   if (relationshipData.requesteeProfile?.id) {
        //     if (profiled.photoRequesteeProfile?.id ===
        //       relationshipData.requesteeProfile?.id) {
        //       return false;
        //     }
        //   }
        // }

        return null;
        // return (
        //   profiled.photoRequesterProfile?.id === relationshipData.requesteeProfile?.id
        // );
      }
    });

    const isPhotosReqSent = sentImgRequestsProfiles.some(profiled => {
      if (relationShipStatus === "NONE" && !hasRequesteeProfile && !hasRequesterProfile) {
        return (
          profiled.photoRequesteeProfile?.id ===
          (relationshipData?.id ||
            relationshipData?.id)
        );
      } else {
        return (
          profiled.photoRequesteeProfile?.id ===
          (relationshipData.requesterProfile?.id ||
            relationshipData.requesteeProfile?.id)
        );
      }
    });
    const isPhotosReqReceived = pendingImgRequestsProfiles.some(profiled => {
      if (relationShipStatus === "NONE" && !hasRequesteeProfile && !hasRequesterProfile) {
        return (
          profiled.photoRequesterProfile?.id ===
          (relationshipData?.id ||
            relationshipData?.id)
        );
      } else {
        return (
          profiled.photoRequesterProfile?.id ===
          (relationshipData.requesterProfile?.id ||
            relationshipData.requesteeProfile?.id)
        );
      }
    });
    setConnectedStatus(isProfileInArray);
    setPhotoConnectedStatus(isPhotosApproved);
    setPhotoConnectedByMeStatus(isPhotosApprovedByMe);
    setPhotoReqSentStatus(isPhotosReqSent);
    setPhotoReqReceivedStatus(isPhotosReqReceived);
  };

  useEffect(() => {

    fetchImageConnections();
  }, [allConnectedProfiles, allImgReqConnectedProfiles, sentImgRequestsProfiles, pendingImgRequestsProfiles, relationshipData, loadingImgs]);


  return (
    <>
      <div className="OtherProfile topspaceprof">
        <Box sx={{ flexGrow: 1 }}>

          {(loading) ? (<Spinner></Spinner>) : (
            // <div className="OtherProfile topspaceprof">
            <div>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} lg={6} xs={12}>
                  <div className="sides">
                    <div>
                      <Button onClick={() => navigate(-1)} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }}><ArrowBackIcon className="arrow" />&nbsp;Back to profiles</Button>
                    </div>
                    <br />
                    <Grid container spacing={0}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {allPhotos.length === 0 ? (
                          <img className="otherproimg" src={User} alt='otherproimg' />
                        ) : relationShipStatus === "APPROVEDBYME" ||
                          relationShipStatus === "APPROVED" ? (
                          <>
                            <CarouselCommon profilePhotos={allPhotos} />
                          </>
                        ) : searchedProfile.photoStatus === "Public" ? (
                          <>
                            <CarouselCommon profilePhotos={allPhotos} />
                          </>
                        ) : searchedProfile.photoStatus === "Request" ? (
                          <>
                            {(!photoConnectedByMeStatus && photoConnectedStatus) && (
                              <CarouselCommon profilePhotos={allPhotos} />
                            )}
                            {/* {(photoConnectedByMeStatus && !photoConnectedStatus) && (
                              <CarouselCommon profilePhotos={allPhotos} />
                            )} */}
                          </>
                        ) : null}
                      </div>
                    </Grid>
                    <hr />
                    <div
                    // className="firstdata"
                    >
                      <div>
                        {/* <p3>Published date : 25/01/2024</p3></div> */}
                        {/* integrate this date */}
                        {!relationShipStatusLoading && (
                          <>
                            {relationShipStatus === "NONE" && (
                              <div>
                                <Grid container spacing={2}>
                                  <Grid item xs={9}>
                                    Are you interested in this profile?
                                  </Grid>
                                  <Grid item xs={3} className="btn1">
                                    <Button onClick={() => createExpressionOfEnterest()} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }} disabled={disableBtn}>Invite</Button>
                                  </Grid>
                                </Grid>
                                <Divider />
                              </div>
                            )}
                            {relationShipStatus === "PENDING" && (
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                  {searchedProfile.firstName || searchedProfile.profileName} is interested in your profile.
                                </div>
                                <div>
                                  <Button disabled={disableBtn} onClick={() => approveExpressionOfEnterest(expressionOfInterestId)} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px', margin: '5px' }}>Accept</Button>
                                  <Button disabled={disableBtn} onClick={() => rejectExpressionOfEnterest(expressionOfInterestId)} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#D52828', padding: '5px', height: '20px', fontSize: '10px', margin: '5px' }}>Decline</Button>
                                </div>
                              </div>
                            )}
                            {relationShipStatus === "SENT" && (
                              <div>
                                <Grid container spacing={2}>
                                  <Grid item xs={9}>
                                    You have already expressed interest to this profile.
                                    Do you want to cancel the request?
                                  </Grid>
                                  <Grid item xs={3} className="btn1">
                                    <Button disabled={disableBtn} onClick={() => cancelExpressionOfEnterest(expressionOfInterestId)} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }}>Cancel</Button>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            {(relationShipStatus === "APPROVED" ||
                              relationShipStatus === "APPROVEDBYME") && (
                                <div>
                                  {/* Revoking */}
                                  <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                      Do you want to Disconnect ?
                                    </Grid>
                                    <Grid item xs={3} className="btn1">
                                      <Button disabled={disableBtn} onClick={() => disconnectExpressionOfEnterest(expressionOfInterestId)} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }}> Revoke</Button>
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                            {relationShipStatus === "REJECTED" && (
                              <div>
                                <Grid container spacing={2}>
                                  <Grid item xs={9}>
                                    {searchedProfile.firstName || searchedProfile.profileName} declined your request. You need to wait for 180 days from the date of declining it to make another connection request.
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            {relationShipStatus === "REJECTEDBYME" && (
                              <div>
                                <Grid container spacing={2}>
                                  <Grid item xs={9}>
                                    You have already rejected this profile. Do you want to
                                    invite again?
                                  </Grid>
                                  <Grid item xs={3} className="btn1">
                                    <Button disabled={disableBtn} onClick={() => createExpressionOfEnterest()} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }}>Invite</Button>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <hr />
                    </div>

                    {/* //// */}
                    <div
                    // className="firstdata"
                    >
                      <div>
                        {((!relationShipStatusLoading && searchedProfile.photoStatus === "Request" && searchedProfile.isPhotosUploaded) || (!relationShipStatusLoading && !photoConnectedStatus && !photoConnectedByMeStatus && !connectedStatus && !photoReqSentStatus && photoReqReceivedStatus && searchedProfile.photoStatus === "Public") || (searchedProfile.photoStatus === "Public" && !relationShipStatusLoading && photoConnectedByMeStatus)) && (
                          <>
                            {(!photoConnectedStatus && !photoConnectedByMeStatus && !connectedStatus && !photoReqSentStatus && !photoReqReceivedStatus && searchedProfile.photoStatus === "Request") && (
                              <div>
                                <Grid container spacing={2}>
                                  <Grid item xs={9}>
                                    Do you want to requsest for profile photos?
                                  </Grid>
                                  <Grid item xs={3} className="btn1">
                                    <Button disabled={disableBtn} onClick={() => requestForImages()} className="dmsans reqpts" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }}>Request photos</Button>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            {(!photoConnectedStatus && !photoConnectedByMeStatus && !connectedStatus && !photoReqReceivedStatus && photoReqSentStatus) && (
                              <div>
                                <Grid container spacing={2}>
                                  <Grid item xs={8}>
                                    You have already sent the profile photo request. Do you want to cancel?
                                  </Grid>
                                  <Grid item xs={4} className="btn1">
                                    <Button disabled={disableBtn} onClick={() => cancelSentImageRequest()} className="dmsans reqpts" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }}>Cancel photo request</Button>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            {(!photoConnectedStatus && !photoConnectedByMeStatus && !connectedStatus && !photoReqSentStatus && photoReqReceivedStatus) && (
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                  {searchedProfile.firstName || searchedProfile.profileName} requested your profile photos
                                </div>
                                <div>
                                  <Button disabled={disableBtn} onClick={() => approveReceivedImageRequest()} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '20px', fontSize: '10px' }}>Accept</Button>
                                  <Button disabled={disableBtn} onClick={() => rejectReceivedImageRequest()} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#D52828', padding: '5px', height: '20px', fontSize: '10px' }}>Decline</Button>
                                </div>
                              </div>
                            )}
                            {(photoConnectedByMeStatus
                              && !(relationShipStatus === "APPROVED" ||
                                relationShipStatus === "APPROVEDBYME"))
                              && (
                                <div>
                                  Cancel sharing my profile photos with {searchedProfile.firstName || searchedProfile.profileName}.
                                  <Button disabled={disableBtn} onClick={() => disconnectApprovedImgRequest()} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#D52828', padding: '5px', height: '20px', fontSize: '10px' }}>Cancel</Button>
                                </div>
                              )}

                            {(!photoConnectedByMeStatus && photoConnectedStatus) && (
                              <div>
                                Your profile photo request has approved by {searchedProfile.firstName || searchedProfile.profileName}.
                                {/* <Button onClick={() => disconnectApprovedImgRequest()} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#D52828', padding: '5px', height: '20px', fontSize: '10px' }}>Cancel</Button> */}
                              </div>
                            )}
                            <hr />
                          </>
                        )}
                      </div>
                    </div>
                    {/* //// */}
                    <div>
                      <div className="seconddata">
                        <div>
                          {(searchedProfile.firstName || searchedProfile.profileName) && <h3>{searchedProfile.firstName || searchedProfile.profileName}</h3>}
                        </div>
                        <div>
                          <FavoriteToggleButton bookmarkId={searchedProfile.id} />
                        </div>
                      </div>
                      <div className="thirddata">
                        <div>
                          <h3>{getAge(searchedProfile.dob)} Years Old</h3>
                        </div>
                        <div>
                          {/* <Button className="dmsans" variant="outlined" style={{ borderRadius: '30px', borderColor: 'red', color: 'red', padding: '5px', height: '20px', fontSize: '10px', fontWeight: '600' }}>Remove from connected list</Button> */}
                        </div>
                      </div>
                      {searchedProfile.about && <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="topicprofile">
                          <InfoIcon />
                          <h5>About </h5>
                        </div>
                        <p2>{searchedProfile.about}</p2>
                      </div>}
                    </div>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} lg={6} xs={12}>
                  <div className="sides">
                    {(relationShipStatus === "APPROVEDBYME" ||
                      relationShipStatus === "APPROVED") && (
                        <>
                          <div className="topicprofile">
                            <PhoneIcon />
                            <h5>Contact Details</h5>
                          </div>
                          <div>
                            <p1>
                              Email: {searchedProfile.contactDetails?.email || "N/A"}
                            </p1>
                          </div>
                          <div>
                            <p1>
                              Phone No: {searchedProfile.contactDetails?.phoneNumber || "N/A"}
                            </p1>
                          </div>
                        </>
                      )}
                    {(searchedProfile.location?.religion?.name || searchedProfile.location?.community?.name || searchedProfile.location?.motherTongue?.name ||
                      searchedProfile.height?.name || searchedProfile.maritalStatus?.name || searchedProfile.location?.ethnicOrigin?.name || searchedProfile.location?.country?.name
                      || searchedProfile.location?.residentialStatus?.name) &&
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="data">
                          <div className="topicprofile">
                            <Person />
                            <h5>Personal Details</h5>
                          </div>
                          {searchedProfile.location?.religion?.name && <p1>Religion: {searchedProfile.location.religion.name}</p1>}
                          {searchedProfile.location?.community?.name && <p1>Community: {searchedProfile.location.community.name}</p1>}
                          {searchedProfile.location?.motherTongue?.name && <p1>Speaks: {searchedProfile.location?.motherTongue?.name}</p1>}
                          {/* Add code to display spoken languages */}
                          {searchedProfile.height?.name && <p1>Height: {searchedProfile.height.name}</p1>}
                          {searchedProfile.maritalStatus?.name && <p1>Marital status: {searchedProfile.maritalStatus.name}</p1>}
                          {searchedProfile.location?.ethnicOrigin?.name && <p1>Ethnic origin: {searchedProfile.location.ethnicOrigin.name}</p1>}
                          {(searchedProfile.location?.country?.name || searchedProfile.location?.state?.name || searchedProfile.location?.city?.name) && (
                            <p1>
                              From:{" "}
                              {renderWithComma(searchedProfile.location?.city?.name)}
                              {renderWithComma(searchedProfile.location?.state?.name)}
                              {searchedProfile.location?.country?.name}
                            </p1>
                          )}
                          {searchedProfile.location?.residentialStatus?.name && <p1>Residential Status: {searchedProfile.location?.residentialStatus?.name}</p1>}
                        </div>
                      </div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className="data">
                        <div className="topicprofile">
                          <EmojiObjectsIcon />
                          <h5>Smoking, Drinking and Dietary Habits</h5>
                        </div>
                        <p1>Dietary Habbit: {searchedProfile.dietHabbit?.name ? (searchedProfile.dietHabbit?.name) : ("N/A")}</p1>
                        <p1>Drinking Habbit: {searchedProfile.drinkingHabbit?.name ? (searchedProfile.drinkingHabbit?.name) : ("N/A")}</p1>
                        <p1>Smoking Habbit: {searchedProfile.smokingHabbit?.name ? (searchedProfile.smokingHabbit?.name) : ("N/A")}</p1>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className="data">
                        <div className="topicprofile">
                          <Group />
                          <h5>Family Details</h5>
                        </div>
                        {searchedProfile.hasChildren ? (
                          <>
                            <p1>Has Children : Yes</p1>
                            {searchedProfile.numberOfKids && (
                              <p1>No of children : {searchedProfile.numberOfKids}</p1>
                            )}
                          </>
                        ) : (<p1>Has Children : No</p1>)}
                        <p1>Father: {searchedProfile.family?.fatherStatus?.name ? (searchedProfile.family?.fatherStatus?.name) : ("N/A")}</p1>
                        <p1>Mother: {searchedProfile.family?.motherStatus?.name ? (searchedProfile.family?.motherStatus?.name) : ("N/A")}</p1>
                        <p1>Number of Brothers: {searchedProfile.family?.numberOfBrothers ? (searchedProfile.family?.numberOfBrothers) : ("N/A")}</p1>
                        <p1>Number of Married Brothers: {searchedProfile.family?.numberOfBrothersMarried ? (searchedProfile.family?.numberOfBrothersMarried) : ("N/A")}</p1>
                        <p1>Number of Sisters: {searchedProfile.family?.numberOfSisters ? (searchedProfile.family?.numberOfSisters) : ("N/A")}</p1>
                        <p1>Number of Married Sisters: {searchedProfile.family?.numberOfSistersMarried ? (searchedProfile.family?.numberOfSistersMarried) : ("N/A")}</p1>
                        <p1>Caste: {searchedProfile.location?.cast?.name ? (searchedProfile.location?.cast?.name) : ("N/A")}</p1>
                        {searchedProfile.location?.castNoBar && <p1>Cast no bar</p1>}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className="data">
                        <div className="topicprofile">
                          <School />
                          <h5>Education and Profession</h5>
                        </div>
                        <p1>Qualification: {searchedProfile.educationAndProfession?.highestQualification?.name ? (searchedProfile.educationAndProfession?.highestQualification?.name) : ("N/A")}</p1>
                        <p1>Job Sector: {searchedProfile.educationAndProfession?.jobSector?.name ? (searchedProfile.educationAndProfession?.jobSector?.name) : ("N/A")}</p1>
                        <p1>Profession: {searchedProfile.educationAndProfession?.profession?.name ? (searchedProfile.educationAndProfession?.profession?.name) : ("N/A")}</p1>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className="data">
                        <div className="topicprofile">
                          <EmojiEmotions />
                          <h5>Hobbies and Interests</h5>
                        </div>
                        <p1>Hobbies: {searchedProfile.profileHobbies && searchedProfile.profileHobbies.length > 0 ? (
                          searchedProfile.profileHobbies.map((cuisine, index) => {
                            if (index < searchedProfile.profileHobbies.length - 1) {
                              return cuisine.name + separator;
                            }
                            return cuisine.name;
                          })
                        ) : (
                          "N/A"
                        )}</p1>
                        <p1>Interests: {searchedProfile.profileInterests && searchedProfile.profileInterests.length > 0 ? (
                          searchedProfile.profileInterests.map((cuisine, index) => {
                            if (index < searchedProfile.profileInterests.length - 1) {
                              return cuisine.name + separator;
                            }
                            return cuisine.name;
                          })
                        ) : (
                          "N/A"
                        )}</p1>
                        <p1>Movie Genres: {searchedProfile.profileMovieGenres && searchedProfile.profileMovieGenres.length > 0 ? (
                          searchedProfile.profileMovieGenres.map((cuisine, index) => {
                            if (index < searchedProfile.profileMovieGenres.length - 1) {
                              return cuisine.name + separator;
                            }
                            return cuisine.name;
                          })
                        ) : (
                          "N/A"
                        )}</p1>
                        <p1>Music Genres: {searchedProfile.profileMusicGenres && searchedProfile.profileMusicGenres.length > 0 ? (
                          searchedProfile.profileMusicGenres.map((cuisine, index) => {
                            if (index < searchedProfile.profileMusicGenres.length - 1) {
                              return cuisine.name + separator;
                            }
                            return cuisine.name;
                          })
                        ) : (
                          "N/A"
                        )}</p1>
                        <p1>Sports And Fitness Activities: {searchedProfile.profileSportsAndFitnessActivities && searchedProfile.profileSportsAndFitnessActivities.length > 0 ? (
                          searchedProfile.profileSportsAndFitnessActivities.map((cuisine, index) => {
                            if (index < searchedProfile.profileSportsAndFitnessActivities.length - 1) {
                              return cuisine.name + separator;
                            }
                            return cuisine.name;
                          })
                        ) : (
                          "N/A"
                        )}</p1>
                        <p1>Preferred Attire: {searchedProfile.profileDressStyles && searchedProfile.profileDressStyles.length > 0 ? (
                          searchedProfile.profileDressStyles.map((cuisine, index) => {
                            if (index < searchedProfile.profileDressStyles.length - 1) {
                              return cuisine.name + separator;
                            }
                            return cuisine.name;
                          })
                        ) : (
                          "N/A"
                        )}</p1>
                        <p1>Cuisines: {searchedProfile.profileCuisines && searchedProfile.profileCuisines.length > 0 ? (
                          searchedProfile.profileCuisines.map((cuisine, index) => {
                            if (index < searchedProfile.profileCuisines.length - 1) {
                              return cuisine.name + separator;
                            }
                            return cuisine.name;
                          })
                        ) : (
                          "N/A"
                        )}</p1>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className="data">
                        <div className="topicprofile">
                          <Star />
                          <h5>Horoscope </h5>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '10px' }}>
                          {horoscopePhotos.length > 0 && (relationShipStatus === "APPROVEDBYME" ||
                            relationShipStatus === "APPROVED") ?
                            <CarouselCommon profilePhotos={horoscopePhotos} />
                            :
                            <p1>Horoscope photos unavailable.</p1>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          )
          }

        </Box>
      </div>
    </>
  );
}