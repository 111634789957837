import http from '../httpService';

const apiEndpoint = "/profiles";
const contactus = "/contactus";

function profileURL(id) {
  return `${apiEndpoint}/${id}`;
}

export function getProfile(id) {
  return http.get(profileURL(id));
}

export function getProfileByEmail(email, except) {
  return except
    ? http.get(`${apiEndpoint}/email/${email}?except=${except}`)
    : http.get(`${apiEndpoint}/email/${email}`);
}

export function getProfiles() {
  return http.get(`${apiEndpoint}`);
}

export function createProfile(body, id) {
  const profile = { ...body };
  return http.put(`${apiEndpoint}/updateProfile/${id}`, profile);
}
export function contactUs(body) {
  return http.post(`${contactus}`, body);
}

export function updateProfile(id, body) {
  const profile = { ...body };
  profile.identityProviderId = 1;
  profile.profileStatusId = 1;
  return http.put(profileURL(id), profile);
}

export function updateProfileLocationDetails(id, body) {
  return http.put(`${apiEndpoint}/${id}/location`, body);
}

export function updateProfileBasicDetails(id, body) {
  return http.put(`${apiEndpoint}/${id}/basic`, body);
}

export function updateProfileEducationAndProfessionDetails(id, body) {
  return http.put(`${apiEndpoint}/${id}/education-profession`, body);
}
export function updateProfilePhoneNumber(body) {
  return http.put(`/updateMemberDetails`, body);
}
//new profession
export function updateProfileEducationAndProfessionDetailsOther(id, body) {
  return http.put(`${apiEndpoint}/${id}/education-professionOther`, body);
}

export function updateProfileHobbyAndInterestDetails(id, body) {
  return http.put(`${apiEndpoint}/${id}/hobby-interest`, body);
}

export function updateProfilePartnerCriterionDetails(id, body) {
  return http.put(`${apiEndpoint}/${id}/partner-criterion`, body);
}

export function updateProfileReligiousBackgroundDetails(id, body) {
  return http.put(`${apiEndpoint}/${id}/religious-background`, body);
}

export function updateProfileFamilyDetails(id, body) {
  return http.put(`${apiEndpoint}/${id}/family`, body);
}

export function updateProfileLifestyleDetails(id, body) {
  return http.put(`${apiEndpoint}/${id}/lifestyle`, body);
}

export function deleteProfile(id) {
  return http.delete(`${apiEndpoint}/soft-delete/${id}`);
}

export function changeState(profileId, statusId) {
  return http.patch(`${apiEndpoint}/${profileId}?statusId=${statusId}`);
}

export function getUpdatedProfileFields(id) {
  return http.get(`profile-free-text-field-update-requests/get/${id}`);
}

export function getBookmarks(id) {
  return http.get(`${apiEndpoint}/${id}/getBookMarked`);
}

export function addBookmark(body) {
  return http.post(`${apiEndpoint}/addBookmark`, body);
}

export function removeBookmark(body) {
  return http.delete(`${apiEndpoint}/removeBookmark/${body.profileId}/${body.bookMarkedProfileId}`);
}

export function getSearchedProfiles(id, queryString) {
  return http.get(`${apiEndpoint}/${id}/searched-profiles?${queryString}`);
}

export function getPhotoStatuses(id) {
  return http.get(`/profiles/photoStatuses`);
}

export function updatePhotoStatus(id, status) {
  return http.put(`/profiles/${id}/photoStatus`, status);
}