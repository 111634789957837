import { useNavigate } from "react-router-dom";
import { useMember } from "../../Contexts/memberContext";
import React, { useEffect, useState } from 'react';
import '../../CSS/RegistrationSuccess.css';
import tickimg from '../../Assests/imgs/tick.jpg';
import { MicrosoftLoginAgent } from "../../Services/Authentication/msal";
import Footer from "../FooterPages/Footer.js";
import AppBar from "../NavBar/AppBar.js";

export default function VerifyEmail() {
  const [account] = useState(MicrosoftLoginAgent.getAccount());
  const navigate = useNavigate();
  const { profile } = useMember();

  useEffect(() => {
    if (profile?.isEmailVerified) {
      console.log("inside this");
      navigate("/board");
    }
  }, []);

  return (
    <div>
      <AppBar />
      <div className="verify-email-container" >
        <div className="verify-email-content">
          <div className="verify-email-header">
            <img src={tickimg} height='100px' />
          </div>
          <div className="verify-email-body">
            <h2>REGISTRATION SUCCESS</h2>
            <p>
              Thank you. We have sent you an email to <strong>{account.idTokenClaims?.email}</strong>.<br />
              Please click the link in that message to verify your email.
            </p>
            {/* <button className="btn" style={{border:'1px solid green', borderRadius:'30px', color:'green'}}>CLOSE</button> */}
          </div>          
        </div>        
      </div>
      <Footer />
    </div>
  );
}