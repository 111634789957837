import '../../CSS/FooterItems.css';

const PrivacyPolicy = () => {
  return (
    <div className="bottomspacedash">
      <ul className="a">
        <li>
        This policy is intended to help you understand transparency about how we
        collect, use, and share information.
        </li>
      </ul>      
      <ul className="b">
      <li>
          vivaakam.com application is a serious matchmaking platform to seek a
          life partner.
        </li>
        {/*2*/}
        <li>
          To avail of the service, you provide the following information: Basic
          Information (includes email address and contact phone number),
          location and religious background, family details, education &
          professions, hobby and interest, partner criteria.
        </li>
        {/*3*/}
        <li>
          We collect information about your activity on our services, such as
          date and time you logged in, features you have been using, searches,
          clicks, and pages visited by you, your interaction with other users.
        </li>
        {/*4*/}
        <li>
          We use the information collected in the following ways: to provide the
          service, manage your account, provide you with customer support,
          conduct research and analysis about your use of our services for
          providing better services, communicate with you by email, phone about
          services, promotions or offers that may be of your interest, recommend
          relevant matches to you and display your profile to other users.{" "}
        </li>
        {/*5*/}
        <li>
          While we implement safeguards designed to protect your information, no
          security system is impenetrable and due to the inherent nature of the
          internet, we cannot guarantee that data, during transmission through
          the internet or while stored on our systems or otherwise in our care,
          is safe from intrusion by others. We follow accepted industry
          standards to protect the personal information submitted to us.{" "}
        </li>
        {/*6*/}
        <li>
          We keep your personal information only as long as you use our service
          and as permitted/ required by applicable law. However, we anonymize
          your information upon deletion and retain some information for better
          services, and we only use the information to analyse the use of our
          services.
        </li>
        {/*7*/}
        <li>
          Please note any member who violates or disagrees with our privacy
          policy; harasses, assaults, and treats inappropriately any
          representative, or any other criminal activity, will be immediately
          erased from our database.{" "}
        </li>
        {/*8*/}
        <li>
          If you have any questions about this privacy statement, the practices
          of this site, or your dealings with the site, please email at:
          support@vivaakam.com{" "}
        </li>
      </ul> 
    </div>
  );
};

export default PrivacyPolicy;
