import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Spinner } from "../Common/Spinner";
import Typography from '@mui/material/Typography';
import { updateProfileFamilyDetails } from '../../Services/Profile/profileService.js';
import { useMember } from "../../Contexts/memberContext.js";
import { useApp } from "../../Contexts/appContext.js";
import Autocomplete from '@mui/material/Autocomplete';
import swal from 'sweetalert';
import {
  TextField,
  FormControl,
} from '@material-ui/core';

export const FamilyDetailsEditModal = ({ open, handleClose }) => {
  const { profile, setProfile } = useMember();
  const { setUserType, metaData } = useApp();
  const [modalLoading, setModalLoading] = useState(false)

  const [userFatherStatus, setUserFatherStatus] = useState(profile?.family?.fatherStatus || {});
  const [inputFatherStatus, setInputFatherStatus] = useState("");
  const [userFatherStatusEdited, setUserFatherStatusEdited] = useState(profile?.family?.fatherStatus?.id || null);

  const [userMotherStatus, setUserMotherStatus] = useState(profile?.family?.motherStatus || {});
  const [inputMotherStatus, setInputMotherStatus] = useState("");
  const [userMotherStatusEdited, setUserMotherStatusEdited] = useState(profile?.family?.motherStatus?.id || null);

  const [numberOfBrothers, setNumberOfBrothers] = useState(profile?.family?.numberOfBrothers || 0);
  const [numberOfMarriedBrothers, setNumberOfMarriedBrothers] = useState(profile?.family?.numberOfBrothersMarried || 0);
  const [numberOfSisters, setNumberOfSisters] = useState(profile?.family?.numberOfSisters || 0);
  const [numberOfMarriedSisters, setNumberOfMarriedSisters] = useState(profile?.family?.numberOfSistersMarried || 0);
  const [brotherCountErrMessage, setBrotherCountErrorMessage] = useState('');
  const [sisterCountErrMessage, setSisterCountErrorMessage] = useState('');
  const [marriedBrothersErrorMessage, setMarriedBrothersErrorMessage] = useState('');
  const [marriedSistersErrorMessage, setMarriedSistersErrorMessage] = useState('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    handleReset();
  }, [profile, metaData, open]);

  const checkValidation = async () => {
    setBrotherCountErrorMessage(numberOfBrothers > 10 ? 'You can add maximum 10 brothers' : numberOfBrothers < 0 ? 'Brothers count must be greater than 0' : '');
    setSisterCountErrorMessage(numberOfSisters > 10 ? 'You can add maximum 10 sisters' : numberOfSisters < 0 ? 'Sisters count must be greater than 0' : '');
    setMarriedSistersErrorMessage(numberOfSisters !== null && numberOfSisters > 0 && numberOfMarriedSisters > numberOfSisters ? 'Married sisters should not exceed total sisters count' :
      (numberOfMarriedSisters < 0 || numberOfMarriedSisters > 10) ? 'Please select the number of sisters properly (0-10)' : '');
    setMarriedBrothersErrorMessage(numberOfBrothers !== null && numberOfBrothers > 0 && numberOfMarriedBrothers > numberOfBrothers ? 'Married brothers should not exceed total brothers count' :
      (numberOfMarriedBrothers < 0 || numberOfMarriedBrothers > 10) ? 'Please select the number of brothers properly (0-10)' : '');
  }

  const handleEdit = async () => {
    checkValidation();
    if ((numberOfBrothers > 10 || numberOfBrothers < 0) || (numberOfSisters > 10 || numberOfSisters < 0) ||
      (numberOfSisters !== null && numberOfSisters > 0 && numberOfMarriedSisters > numberOfSisters) || (numberOfMarriedSisters < 0 || numberOfMarriedSisters > 10)
      || (numberOfBrothers !== null && numberOfBrothers > 0 && numberOfMarriedBrothers > numberOfBrothers) || (numberOfMarriedBrothers < 0 || numberOfMarriedBrothers > 10)
    ) {
      return;
    }
    const family = {};
    family.fatherStatusId = userFatherStatusEdited;
    family.motherStatusId = userMotherStatusEdited;
    family.numberOfSisters = numberOfSisters;
    family.numberOfSistersMarried = numberOfMarriedSisters;
    family.numberOfBrothers = numberOfBrothers;
    family.numberOfBrothersMarried = numberOfMarriedBrothers;

    setModalLoading(true);
    const response = await updateProfileFamilyDetails(profile?.id, family);
    if (response.status === 200) {
      setProfile(response.data);
      handleClose();
      swal({
        text: "Profile updated.",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      })
    } else if (response.status !== 200) {
      // handleClose();
      swal({
        text: 'Unsuccessful. Try again.',
        icon: 'error',
        dangerMode: true,
      });
    }
    setModalLoading(false);
    // handleClose();
  };

  const handleReset = async () => {
    setModalLoading(true);
    setUserFatherStatus(profile?.family?.fatherStatus || {});
    setUserMotherStatus(profile?.family?.motherStatus || {});
    setNumberOfBrothers(profile?.family?.numberOfBrothers || 0);
    setNumberOfMarriedBrothers(profile?.family?.numberOfBrothersMarried || 0);
    setNumberOfSisters(profile?.family?.numberOfSisters || 0);
    setNumberOfMarriedSisters(profile?.family?.numberOfSistersMarried || 0);
    setBrotherCountErrorMessage('');
    setSisterCountErrorMessage('');
    setMarriedSistersErrorMessage('');
    setMarriedBrothersErrorMessage('');
    setModalLoading(false);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalLoading ? <Spinner></Spinner> :
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '350px' },
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ /* styles */ }}>
                {/* Modal content */}
                <Typography className="dmsans" id="modal-modal-title" variant="h6" component="h2">
                Update Family Details.
                </Typography>
                <br />
                <FormControl fullWidth>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={metaData.jobStatuses.find(option => option.id === userFatherStatus?.id || null) || null}
                      clearIcon={null}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setUserFatherStatus(newValue);
                          setUserFatherStatusEdited(newValue.id)
                        } else {
                          setUserFatherStatus(null);
                          setUserFatherStatusEdited(null)
                        }
                      }}
                      inputValue={inputFatherStatus}
                      onInputChange={(event, newInputValue) => {
                        setInputFatherStatus(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={metaData.jobStatuses}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Father Status" />}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={metaData.jobStatuses.find(option => option.id === userMotherStatus?.id || null) || null}
                      clearIcon={null}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setUserMotherStatus(newValue);
                          setUserMotherStatusEdited(newValue.id)
                        } else {
                          setUserMotherStatus(null);
                          setUserMotherStatusEdited(null)
                        }
                      }}
                      inputValue={inputMotherStatus}
                      onInputChange={(event, newInputValue) => {
                        setInputMotherStatus(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={metaData.jobStatuses}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Mother Status" />}
                    />
                  </FormControl>
                  <TextField
                    label="Number of Sisters"
                    type="number"
                    fullWidth
                    inputProps={{ min: 0, max: 10 }}
                    value={numberOfSisters}
                    onChange={(e) => {
                      if (parseInt(e.target.value) === 0 || !e.target.value) {
                        setNumberOfMarriedSisters(0)
                      }
                      setNumberOfSisters(parseInt(e.target.value));
                      setSisterCountErrorMessage(e.target.value > 10 ? 'You can add maximum 10 sisters' : e.target.value < 0 ? 'Sisters count must be greater than 0' : '');
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'red' }}>{sisterCountErrMessage}</p>
                  {numberOfSisters !== null && numberOfSisters > 0 && (
                    <>
                      <TextField
                        label="Number of Married Sisters"
                        type="number"
                        fullWidth
                        inputProps={{ min: 0, max: numberOfSisters }}
                        value={numberOfMarriedSisters}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (!isNaN(value) && value <= numberOfSisters) {
                            setNumberOfMarriedSisters(value);
                            setMarriedSistersErrorMessage(numberOfSisters !== null && numberOfSisters > 0 && value > numberOfSisters ? 'Married sisters should not exceed total sisters count' :
                              (value < 0 || value > 10) ? 'Please select the number of sisters properly (0-10)' : '');
                          }
                        }}
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />
                      <p style={{ color: 'red' }}>{marriedSistersErrorMessage}</p>
                    </>
                  )}
                  <TextField
                    label="Number of Brothers"
                    type="number"
                    fullWidth
                    value={numberOfBrothers}
                    inputProps={{ min: 0, max: 10 }}
                    onChange={(e) => {
                      if (parseInt(e.target.value) === 0 || !e.target.value) {
                        setNumberOfMarriedBrothers(0)
                      }
                      setNumberOfBrothers(parseInt(e.target.value));
                      setBrotherCountErrorMessage(e.target.value > 10 ? 'You can add maximum 10 brothers' : e.target.value < 0 ? 'Brothers count must be greater than 0' : '');
                    }}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'red' }}>{brotherCountErrMessage}</p>
                  {numberOfBrothers !== null && numberOfBrothers > 0 && (
                    <>
                      <TextField
                        label="Number of Married Brothers"
                        type="number"
                        fullWidth
                        inputProps={{ min: 0, max: numberOfBrothers }}
                        value={numberOfMarriedBrothers}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (!isNaN(value) && value <= numberOfBrothers) {
                            setNumberOfMarriedBrothers(value);
                            setMarriedBrothersErrorMessage(numberOfBrothers !== null && numberOfBrothers > 0 && value > numberOfBrothers ? 'Married brothers should not exceed total brothers count' :
                              (value < 0 || value > 10) ? 'Please select the number of brothers properly (0-10)' : '');
                          }
                        }}
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />
                      <p style={{ color: 'red' }}>{marriedBrothersErrorMessage}</p>
                    </>
                  )}
                </FormControl>
                <div className="editmodal">
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleClose}>Cancel</Button>
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleReset}>Reset</Button>
                  <Button className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC' }} onClick={handleEdit}>Save</Button>
                </div>
              </Box>
            </Box>
          </Box>
        }
      </Modal>
    </>
  );
};