import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Spinner, ButtonSpinner } from "../Common/Spinner";
import Typography from '@mui/material/Typography';
import { updateProfileHobbyAndInterestDetails } from '../../Services/Profile/profileService.js';
import { useMember } from "../../Contexts/memberContext.js";
import { useApp } from "../../Contexts/appContext.js";
import Autocomplete from '@mui/material/Autocomplete';
import swal from 'sweetalert';
import {
  TextField,
  FormControl,
} from '@material-ui/core';

export const HobbiesInterestsEditModal = ({ open, handleClose }) => {
  const { profile, setProfile } = useMember();
  const { setUserType, metaData } = useApp();
  const [modalLoading, setModalLoading] = useState(false)
  const [userHobbies, setUserHobbies] = useState(profile?.profileHobbies || []);
  const [userMovieGenres, setUserMovieGenres] = useState(profile?.profileMovieGenres || []);
  const [userCuisines, setUserCuisines] = useState(profile?.profileCuisines || []);
  const [userMusicGenres, setUserMusicGenres] = useState(profile?.profileMusicGenres || []);
  const [userInterests, setUserInterests] = useState(profile?.profileInterests || []);
  const [userDressStyles, setUserDressStyles] = useState(profile?.profileDressStyles || []);
  const [userSportsAndFitnessActivities, setUserSportsAndFitnessActivities] = useState(profile?.profileSportsAndFitnessActivities || []);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    handleReset();
  }, [profile, metaData, open]);

  const handleEdit = async () => {
    const hobbyAndInterest = {};
    hobbyAndInterest.profileHobbies = userHobbies || [];
    hobbyAndInterest.profileSportsAndFitnessActivities = userSportsAndFitnessActivities || [];
    hobbyAndInterest.profileDressStyles = userDressStyles || [];
    hobbyAndInterest.profileInterests = userInterests || [];
    hobbyAndInterest.profileMusicGenres = userMusicGenres || [];
    hobbyAndInterest.profileCuisines = userCuisines || [];
    hobbyAndInterest.profileMovieGenres = userMovieGenres || [];

    setModalLoading(true);
    const response = await updateProfileHobbyAndInterestDetails(profile?.id, hobbyAndInterest);
    if (response.status === 200) {
      setProfile(response.data);
      handleClose();
      swal({
        text: "Profile updated.",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      })
    } else if (response.status !== 200) {
      swal({
        text: 'Unsuccessful. Try again.',
        icon: 'error',
        dangerMode: true,
      });
      console.log("error in else")
    }
    setModalLoading(false);
  };

  const handleReset = async () => {
    setModalLoading(true);
    setUserHobbies(profile?.profileHobbies || []);
    setUserMovieGenres(profile?.profileMovieGenres || []);
    setUserCuisines(profile?.profileCuisines || []);
    setUserMusicGenres(profile?.profileMusicGenres || []);
    setUserInterests(profile?.profileInterests || []);
    setUserDressStyles(profile?.profileDressStyles || []);
    setUserSportsAndFitnessActivities(profile?.profileSportsAndFitnessActivities || []);
    setModalLoading(false);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalLoading ? <Spinner></Spinner> :
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '350px' },
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ /* styles */ }}>
                {/* Modal content */}
                <Typography className="dmsans" id="modal-modal-title" variant="h6" component="h2">
                Update hobbies and interests.
                </Typography>
                <br />
                <div style={{ overflowY: 'scroll', height: '500px' }}>
                  <FormControl fullWidth>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userHobbies}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserHobbies(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.hobbies.filter(option => !userHobbies.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Hobbies" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userInterests}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserInterests(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.interests.filter(option => !userInterests.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Interests" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userDressStyles}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserDressStyles(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.dressStyles.filter(option => !userDressStyles.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Dress Styles" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userCuisines}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserCuisines(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.cuisines.filter(option => !userCuisines.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Cuisines" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userMovieGenres}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserMovieGenres(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.movieGenres.filter(option => !userMovieGenres.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Movie Genres" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userMusicGenres}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserMusicGenres(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.musicGenres.filter(option => !userMusicGenres.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Music Genres" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userSportsAndFitnessActivities}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserSportsAndFitnessActivities(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.sportsAndFitnessActivities.filter(option => !userSportsAndFitnessActivities.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Sports And Fitness Activities" />}
                      />
                    </FormControl>
                  </FormControl>
                </div>
                <div className="editmodal">
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleClose}>Cancel</Button>
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleReset}>Reset</Button>
                  <Button className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC' }} onClick={handleEdit}>Save</Button>
                </div>
              </Box>
            </Box>
          </Box>
        }
      </Modal>
    </>
  );
};