import React from 'react';
import swal from 'sweetalert';

const Base64PdfLink = ({ base64Pdf, fileName, photoId, profileId }) => {
  // Function to convert base64 to a Blob
  const base64ToBlob = (base64) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf' });
  };

  // Convert base64 to Blob
  const pdfBlob = base64ToBlob(base64Pdf);

  // Create a URL from the Blob
  const pdfUrl = URL.createObjectURL(pdfBlob);

  return (
    <div>
      <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
        View PDF in New Tab
      </a>
    </div>
  );
};

export default Base64PdfLink;
