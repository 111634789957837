import React, { useState, useEffect } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import { useMember } from "../../Contexts/memberContext.js";
import { addBookmark, removeBookmark, getBookmarks } from "../../Services/Profile/profileService.js";
import swal from 'sweetalert';
import { Spinner, ButtonSpinner } from "../Common/Spinner.js";

const FavoriteToggleButton = (bookmarkId) => {
  const [isFavorited, setIsFavorited] = useState(false);
  const { profile, setProfileLoading, profileLoading, bookMarkedProfiles, setBookMarkedProfiles } = useMember();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (bookMarkedProfiles.length > 0) {
      // Check if the profile is bookmarked
      const isProfileBookmarked = bookMarkedProfiles.some(item => item.bookMarkedProfile.id === bookmarkId.bookmarkId);
      // Set isFavorited state based on whether the profile is bookmarked or not
      setIsFavorited(isProfileBookmarked);
    }
  }, []);

  const addToBookmarked = async (profilesObject) => {
    const addedResponse = await addBookmark(profilesObject);
    if (addedResponse.status === 200) {
      swal({
        text: "Added successfully",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
      // Update bookMarkedProfiles state after adding bookmark
      const bookmarks = await getBookmarks(profile.id);
      if (bookmarks.status === 201) {
        setBookMarkedProfiles(bookmarks.data);
      }
      setIsFavorited((prev) => !prev);
    } else {
      swal({
        text: 'Unsuccessful. Please try again.',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const removeFromBookmarked = async (profilesObject) => {
    const removedResponse = await removeBookmark(profilesObject);
    if (removedResponse.status === 200) {
      swal({
        text: "Removed successfully",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
      // Remove the bookmarked profile from bookMarkedProfiles state
      const bookmarks = await getBookmarks(profile.id);
      if (bookmarks.status === 201) {
        setBookMarkedProfiles(bookmarks.data);
      }
      setIsFavorited((prev) => !prev);
    } else {
      swal({
        text: 'Unsuccessful. Please try again.',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const handleClick = async () => {
    setLoading(true);
    if (bookMarkedProfiles.length === 10) {
      swal({
        text: "You can favourite upto 10 profile",
        buttons: {
          cancel: 'Close',
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      }).then((response) => {
        if (response) {
          return;
        }
      });
    }
    const profilesObject = {
      profileId: profile.id,
      bookMarkedProfileId: bookmarkId.bookmarkId
    };
    if (isFavorited) {
      swal({
        text: "Do you want to remove this profile form favourites?",
        buttons: {
          cancel: 'Close',
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      }).then(async (response) => {
        if (response) {
          await removeFromBookmarked(profilesObject);
          setLoading(false);
          // setIsFavorited((prev) => !prev);
        } else {
          setLoading(false);
        }
      });
    } else if (!isFavorited) {
      swal({
        text: "Do you want to add this profile to favourites?",
        buttons: {
          cancel: 'Close',
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      }).then(async (response) => {
        if (response) {
          await addToBookmarked(profilesObject);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  };

  return (
    <>
      {loading ? <Spinner /> : (
        <IconButton
          onClick={() => handleClick()}
          color="inherit"
          style={{
            backgroundColor: '#AEE4EE',
            // border: `1px solid ${isFavorited ? '#006A76' : '#AEE4EE'}`,
            borderRadius: '15px'
          }}
        >
          {isFavorited ? <FavoriteIcon style={{ color: '#006A76' }} /> : <FavoriteBorderIcon style={{ color: '#006A76' }} />}
        </IconButton>
      )}
    </>
  );

};

export default FavoriteToggleButton;