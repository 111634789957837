import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, Radio, FormControlLabel, RadioGroup, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { getOppositeGender } from '../../Utils/getOppositeGender.js';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import '../../CSS/Search.css';
import FormGroup from '@mui/material/FormGroup';
import { useApp } from "../../Contexts/appContext";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useMember } from "../../Contexts/memberContext.js";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicGrid() {

  const [age, setAge] = React.useState('');
  const [searchedAgeTo, setSearchedAgeTo] = useState('');
  const [searchedAgeFrom, setSearchedAgeFrom] = useState('');
  const [searchedHeightTo, setSearchedHeightTo] = useState('');
  const [searchedHeightFrom, setSearchedHeightFrom] = useState('');
  const [searchedReligion, setSearchedReligion] = useState([]);
  const [searchedCountry, setSearchedCountry] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [motherTongue, setMotherTongue] = useState([]);
  const [hasChildren, setHasChildren] = useState("both");
  const [considerCast, setConsiderCast] = useState("both");
  const [dietHabbit, setDietHabbit] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { profile } = useMember();


  const handleToggle = () => {
    setOpen(!open);
  };

  const { metaData } = useApp();

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleFromChangeHeight = (e) => {
    const selectedFromValue = parseFloat(e.target.value);
    setSearchedHeightFrom(selectedFromValue);
  };

  const handleToChangeHeight = (e) => {
    const selectedToValue = parseFloat(e.target.value);
    setSearchedHeightTo(selectedToValue);
  };

  const handleFromChangeAge = (e) => {
    const selectedFromValue = parseInt(e.target.value);
    setSearchedAgeFrom(selectedFromValue);
  };

  const handleToChangeAge = (e) => {
    const selectedToValue = parseInt(e.target.value);
    setSearchedAgeTo(selectedToValue);
  };


  const getAgeLowerBoundOptions = () => {
    if (!searchedAgeTo) return metaData.ages;

    const options = metaData.ages.filter(
      (age) =>
        parseInt(age.name) <= parseInt(searchedAgeTo)
    );
    return options;
  };

  const getAgeUpperBoundOptions = () => {
    if (!searchedAgeFrom) return metaData.ages;

    const options = metaData.ages.filter(
      (age) =>
        parseInt(age.name) >= parseInt(searchedAgeFrom)
    );
    return options;
  };

  const getHeightLowerBoundOptions = () => {
    if (!searchedHeightTo) return metaData.heights;

    const options = metaData.heights.filter(
      (h) =>
        h.centimetreValue <= parseFloat(searchedHeightTo)
    );
    return options;
  };

  const getHeightUpperBoundOptions = () => {
    if (!searchedHeightFrom) return metaData.heights;

    const options = metaData.heights.filter(
      (h) =>
        h.centimetreValue >= parseFloat(searchedHeightFrom)
    );
    return options;
  };

  const clickLetsGo = async () => {
    const data = {
      // hasChildren: hasChildren,
      // castNoBar: considerCast,
      ageLowerBound: [searchedAgeFrom],
      ageUpperBound: [searchedAgeTo],
      heightLowerBound: [searchedHeightFrom],
      heightUpperBound: [searchedHeightTo],
      gender: getOppositeGender(profile.gender.name),
      religion: [searchedReligion],
      country: [searchedCountry],
      dietHabbit: dietHabbit,
      motherTongue: motherTongue,
      maritalStatus: maritalStatus
    };
    if (hasChildren === 'has' || hasChildren === 'no') {
      const hasChildrenValue = hasChildren === 'has';
      data.hasChildren = hasChildrenValue;
    }
    if (considerCast !== 'both') {
      const castValue = considerCast === 'has';
      data.castNoBar = castValue;
    }
    navigate('/resultpage', { state: { data } });
  };


  return (
    <div className=" bottomspacedash" style={{ marginTop: '100px', minHeight: '100vh' }}>
      <h5 className="dmsans" style={{ margin: '20px' }}>
        I'm looking for,
      </h5>
      <Card className="searchContent">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Item className="itemShape">
                <div>
                  <Typography className="alignLeft acordion_items" gutterBottom>
                    Age
                  </Typography>
                  <div>
                    <FormControl className="age" style={{ display: 'flex', flexDirection: 'row' }}>
                      From
                      <div>&nbsp;</div>
                      <Select
                        value={searchedAgeFrom}
                        onChange={handleFromChangeAge}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Value' }}
                        style={{ borderRadius: '20px', width: '80px', height: '40px', backgroundColor: '#F4F7FD' }}
                      >
                        {getAgeLowerBoundOptions().map((age, index) => (
                          <MenuItem key={index} value={parseInt(age.name)}>{age.name}</MenuItem>
                        ))}
                      </Select>
                      <div>&nbsp;</div>
                      To
                      <div>&nbsp;</div>
                      <Select
                        className="dropdown"
                        value={searchedAgeTo}
                        onChange={handleToChangeAge}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Value' }}
                        style={{ borderRadius: '20px', width: '80px', height: '40px', backgroundColor: '#F4F7FD' }}
                      >
                        {getAgeUpperBoundOptions().map((age, index) => (
                          <MenuItem key={index} value={parseInt(age.name)}>{age.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Item className="itemShape">
                <div>
                  <Typography className="alignLeft acordion_items" gutterBottom>
                    Height
                  </Typography>
                  <div>
                    <FormControl className="age" style={{ display: 'flex', flexDirection: 'row' }}>
                      From
                      <div>&nbsp;</div>
                      <Select
                        className="dropdown"
                        value={searchedHeightFrom}
                        onChange={handleFromChangeHeight}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Value' }}
                        style={{ borderRadius: '20px', width: '80px', height: '40px', backgroundColor: '#F4F7FD' }}
                      >
                        {getHeightLowerBoundOptions().map((height, index) => (
                          <MenuItem key={index} value={parseFloat(height.centimetreValue)}>{height.name}</MenuItem>
                        ))}
                      </Select>
                      <div>&nbsp;</div>
                      To
                      <div>&nbsp;</div>
                      <Select
                        value={searchedHeightTo}
                        onChange={handleToChangeHeight}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Value' }}
                        style={{ borderRadius: '20px', width: '80px', height: '40px', backgroundColor: '#F4F7FD' }}
                      >
                        {getHeightUpperBoundOptions().map((height, index) => (
                          <MenuItem key={index} value={parseFloat(height.centimetreValue)}>{height.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Item>
            </Grid>

            <Grid item xs={6} sm={6} md={2} lg={2}>
              <Item className="itemShape">
                <div>
                  <Typography className="acordion_items alignLeft" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Religion
                  </Typography>
                  <div className="alignLeft">
                    <FormControl className="religion" style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
                      <Select
                        multiple
                        value={searchedReligion}
                        onChange={(e) => { setSearchedReligion(e.target.value); }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Value' }}
                        style={{ borderRadius: '20px', width: '100px', height: '40px', backgroundColor: '#F4F7FD' }}
                      >
                        {metaData.religions.map((religion, index) => (
                          <MenuItem key={index} value={religion.name}>{religion.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Item>
            </Grid>

            <Grid item xs={6} sm={6} md={2} lg={2}>
              <Item className="itemShape">
                <div>
                  <Typography className="acordion_items alignLeft" gutterBottom>
                    Mother Tongue
                  </Typography>
                  <div className="alignLeft">
                    <FormControl>
                      <Select
                        multiple
                        value={motherTongue}
                        onChange={(e) => { setMotherTongue(e.target.value); }} // Ensure e.target.value is always an array
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Value' }}
                        style={{ borderRadius: '20px', width: '100px', height: '40px', backgroundColor: '#F4F7FD' }}
                      >
                        {metaData.motherTongues.map((motherTongue, index) => (
                          <MenuItem key={index} value={motherTongue.name}>{motherTongue.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Item className="itemShape">
                <div>
                  <Typography className="acordion_items alignLeft" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Living in
                  </Typography>
                  <div className="alignLeft">
                    <FormControl >
                      <Select
                        className="living-in"
                        multiple
                        value={searchedCountry}
                        onChange={(e) => { setSearchedCountry(e.target.value); }} // Ensure e.target.value is always an array
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Value' }}
                        style={{ borderRadius: '20px', width: '100px', height: '40px', backgroundColor: '#F4F7FD' }}
                      >
                        {metaData.countries.map((country, index) => (
                          <MenuItem key={index} value={country.name}>{country.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Item className="itemShape2">
                <Accordion className={"filteraccordion"}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="acordion_items alignLeft">Dietary Habit</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography >
                      {metaData.dietHabbits &&
                        metaData.dietHabbits.map((field) => (
                          <div key={field.name} className="datafieldsacc" >
                            <Checkbox
                              value={field.name}
                              checked={dietHabbit.includes(field.name)}
                              onChange={(event) => {
                                const { value, checked } = event.target;
                                setDietHabbit(prevDietHabbitsStatus => {
                                  if (checked) {
                                    return [...prevDietHabbitsStatus, value];
                                  } else {
                                    const updatedStatus = prevDietHabbitsStatus.filter(status => status !== value);
                                    return updatedStatus;
                                  }
                                });
                              }}
                            />{' '}
                            {field.name}
                            <br />
                          </div>
                        ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Item className="itemShape2">
                <Accordion className={"filteraccordion"}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="acordion_items alignLeft">Marital Status</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {metaData.maritalStatuses &&
                        metaData.maritalStatuses.map((field) => (
                          <div key={field.name} className="datafieldsacc">
                            <Checkbox
                              value={field.name}
                              checked={maritalStatus.includes(field.name)}
                              onChange={(event) => {
                                const { value, checked } = event.target;
                                setMaritalStatus(prevMaritalStatus => {
                                  if (checked) {
                                    return [...prevMaritalStatus, value];
                                  } else {
                                    const updatedStatus = prevMaritalStatus.filter(status => status !== value);
                                    return updatedStatus;
                                  }
                                });
                              }}
                            />{' '}
                            {field.name}
                            <br />
                          </div>
                        ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Item className="itemShape2">
                <Accordion className="filteraccordion" >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="acordion_items">Cast consideration
                      <Tooltip title="Other people's consideration about your cast" open={open} onClick={handleToggle} arrow placement="top">
                        <IconButton onClick={handleToggle} style={{ padding: '0px 8px' }}>
                          <InfoIcon style={{ width: '20px', height: '20px' }} />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordata">
                    <Typography className="acordion_items radiobtns zeropadding">
                      <RadioGroup className="radioGroup" value={considerCast}
                        onChange={(event) => setConsiderCast(event.target.value)}>
                        <div className="radio"><FormControlLabel value="has" control={<Radio />} style={{ display: 'flex', alignItems: 'center' }} />
                          <Typography>Cast no bar</Typography>
                        </div>
                        <div className="radio"><FormControlLabel value="no" control={<Radio />} /><Typography>Individuals who are considering cast</Typography></div>
                        <div className="radio"><FormControlLabel value="both" control={<Radio />} /><Typography>Both</Typography></div>
                      </RadioGroup>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Item className="itemShape2">
                <Accordion className="filteraccordion" >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="acordion_items">Children</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordata">
                    <Typography className="acordion_items radiobtns zeropadding">
                      <RadioGroup className="" value={hasChildren}
                        onChange={(event) => setHasChildren(event.target.value)}>
                        <div className="radio"><FormControlLabel value="has" control={<Radio />} /><Typography>Has children</Typography></div>
                        <div className="radio"><FormControlLabel value="no" control={<Radio />} /><Typography>No children</Typography></div>
                        <div className="radio"><FormControlLabel value="both" control={<Radio />} /><Typography>Both</Typography></div>
                      </RadioGroup>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Item>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
              <div>
                <Button onClick={clickLetsGo} className="dmsans purplebuttonmain" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC' }}>Search</Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </div>
  );
}
