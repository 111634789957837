import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import Footer from "../FooterPages/Footer";

const Layout = () => {
  return (
    <>
      <div style={{ display: 'flex', height: '100%' }}>
        <SideBar />
        <div style={{ marginLeft: '0px', width: '100%', }}>
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  );

};

export default Layout;