import http from "../httpService";

export function uploadPhotos(id, body) {
  return http.post(`/profiles/${id}/photos`, body);
}

export function updateMainPhoto(id, body) {
  return http.put(`/profiles/${id}/main-profile-photo`, body);
}

export function getAllProfilePhotos(id) {
  return http.get(`/profiles/${id}/photos`);
}

export function getProfilePhoto(photoId) {
  return http.get(`/photos/${photoId}`);
}

export function deleteProfilePhoto(id, photoId) {
  return http.delete(`/profiles/${id}/photos/${photoId}`);
}

export function deleteMainProfilePhoto(id) {
  return http.delete(`/profiles/${id}/main-profile-photo`);
}

export function uploadHoroscope(id, body) {
  return http.post(`/profiles/${id}/horoscopePhotos`, body);
}

export function removeHoroscope(id, photoId) {
  return http.delete(`/profiles/${id}/horoscopePhotos/${photoId}`);
}

export function getAllHoroscopes(id) {
  return http.get(`/profiles/${id}/horoscopePhotos`);
}

export function getPendingImageRequests(profileId) {
  return http.get(`/photoRequest/${profileId}/pending-photo-requests`);
}

export function getSentImageRequests(profileId) {
  return http.get(`/photoRequest/${profileId}/sent-photo-requests`);
}

export function getAcceptedImageRequestsAsRequester(profileId) {
  return http.get(
    `/photoRequest/acceptedRequester/${profileId}`
  );
}

export function getAcceptedImageRequestsAsRequestee(profileId) {
  return http.get(
    `/photoRequest/acceptedRequestee/${profileId}`
  );
}

export function cancelImageRequest(id) {
  return http.delete(`/photoRequest/${id}/removeSentReq`);
}

export function approveImageRequest(id) {
  return http.put(`/photoRequest/${id}/acceptRequest`);
}

export function rejectImageRequest(id) {
  return http.put(`/photoRequest/${id}/rejectRequest`);
}

export function disconnectApprovedImageRequest(id) {
  return http.put(`photoRequest/${id}/disconnectRequest`);
}

export function imageRequest(body, id) {
  return http.post(`/photoRequest/${id}/requestPhotos`, body);
}