import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Spinner } from "../Common/Spinner";
import Typography from '@mui/material/Typography';
import { updateProfilePhoneNumber } from '../../Services/Profile/profileService.js';
import PhoneInput from "react-phone-input-2"
import { useMember } from "../../Contexts/memberContext.js";
import { useApp } from "../../Contexts/appContext.js";
import swal from 'sweetalert';
import {
  FormControl,
  InputLabel
} from '@material-ui/core';

export const PhoneNumberEditModal = ({ open, handleClose }) => {
  const { profile, setProfile } = useMember();
  const { metaData } = useApp();
  const [modalLoading, setModalLoading] = useState(false);
  const RequiredErrror = 'This field is required';
  const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/;
  const [userPhoneNumber, setUserPhoneNumber] = useState(profile?.contactDetails?.phoneNumber || "");
  const [PhoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("Hi");

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    handleReset();
  }, [profile, metaData, open]);

  const handleEdit = async () => {
    setPhoneNumberErrorMessage(
      userPhoneNumber === '' ? RequiredErrror : !phoneRegExp.test(userPhoneNumber) ? 'Invalid phone number' :
        userPhoneNumber.length < 7 ? 'Phone number must be at least 7 digits with country code' :
          userPhoneNumber.length > 15 ? 'Phone number must be at most 15 digits with country code' : ''
    );
    if (userPhoneNumber === '' || !phoneRegExp.test(userPhoneNumber) || userPhoneNumber.length < 7 || userPhoneNumber.length > 15) {
      return;
    }
    const phonenNumberEditDetails = {};
    phonenNumberEditDetails.phoneNumber = userPhoneNumber;
    setModalLoading(true);
    const response = await updateProfilePhoneNumber(phonenNumberEditDetails);
    if (response.status === 201) {
      setProfile(prevProfile => ({
        ...prevProfile,
        contactDetails: {
          ...prevProfile.contactDetails,
          phoneNumber: response.data.phoneNumber
        }
      }));
      handleClose();
      swal({
        text: "Save and updated the phone number.",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      })
    } else if (response.status !== 200) {
      swal({
        text: 'Unsuccessful. Try again.',
        icon: 'error',
        dangerMode: true,
      });
    }
    setModalLoading(false);
  };

  const handleReset = async () => {
    setModalLoading(true);
    setUserPhoneNumber(profile?.contactDetails?.phoneNumber || " ");
    setPhoneNumberErrorMessage('')
    setModalLoading(false);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalLoading ? <Spinner></Spinner> :
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '350px' },
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ /* styles */ }}>
                {/* Modal content */}
                <Typography className="dmsans" id="modal-modal-title" variant="h6" component="h2">
                  Update phone number.
                </Typography>
                <br />
                <FormControl fullWidth>
                  <FormControl fullWidth>
                    <InputLabel style={{ fontFamily: 'DM Sans' }}>Phone Number *</InputLabel>
                    <PhoneInput style={{ marginTop: '5px' }}
                      country='lk'
                      value={userPhoneNumber}
                      onChange={(phoneNumber) => {
                        setUserPhoneNumber(phoneNumber);
                        setPhoneNumberErrorMessage(
                          userPhoneNumber === '' ? RequiredErrror :
                            !phoneRegExp.test(userPhoneNumber) ? 'Invalid phone number' :
                              userPhoneNumber.length < 7 ? 'Phone number must be at least 7 digits with country code' :
                                userPhoneNumber.length > 15 ? 'Phone number must be at most 15 digits with country code' :
                                  ''
                        );
                      }}
                      inputExtraProps={{
                        name: "phoneNumber",
                        id: "phoneNumber",
                        required: true,
                      }}
                    />
                    <p style={{ color: 'red' }}>{PhoneNumberErrorMessage}</p>
                  </FormControl>
                </FormControl>
                <div className="editmodal">
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleClose}>Cancel</Button>
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleReset}>Reset</Button>
                  <Button className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC' }} onClick={handleEdit}>Save</Button>
                </div>
              </Box>
            </Box>
          </Box>
        }
      </Modal>
    </>
  );
};