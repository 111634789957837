import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/Dashboard.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CardMedia from '@mui/material/CardMedia';
import 'react-alice-carousel/lib/alice-carousel.css';
import ImageSlider from '../ImageSlider/ImageSlider.js';
import { useMember } from "../../Contexts/memberContext.js";
import { getPendingRequests, getSentRequests, getAcceptedRequestsAsRequester, getAcceptedRequestsAsRequestee } from "../../Services/Profile/requests.js";
import { getPendingImageRequests, getSentImageRequests, getAcceptedImageRequestsAsRequestee, getAcceptedImageRequestsAsRequester, cancelImageRequest, approveImageRequest, rejectImageRequest, disconnectApprovedImageRequest } from '../../Services/Profile/photoService.js';
import { cancelRequest, disconnectRelationship, approveRequest, rejectRequest, addTopVisitor, getRelationship } from "../../Services/Profile/expressionOfInterest.js";
import { getProfile } from "../../Services/Profile/profileService.js";
import { useApp } from "../../Contexts/appContext";
import user2 from "../../Assests/imgs/user2.png";
import swal from 'sweetalert';
import { Spinner } from "../Common/Spinner.js";
import { getOppositeGender } from '../../Utils/getOppositeGender.js';
import { getSearchedProfiles } from "../../Services/Profile/profileService.js";
import { searchQueryString } from "../../Utils/searchQueryString.js";
import { getAge } from "../../Utils/getAge.js";
import profilehidden from '../../Assests/imgs/profilehidden.jpg';
import { useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getProfilePhoto } from '../../Services/Profile/photoService.js';
import logo from '../../Assests/imgs/vivakamLogo.png';

const theme = createTheme({

  breakpoints: {
    values: {
      xs: 200, // Extra small devices (phones) - 0 to 599 pixels
      sm: 500,    // Small devices (tablets) - 600 to 959 pixels
      md: 1249,    // Medium devices (desktops) - 960 to 1279 pixels
      lg: 1250,   // Large devices (large desktops) - 1280 to 1919 pixels
      xl: 1920,   // Extra large devices (extra-large desktops) - 1920 pixels and above
    },
  },
});

const Dashboard = () => {

  const [selectedValue, setSelectedValue] = React.useState('a');
  const { profile, setProfile, allConnectedProfiles, setAllConnectedProfiles, allImgReqConnectedProfiles, setAllImgReqConnectedProfiles, sentImgRequestsProfiles, setSentImgRequiestsProfiles, pendingImgRequestsProfiles, setPendingImgRequiestsProfiles } = useMember();
  const [suggestionProfiles, setSuggestionProfiles] = useState([]);
  const [pendingRequestsProfiles, setPendingRequiestsProfiles] = useState([]);
  const [sentRequestsProfiles, setSentRequiestsProfiles] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loadingIcon, setSetLoadingIcon] = useState(true);
  const [searchedCountry, setSearchedCountry] = useState([]);
  const [searchedReligion, setSearchedReligion] = useState([]);
  const [searchedAgeTo, setSearchedAgeTo] = useState('');
  const [searchedAgeFrom, setSearchedAgeFrom] = useState('');
  const [searchedHeightTo, setSearchedHeightTo] = useState('');
  const [searchedHeightFrom, setSearchedHeightFrom] = useState('');
  const [interestStatus, setInterestStatus] = useState("NONE");
  const { metaData, userType } = useApp();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [value, setValue] = React.useState('1');
  const handleChange3 = (event, newValue) => {
    setValue(newValue);
  };

  const [imgValue, setImgValue] = React.useState('4');
  const imgHandleChange3 = (event, newImgValue) => {
    setImgValue(newImgValue);
  };

  const pendingRequests = async () => {
    try {
      const pendingRequestsResponse = await getPendingRequests(profile.id);
      if (pendingRequestsResponse.status === 201) {
        setPendingRequiestsProfiles(pendingRequestsResponse.data);
      }
    } catch (error) {
      console.log("Error in get pending requests");
    }
  };
  const sentRequests = async () => {
    try {
      const sentRequestsResponse = await getSentRequests(profile.id);
      if (sentRequestsResponse.status === 201) {
        setSentRequiestsProfiles(sentRequestsResponse.data);
      }
    } catch (error) {
      console.log("Error in get sent requests");
    }
  };

  const pendingImageRequests = async () => {
    try {
      const pendingImageRequestsResponse = await getPendingImageRequests(profile.id);
      if (pendingImageRequestsResponse.status === 200) {
        setPendingImgRequiestsProfiles(pendingImageRequestsResponse.data);
      }
    } catch (error) {
      console.log("Error in get pending image requests");
    }
  };
  const sentImageRequests = async () => {
    try {
      const sentImageRequestsResponse = await getSentImageRequests(profile.id);
      if (sentImageRequestsResponse.status === 200) {
        setSentImgRequiestsProfiles(sentImageRequestsResponse.data);
      }
    } catch (error) {
      console.log("Error in get sent image requests");
    }
  };

  const getAcceptedPhotoRequests = async () => {
    try {
      let acceptedPhotoRequests = [];
      const [requesteeImgConnectedProfiles, requesterImgConnectedProfiles] = await Promise.all([
        getAcceptedImageRequestsAsRequestee(profile.id),
        getAcceptedImageRequestsAsRequester(profile.id)
      ]);
      if (requesteeImgConnectedProfiles.status === 200) {
        if (requesteeImgConnectedProfiles.data.length > 0) {
          const updatedProfilesReq = [];
          for (const profile of requesteeImgConnectedProfiles.data) {
            let photoData = null;
            if (profile.photoRequesterProfile.mainProfilePhotoId) {
              try {
                const photoResponse = await getProfilePhoto(profile.photoRequesterProfile.mainProfilePhotoId);
                photoData = photoResponse.data;
              } catch (error) {
                console.error("Error fetching profile photo for profile ID", profile.photoRequesterProfile.id, ":", error);
              }
            }
            profile.photoData = photoData;
            const updatedProfile = {
              ...profile,
              photoData: photoData
            };
            updatedProfilesReq.push(updatedProfile);
          }
          acceptedPhotoRequests.push(...updatedProfilesReq);
        }
      } else {
        console.log("Error in get requestee img connected profiles");
      }
      if (requesterImgConnectedProfiles.status === 200) {
        if (requesterImgConnectedProfiles.data.length > 0) {
          const updatedProfiles = [];
          for (const profile of requesterImgConnectedProfiles.data) {
            let photoData = null;
            if (profile.photoRequesteeProfile.mainProfilePhotoId) {
              try {
                const photoResponse = await getProfilePhoto(profile.photoRequesteeProfile.mainProfilePhotoId);
                photoData = photoResponse.data;
              } catch (error) {
                console.error("Error fetching profile photo for profile ID", profile.photoRequesteeProfile.id, ":", error);
              }
            }
            profile.photoData = photoData;
            const updatedProfile = {
              ...profile,
              photoData: photoData
            };
            updatedProfiles.push(updatedProfile);
          }
          acceptedPhotoRequests.push(...updatedProfiles);
        }
      } else {
        console.log("Error in get requester img connected profiles");
      }
      setAllImgReqConnectedProfiles(acceptedPhotoRequests);
    } catch (error) {
      console.log("Error in get img connected profiles", error);
    }
  };

  const cancelSentImageRequest = async (id) => {
    setSetLoadingIcon(true);
    const cancelImgRequestResponse = await cancelImageRequest(id);
    if (cancelImgRequestResponse.status === 204) {
      const indexToRemove = sentImgRequestsProfiles.findIndex(profile => profile.id === id);
      if (indexToRemove !== -1) {
        const updatedSentRequests = [...sentImgRequestsProfiles];
        updatedSentRequests.splice(indexToRemove, 1);
        setSentImgRequiestsProfiles(updatedSentRequests);
        swal({
          text: "Success", icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        console.log("Record with id not found in sentImgRequestsProfiles array");
        swal({
          text: 'Unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    } else {
      console.log("Error while cancel");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
    setSetLoadingIcon(false);
  };

  const approveReceivedImageequest = async (id) => {
    setSetLoadingIcon(true);
    const approveRequestResponse = await approveImageRequest(id);
    if (approveRequestResponse.status === 200) {
      setDataLoading(true);
      swal({
        text: "Success", icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
    } else {
      console.log("Error while accept");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const rejectReceivedImageRequest = async (id) => {
    const rejectRequestResponse = await rejectImageRequest(id);
    if (rejectRequestResponse.status === 200) {
      setDataLoading(true);
      swal({
        text: "Success", icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
    } else {
      console.log("Error while reject");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const disconnectApprovedImgRequest = async (id) => {
    setSetLoadingIcon(true);
    const disconnectResponse = await disconnectApprovedImageRequest(id);
    if (disconnectResponse.status === 200) {
      // Find the index of the record with the specified id
      const indexToRemove = allImgReqConnectedProfiles.findIndex(profile => profile.id === id);
      if (indexToRemove !== -1) {
        // If found, remove the record from the array
        const updatedallConnectedProfiles = [...allImgReqConnectedProfiles];
        updatedallConnectedProfiles.splice(indexToRemove, 1);
        // Update the state with the modified array
        setAllImgReqConnectedProfiles(updatedallConnectedProfiles);
        swal({
          text: "Success", icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        console.log("Record with id not found in sentRequestsProfiles array");
        swal({
          text: 'Unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    } else {
      console.log("Error while rejecting invite");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
    setSetLoadingIcon(false);
  };

  const getConnectedProfiles = async () => {
    try {
      let connectedProfiles = [];
      const [requesteeConnectedProfiles, requesterConnectedProfiles] = await Promise.all([
        getAcceptedRequestsAsRequestee(profile.id),
        getAcceptedRequestsAsRequester(profile.id)
      ]);
      if (requesteeConnectedProfiles.status === 200) {
        if (requesteeConnectedProfiles.data.length > 0) {
          const updatedProfilesReq = [];
          for (const profile of requesteeConnectedProfiles.data) {
            let photoData = null;
            if (profile.requesterProfile.mainProfilePhotoId) {
              try {
                const photoResponse = await getProfilePhoto(profile.requesterProfile.mainProfilePhotoId);
                photoData = photoResponse.data;
              } catch (error) {
                console.error("Error fetching profile photo for profile ID", profile.requesterProfile.id, ":", error);
              }
            }
            profile.photoData = photoData;
            const updatedProfile = {
              ...profile,
              photoData: photoData
            };
            updatedProfilesReq.push(updatedProfile);
          }
          connectedProfiles.push(...updatedProfilesReq);
        }
      } else {
        console.log("Error in get requestee connected profiles");
      }
      if (requesterConnectedProfiles.status === 200) {
        if (requesterConnectedProfiles.data.length > 0) {
          const updatedProfiles = [];
          for (const profile of requesterConnectedProfiles.data) {
            let photoData = null;
            if (profile.requesteeProfile.mainProfilePhotoId) {
              try {
                const photoResponse = await getProfilePhoto(profile.requesteeProfile.mainProfilePhotoId);
                photoData = photoResponse.data;
              } catch (error) {
                console.error("Error fetching profile photo for profile ID", profile.requesteeProfile.id, ":", error);
              }
            }
            profile.photoData = photoData;
            const updatedProfile = {
              ...profile,
              photoData: photoData
            };
            updatedProfiles.push(updatedProfile);
          }
          connectedProfiles.push(...updatedProfiles);
        }
      } else {
        console.log("Error in get requester connected profiles");
      }
      setAllConnectedProfiles(connectedProfiles);
    } catch (error) {
      console.log("Error in get connected profiles", error);
    }
  };

  const disconnectExpressionOfEnterest = async (id) => {
    setSetLoadingIcon(true);
    const disconnectResponse = await disconnectRelationship(id);
    if (disconnectResponse.status === 200) {
      // Find the index of the record with the specified id
      const indexToRemove = allConnectedProfiles.findIndex(profile => profile.id === id);
      if (indexToRemove !== -1) {
        // If found, remove the record from the array
        const updatedallConnectedProfiles = [...allConnectedProfiles];
        updatedallConnectedProfiles.splice(indexToRemove, 1);
        // Update the state with the modified array
        setAllConnectedProfiles(updatedallConnectedProfiles);
        swal({
          text: "Success", icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        console.log("Record with id not found in sentRequestsProfiles array");
        swal({
          text: 'Unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    } else {
      console.log("Error while rejecting invite");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
    setSetLoadingIcon(false);
  };

  const cancelExpressionOfEnterest = async (id) => {
    setSetLoadingIcon(true);
    const cancelRequestResponse = await cancelRequest(id);
    if (cancelRequestResponse.status === 204) {
      const indexToRemove = sentRequestsProfiles.findIndex(profile => profile.id === id);
      if (indexToRemove !== -1) {
        const updatedSentRequests = [...sentRequestsProfiles];
        updatedSentRequests.splice(indexToRemove, 1);
        setSentRequiestsProfiles(updatedSentRequests);
        swal({
          text: "Success", icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        console.log("Record with id not found in sentRequestsProfiles array");
        swal({
          text: 'Unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    } else {
      console.log("Error while cancel");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
    setSetLoadingIcon(false);
  };

  const approveExpressionOfEnterest = async (id) => {
    setSetLoadingIcon(true);
    const approveRequestResponse = await approveRequest(id);
    if (approveRequestResponse.status === 200) {
      setDataLoading(true);
      swal({
        text: "Success", icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
    } else {
      console.log("Error while accept");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const rejectExpressionOfEnterest = async (id) => {
    const rejectRequestResponse = await rejectRequest(id);
    if (rejectRequestResponse.status === 200) {
      setDataLoading(true);
      swal({
        text: "Success", icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
    } else {
      console.log("Error while reject");
      swal({
        text: 'Unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const clickLetsGo = async () => {
    const data = {
      // hasChildren: false,
      // castNoBar: false,
      ageLowerBound: [searchedAgeFrom],
      ageUpperBound: [searchedAgeTo],
      heightLowerBound: [searchedHeightFrom],
      heightUpperBound: [searchedHeightTo],
      gender: getOppositeGender(profile.gender.name),
      religion: [searchedReligion],
      country: [searchedCountry],
    };
    navigate('/resultpage', { state: { data } });
  };

  const navigateToProfile = async (resultProfileId) => {
    setSetLoadingIcon(true);
    const relationshipResponse = await getRelationship(resultProfileId, profile.externalId);
    if (relationshipResponse.status === 200) {
      if (relationshipResponse.data.status === "Approved") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("APPROVEDBYME");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("APPROVED");
        }
      } else if (relationshipResponse.data.status === "Await") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("PENDING");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("SENT");
        }
      } else if (relationshipResponse.data.status === "Rejected") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("REJECTEDBYME");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("REJECTED");
        }
      } else if (!relationshipResponse.data.status) {
        setInterestStatus("NONE");
      }
      if (interestStatus !== "APPROVEDBYME") {
        let obj = {};
        obj.requesterProfileId = profile.id;
        obj.requesteeProfileId = resultProfileId;
        const AddTopVisitor = await addTopVisitor(obj);
        if (AddTopVisitor.status === 500) {
          console.log("Error while saving");
        }
      }
    } else {
      console.log("Error in calling getRelationship");
    }
    navigate('/otherprofiles', { state: { resultProfileId } });
  };

  const handleFromChangeHeight = (e) => {
    const selectedFromValue = parseFloat(e.target.value);
    setSearchedHeightFrom(selectedFromValue);
  };

  const handleToChangeHeight = (e) => {
    const selectedToValue = parseFloat(e.target.value);
    setSearchedHeightTo(selectedToValue);
  };

  const handleFromChangeAge = (e) => {
    const selectedFromValue = parseInt(e.target.value);
    setSearchedAgeFrom(selectedFromValue);
  };

  const handleToChangeAge = (e) => {
    const selectedToValue = parseInt(e.target.value);
    setSearchedAgeTo(selectedToValue);
  };


  const getAgeLowerBoundOptions = () => {
    if (!searchedAgeTo) return metaData.ages;

    const options = metaData.ages.filter(
      (age) =>
        parseInt(age.name) <= parseInt(searchedAgeTo)
    );
    return options;
  };

  const getAgeUpperBoundOptions = () => {
    if (!searchedAgeFrom) return metaData.ages;

    const options = metaData.ages.filter(
      (age) =>
        parseInt(age.name) >= parseInt(searchedAgeFrom)
    );
    return options;
  };

  const getHeightLowerBoundOptions = () => {
    if (!searchedHeightTo) return metaData.heights;

    const options = metaData.heights.filter(
      (h) =>
        h.centimetreValue <= parseFloat(searchedHeightTo)
    );
    return options;
  };

  const getHeightUpperBoundOptions = () => {
    if (!searchedHeightFrom) return metaData.heights;

    const options = metaData.heights.filter(
      (h) =>
        h.centimetreValue >= parseFloat(searchedHeightFrom)
    );
    return options;
  };

  const fetchSuggestedProfiles = async () => {
    const age = getAge(profile.dob);
    let ageLowerBound, ageUpperBound;

    if (profile.gender.name === 'Female') {
      ageLowerBound = age;
      ageUpperBound = Math.min(100, age + 5);
    } else if (profile.gender.name === 'Male') {
      ageLowerBound = Math.max(18, age - 5);
      ageUpperBound = age;
    }
    const suggestCriteria = {
      hasChildren: false,
      ageLowerBound: [ageLowerBound],
      ageUpperBound: [ageUpperBound],
      gender: getOppositeGender(profile.gender.name),
    };
    let queryString = searchQueryString(suggestCriteria);
    const response = await getSearchedProfiles(profile.id, queryString);
    if (response.status === 200) {
      setSuggestionProfiles(response.data);
    } else if (response.status === 404) {
      setSuggestionProfiles([]);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSetLoadingIcon(true);
    const fetchData = async () => {
      if (!profile) {
        try {
          const { status: profileStatus, data: profile } = await getProfile("me");
          if (profileStatus === 200) setProfile(profile);
        } catch (error) {
          console.log("Error in getProfile ", error);
        }
      } else {
        await pendingRequests();
        await sentRequests();
        await pendingImageRequests();
        await sentImageRequests();
        await getConnectedProfiles();
        await getAcceptedPhotoRequests();
        await fetchSuggestedProfiles();
        setSetLoadingIcon(false);
      }
    };
    fetchData();
  }, [profile, dataLoading]);

  const isScreenLargerThan600 = useMediaQuery('(min-width:600px)');

  return (
    <div className="wholedashboard" >
      {loadingIcon ? (<Spinner></Spinner>) : (
        <div>
          <div className="dashboard topspacedash btmspacedash-2" style={{ backgroundColor: '#F7F7FF', fontFamily: 'DM Sans' }}>
            <div>
              {userType === 'INACTIVE_PROFILE_USER' &&
                <>
                  <div className="hidden_profile">
                    <h2 class="strokeme">
                      {profile.isSoftDeleted && (
                        <span style={{ textAlign: 'center' }}>
                          <h3>Your profile has been deleted. </h3>
                          <h5>You can restore it within 180 days or it will be permanently removed.</h5>
                        </span>
                      )}
                      {profile.isHided && !profile.isSoftDeleted && (
                        <span style={{ textAlign: 'center' }}>
                          <h3>Your profile is currently hidden. </h3>
                          <h5>  You have the option to unhide it at any time. </h5>
                        </span>
                      )}
                      {profile.status === "Await" &&
                        !profile.isSoftDeleted &&
                        !profile.isHided && (
                          <span style={{ textAlign: 'center' }}>
                            <h3>Profile status - Under review.</h3>
                            <h5>Please wait patiently until it receives
                              approval from the administrator</h5>
                          </span>
                        )}
                      {profile.status === "Holded" &&
                        !profile.isSoftDeleted &&
                        !profile.isHided && "Profile status - hold"}
                    </h2>

                    <Card className="card1 card7" style={{ margin: '20px' }}>
                      <div style={{ display: 'flex', alignItems: '', flexDirection: 'row', justifyContent: 'center', padding: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>

                          <Grid item lg={6} md={6} sm={12} xs={12} >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {/* <h3 onClick={() => navigate('/chart')}>70%</h3> */}
                              {/* <Chart /> */}
                              <img src={logo} alt="logo" style={{ borderRadius: '5px', margin: '2px', width: '100%', height: '75px', objectFit: 'cover' }} />
                            </div>
                          </Grid>
                          <Grid item lg={3} md={3} sm={12} xs={12} >
                            <p4 className="pointer" onClick={() => navigate('/myprofile')}>
                              <u>Go to profile</u>
                            </p4>
                          </Grid>
                        </div>
                      </div>
                    </Card>

                    <Card style={{ height: "250px", width: "250px", display: 'flex', justifyContent: 'center' }}>
                      <img src={profilehidden} alt="profilehidden img" style={{ height: "250px", width: "220px" }} />
                    </Card>

                  </div>
                </>
              }
              <Box sx={{ flexGrow: 1 }}>
                {userType === 'ACTIVE_PROFILE_USER' &&
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h5 className="dmsans">
                        I'm looking for,
                      </h5>
                      {/* <div className="profileimg">
                      <img src="https://bespokeunit.com/wp-content/uploads/2017/12/Oval-Face-Wearing-Wayfarer-Glasses-Frames.png" 
                      alt="Mountains" width="50" height="50" style={{ borderRadius: '100px', border: '2px solid gray' }} 
                      onClick={() => navigate('/myprofile')}/>
                    </div> */}
                    </div>
                  </>
                }
                <Grid container spacing={2}>
                  {userType === 'ACTIVE_PROFILE_USER' &&
                    <>
                      <Grid item lg={10} md={10} sm={12} xs={12} className="part1">
                        <div className="filter-content">
                          <Card sx={{ minWidth: 275 }} className="card card1" >
                            <CardContent className="filter">
                              <ThemeProvider theme={theme}>
                                <Grid container spacing={2}>
                                  {/* <div className="firstLine"> */}
                                  <Grid item xs={8} sm={6} md={6} lg={3}>
                                    <div>
                                      <Typography className="dmsans whitefont" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Age
                                      </Typography>
                                      <div>
                                        <FormControl className="age whitefont" style={{ display: 'flex', flexDirection: 'row' }}>
                                          From
                                          <div>&nbsp;</div>
                                          <Select
                                            value={searchedAgeFrom}
                                            onChange={handleFromChangeAge}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Select Value' }}
                                            style={{ borderRadius: '20px', width: '80px', height: '40px', backgroundColor: '#F4F7FD' }}
                                          >
                                            {getAgeLowerBoundOptions().map((age, index) => (
                                              <MenuItem key={index} value={parseInt(age.name)}>{age.name}</MenuItem>
                                            ))}
                                          </Select>

                                          {/* <Autocomplete
                                          value={getAgeLowerBoundOptions().find(option => option.name === searchedAgeFrom) || null}
                                          onChange={(event, newValue) => { handleFromChangeAge(newValue); }}
                                          options={getAgeLowerBoundOptions()}
                                          getOptionLabel={(age) => age.name}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              sx={{
                                                borderRadius: '20px',
                                                width: '80px',
                                                height: '40px',
                                                backgroundColor: '#F4F7FD',
                                                '& .MuiOutlinedInput-root': {
                                                  borderRadius: '20px',
                                                  height: '40px',
                                                  padding: '0px',
                                                  '&:hover fieldset': {
                                                    borderColor: '#F4F7FD',
                                                  },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                  fontSize: '10px', // Adjust font size
                                                  color: '#555', // Adjust placeholder color
                                                  textAlign: 'center', // Align text to the middle
                                                },
                                              }}
                                              InputProps={{
                                                ...params.InputProps,
                                                placeholder: searchedAgeFrom ? '' : 'Select...', // Display empty area when no value selected
                                              }}
                                            />
                                          )}
                                          PaperComponent={({ children }) => (
                                            <Paper
                                              elevation={8}
                                              sx={{
                                                width: 'auto !important',
                                                minWidth: '40px',
                                              }}
                                            >
                                              {children}
                                            </Paper>
                                          )}
                                          MenuProps={{
                                            sx: {
                                              width: 'auto !important',
                                            },
                                          }}
                                        /> */}

                                          <div>&nbsp;</div>
                                          To
                                          <div>&nbsp;</div>
                                          <Select
                                            className="dropdown"
                                            value={searchedAgeTo}
                                            onChange={handleToChangeAge}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Select Value' }}
                                            style={{ borderRadius: '20px', width: '80px', height: '40px', backgroundColor: '#F4F7FD' }}
                                          >
                                            {getAgeUpperBoundOptions().map((age, index) => (
                                              <MenuItem key={index} value={parseInt(age.name)}>{age.name}</MenuItem>
                                            ))}
                                          </Select>
                                          {/* <Autocomplete
                                          value={getAgeUpperBoundOptions().find(option => option.name === searchedAgeTo) || null}
                                          onChange={(event, newValue) => { handleToChangeAge(newValue); }}
                                          options={getAgeUpperBoundOptions()}
                                          getOptionLabel={(age) => age.name}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              sx={{
                                                borderRadius: '20px',
                                                width: '80px',
                                                height: '40px',
                                                backgroundColor: '#F4F7FD',
                                                '& .MuiOutlinedInput-root': {
                                                  borderRadius: '20px',
                                                  height: '40px',
                                                  padding: '0px',
                                                  '&:hover fieldset': {
                                                    borderColor: '#F4F7FD',
                                                  },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                  fontSize: '10px', // Adjust font size
                                                  color: '#555', // Adjust placeholder color
                                                  textAlign: 'center', // Align text to the middle
                                                },
                                              }}
                                              InputProps={{
                                                ...params.InputProps,
                                                placeholder: searchedAgeTo ? '' : 'Select...', // Display empty area when no value selected
                                              }}
                                            />
                                          )}
                                          PaperComponent={({ children }) => (
                                            <Paper
                                              elevation={8}
                                              sx={{
                                                width: 'auto !important',
                                                minWidth: '40px',
                                              }}
                                            >
                                              {children}
                                            </Paper>
                                          )}
                                          MenuProps={{
                                            sx: {
                                              width: 'auto !important',
                                            },
                                          }}
                                        /> */}

                                        </FormControl>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4} sm={4} md={6} lg={2}>
                                    <div>
                                      <div className="">
                                        <div>
                                          <Typography className="dmsans whitefont" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Religion
                                          </Typography>
                                          <div>
                                            <FormControl className="religion" style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
                                              <Select
                                                multiple
                                                value={searchedReligion}
                                                onChange={(e) => { setSearchedReligion(e.target.value); }}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Select Value' }}
                                                style={{ borderRadius: '20px', width: '100px', height: '40px', backgroundColor: '#F4F7FD' }}
                                              >
                                                {metaData.religions.map((religion, index) => (
                                                  <MenuItem key={index} value={religion.name}>{religion.name}</MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={8} sm={6} md={6} lg={3}>
                                    <div>
                                      <Typography className="dmsans whitefont" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Height
                                      </Typography>
                                      <div>
                                        <FormControl className="age whitefont" style={{ display: 'flex', flexDirection: 'row' }}>
                                          From
                                          <div>&nbsp;</div>
                                          <Select
                                            className="dropdown"
                                            value={searchedHeightFrom}
                                            onChange={handleFromChangeHeight}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Select Value' }}
                                            style={{ borderRadius: '20px', width: '80px', height: '40px', backgroundColor: '#F4F7FD' }}
                                          >
                                            {getHeightLowerBoundOptions().map((height, index) => (
                                              <MenuItem key={index} value={parseFloat(height.centimetreValue)}>{height.name}</MenuItem>
                                            ))}
                                          </Select>
                                          {/* <Autocomplete
                                          value={metaData.heights.find(option => option.id === searchedHeightFrom) || null}
                                          onChange={(event, newValue) => { handleFromChangeHeight(newValue); }}
                                          options={getHeightLowerBoundOptions()}
                                          getOptionLabel={(height) => height.name}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              sx={{
                                                borderRadius: '20px',
                                                width: '80px',
                                                height: '40px',
                                                backgroundColor: '#F4F7FD',
                                                '& .MuiOutlinedInput-root': {
                                                  borderRadius: '20px',
                                                  height: '40px',
                                                  padding: '0px',
                                                  '&:hover fieldset': {
                                                    borderColor: '#F4F7FD',
                                                  },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                  fontSize: '10px', // Adjust font size
                                                  color: '#555', // Adjust placeholder color
                                                  textAlign: 'center', // Align text to the middle
                                                },
                                              }}
                                              InputProps={{
                                                ...params.InputProps,
                                                placeholder: searchedHeightFrom ? '' : 'Select...', // Display empty area when no value selected
                                              }}
                                            />
                                          )}
                                          PaperComponent={({ children }) => (
                                            <Paper
                                              elevation={8}
                                              sx={{
                                                width: 'auto !important',
                                                minWidth: '150px',
                                              }}
                                            >
                                              {children}
                                            </Paper>
                                          )}
                                          MenuProps={{
                                            sx: {
                                              width: 'auto !important',
                                            },
                                          }}
                                        /> */}

                                          <div>&nbsp;</div>
                                          To
                                          <div>&nbsp;</div>
                                          <Select
                                            value={searchedHeightTo}
                                            onChange={handleToChangeHeight}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Select Value' }}
                                            style={{ borderRadius: '20px', width: '80px', height: '40px', backgroundColor: '#F4F7FD' }}
                                          >
                                            {getHeightUpperBoundOptions().map((height, index) => (
                                              <MenuItem key={index} value={parseFloat(height.centimetreValue)}>{height.name}</MenuItem>
                                            ))}
                                          </Select>
                                          {/* <Autocomplete
                                          value={metaData.heights.find(option => option.id === searchedHeightTo) || null}
                                          onChange={(event, newValue) => { handleToChangeHeight(newValue); }}
                                          options={getHeightUpperBoundOptions()}
                                          getOptionLabel={(height) => height.name}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              sx={{
                                                borderRadius: '20px',
                                                width: '80px',
                                                height: '40px',
                                                backgroundColor: '#F4F7FD',
                                                '& .MuiOutlinedInput-root': {
                                                  borderRadius: '20px',
                                                  height: '40px',
                                                  padding: '0px',
                                                  '&:hover fieldset': {
                                                    borderColor: '#F4F7FD',
                                                  },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                  fontSize: '10px', // Adjust font size
                                                  color: '#555', // Adjust placeholder color
                                                  textAlign: 'center', // Align text to the middle
                                                },
                                              }}
                                              InputProps={{
                                                ...params.InputProps,
                                                placeholder: searchedHeightTo ? '' : 'Select...', // Display empty area when no value selected
                                              }}
                                            />
                                          )}
                                          PaperComponent={({ children }) => (
                                            <Paper
                                              elevation={8}
                                              sx={{
                                                width: 'auto !important',
                                                minWidth: '150px',
                                              }}
                                            >
                                              {children}
                                            </Paper>
                                          )}
                                          MenuProps={{
                                            sx: {
                                              width: 'auto !important',
                                            },
                                          }}
                                        /> */}
                                        </FormControl>
                                      </div>
                                    </div>
                                  </Grid>
                                  {/* </div> */}

                                  <Grid item xs={4} sm={3} md={3} lg={2}>
                                    <div>
                                      <Typography className="filterTopic whitefont" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Living in
                                      </Typography>
                                      <div>
                                        <FormControl>
                                          <Select
                                            multiple
                                            value={searchedCountry}
                                            onChange={(e) => { setSearchedCountry(e.target.value); }} // Ensure e.target.value is always an array
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Select Value' }}
                                            style={{ borderRadius: '20px', width: '100px', height: '40px', backgroundColor: '#F4F7FD' }}
                                          >
                                            {metaData.countries.map((country, index) => (
                                              <MenuItem key={index} value={country.name}>{country.name}</MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>

                                        {/* <Autocomplete
                                        multiple
                                        value={searchedCountry}
                                        onChange={(event, newValue) => { setSearchedCountry(newValue); }}
                                        options={metaData.countries.map((country) => country.name)}
                                        getOptionLabel={(option) => option}
                                        renderTags={(value, getTagProps) => (
                                          value.length > 0 ? null : <span {...getTagProps()}></span>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            sx={{
                                              borderRadius: '50px',
                                              width: '100px',
                                              height: '40px',
                                              backgroundColor: '#F4F7FD',
                                              '& .MuiOutlinedInput-root': {
                                                borderRadius: '50px',
                                                height: '40px',
                                                padding: '0px',
                                                '&:hover fieldset': {
                                                  borderColor: '#F4F7FD',
                                                },
                                              },
                                              '& .MuiOutlinedInput-input': {
                                                fontSize: '10px', // Adjust font size
                                                color: '#555', // Adjust placeholder color
                                                textAlign: 'center', // Align text to the middle
                                              },
                                            }}
                                            placeholder={searchedCountry.length > 0 ? `${searchedCountry[0].slice(0, 12)}...` : ''}
                                            value={''} // Clear the default input value
                                          />
                                        )}
                                        PaperComponent={({ children }) => (
                                          <Paper
                                            elevation={8}
                                            sx={{
                                              width: 'auto !important',
                                              minWidth: '200px',
                                            }}
                                          >
                                            {children}
                                          </Paper>
                                        )}
                                      /> */}

                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={3} md={3} lg={2} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                    <div className="">
                                      <Button onClick={clickLetsGo} className="dmsans purplebuttonmain" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC' }}>Lets Go</Button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </ThemeProvider>
                            </CardContent>
                          </Card>
                        </div>
                      </Grid>

                      <Grid item lg={2} md={2} sm={4} xs={4} sx={{ display: { md: "block", lg: "block", sm: "none", xs: "none" } }}>
                        <Card className="card card1 card5">
                          <div style={{ display: 'flex', alignItems: '', flexDirection: 'row', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className="chart" style={{ display: 'flex', alignItems: 'center' }}>
                                  {/* <h3 onClick={() => navigate('/chart')}>70%</h3> */}
                                  {/* <Chart /> */}
                                  <img src={logo} className="vivakamlogo" alt="logo" />
                                </div>
                              </Grid>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                    </>
                  }

                  {userType === 'ACTIVE_PROFILE_USER' &&
                    <>
                      {/* {allConnectedProfiles && allConnectedProfiles.length > 0 && ( */}
                      <Grid item lg={4} md={4} sm={6} xs={6} sx={{ display: { md: "block", lg: "block", sm: "none", xs: "none" } }} className="part2">
                        <div className="card card2" >
                          <h6>Accepted connection requests</h6>
                          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%' }}>
                            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                              {allConnectedProfiles?.length > 0 ? (
                                allConnectedProfiles.map(profile => (
                                  <Card key={profile.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none' }}>
                                    <Grid item lg={3} md={3} sm={3} xs={3}>
                                      <CardMedia className="cardimg"
                                        component="img"
                                        onClick={() => navigateToProfile(profile.requesterProfile?.id || profile.requesteeProfile?.id)}
                                        alt="Sample Image"
                                        style={{
                                          height: '50px', width: '50px'
                                          // , filter: (profile.photoData?.isApproved) ? 'none' : (!profile.photoData?.isApproved && profile.photoData !== null) && 'blur(4px)' 
                                        }}
                                        src={profile.photoData === null ? user2 : (profile.photoData?.fileContents && (profile.photoData?.isApproved) ? `data:image;base64,${profile.photoData.fileContents}` : user2)}
                                      />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                      <CardContent>
                                        <Typography className="profilename" onClick={() => navigateToProfile(profile.requesterProfile?.id || profile.requesteeProfile?.id)} variant="p" component="div">
                                          {profile.requesterProfile?.firstName ? profile.requesterProfile.firstName : profile.requesteeProfile?.firstName ? profile.requesteeProfile.firstName : profile.requesterProfile?.profileName ? profile.requesterProfile.profileName : profile.requesteeProfile?.profileName ? profile.requesteeProfile.profileName : ''}
                                        </Typography>
                                      </CardContent>
                                    </Grid>
                                    <Grid className="alignRight" item lg={3} md={3} sm={3} xs={3}>
                                      <Button onClick={() => disconnectExpressionOfEnterest(profile.id)} className="dmsans purplebutton cancelbtn" variant="outlined" style={{ borderRadius: '30px', color: '#EC5B5B', border: '2px solid #EC5B5B', padding: '5px', height: '25px', fontSize: '12px' }}>Cancel</Button>
                                    </Grid>
                                  </Card>
                                ))
                              ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <img src='https://img.freepik.com/free-vector/add-new-user_78370-4710.jpg?t=st=1712054303~exp=1712057903~hmac=11137b3cacfdf47404a1879733c3cff680f1ba84ef497a6f14f5e16a7eee37bc&w=740' alt="noconnectionsimg" style={{ width: '100px' }} />
                                  <Typography variant="p" component="div">
                                    No accepted requests found.
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      {/* )} */}




                      <Grid item lg={4} md={4} sm={6} xs={6} sx={{ display: { md: "block", lg: "block", sm: "none", xs: "none" } }}>
                        <Card variant="outlined" className="card card2">
                          <h6 >Connection requests</h6>
                          <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                  onChange={handleChange3}
                                  aria-label="lab API tabs example"
                                  sx={{
                                    color: '#9E80BC !important',
                                    "& .Mui-selected": { color: '#9E80BC !important' },
                                    "& .MuiTabs-indicator": { backgroundColor: '#9E80BC' }
                                  }}
                                >
                                  <Tab label="Received" value="1" style={{ fontFamily: 'DM Sans', margin: '2px' }} />
                                  <Tab label="Sent" value="2" style={{ fontFamily: 'DM Sans', margin: '2px' }} />
                                </TabList>
                              </Box>
                              <TabPanel value="1" className="tabpanel">
                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                  {pendingRequestsProfiles && pendingRequestsProfiles.length > 0 ? (
                                    pendingRequestsProfiles.map(profile => (
                                      <Card key={profile.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none', marginBottom: '10px' }}>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                          <CardMedia
                                            component="img"
                                            onClick={() => navigateToProfile(profile.requesterProfile.id)}
                                            alt={"Profile Image"}
                                            style={{ height: '50px', width: '50px' }}
                                            src={user2}
                                          />
                                        </Grid>
                                        <Grid item lg={5} md={5} sm={5} xs={5}>
                                          <CardContent>
                                            <Typography className="profilename" onClick={() => navigateToProfile(profile.requesterProfile.id)} variant="p" component="div">
                                              {profile.requesterProfile?.firstName || profile.requesterProfile?.profileName}
                                            </Typography>
                                          </CardContent>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={4}>
                                          {profile.status === 'Await' &&
                                            <>
                                              <Button onClick={() =>
                                                approveExpressionOfEnterest(profile.id)
                                              } className="dmsans purplebutton" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '25px', fontSize: '10px', margin: '2px' }}>Approve</Button>
                                              <Button onClick={() => rejectExpressionOfEnterest(profile.id)} className="dmsans purplebutton" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#EC5B5B', padding: '5px', height: '25px', fontSize: '10px', margin: '2px' }}>Reject</Button>
                                            </>
                                          }
                                        </Grid>
                                      </Card>
                                    ))
                                  ) : (
                                    <h6>No received requests</h6>
                                  )}
                                </div>
                              </TabPanel>

                              <TabPanel value="2" className="tabpanel">
                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                  {sentRequestsProfiles && sentRequestsProfiles.length > 0 ? (
                                    sentRequestsProfiles.map(profile => (
                                      <Card key={profile.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none' }}>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                          <CardMedia
                                            component="img"
                                            onClick={() => navigateToProfile(profile.requesteeProfile.id)}
                                            alt={"Profile Image"}
                                            style={{ height: '50px', width: '50px' }}
                                            src={user2}
                                          />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                          <CardContent>
                                            <Typography className="profilename" onClick={() => navigateToProfile(profile.requesteeProfile.id)} variant="p" component="div">
                                              {profile.requesteeProfile?.firstName || profile.requesteeProfile?.profileName}
                                            </Typography>
                                          </CardContent>
                                        </Grid>
                                        <Grid className="alignRight" item lg={3} md={3} sm={3} xs={3}>
                                          {profile.status === 'Await' &&
                                            <Button onClick={() =>
                                              cancelExpressionOfEnterest(profile.id)
                                            } className="dmsans purplebutton cancelbtn" variant="outlined" style={{ borderRadius: '30px', color: '#EC5B5B', border: '2px solid #EC5B5B', padding: '5px', height: '25px', fontSize: '10px' }}>Cancel</Button>
                                          }
                                        </Grid>
                                      </Card>
                                    ))
                                  ) : (
                                    <h6>No sent requests</h6>
                                  )}
                                </div>
                              </TabPanel>
                            </TabContext>
                          </Box>
                        </Card>
                      </Grid>

                      {/* <div>
                   {isSmallScreen && ( */}
                      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ display: { md: "none", lg: "none", sm: "block", xs: "block" } }}>
                        <Card variant="outlined" className="card card2">
                          <h6 className="h6tag">Connection Requests</h6>
                          <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                              <div className="h6tag">
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                  <TabList
                                    onChange={handleChange3}
                                    aria-label="lab API tabs example"
                                    sx={{
                                      color: '#9E80BC !important',
                                      "& .Mui-selected": { color: '#9E80BC !important' },
                                      "& .MuiTabs-indicator": { backgroundColor: '#9E80BC' }
                                    }}
                                  >
                                    <Tab label="Received" value="1" style={{ fontFamily: 'DM Sans' }} />
                                    <Tab label="Sent" value="2" style={{ fontFamily: 'DM Sans' }} />
                                    {allConnectedProfiles && allConnectedProfiles.length > 0 && (<Tab label="Connected" value="3" style={{ fontFamily: 'DM Sans' }} />)}
                                  </TabList>
                                </Box>
                              </div>
                              <TabPanel value="1" className="tabpanel">

                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                  {pendingRequestsProfiles && pendingRequestsProfiles.length > 0 ? (
                                    pendingRequestsProfiles.map(profile => (
                                      <Card key={profile.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none' }}>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                          <CardMedia
                                            component="img"
                                            onClick={() => navigateToProfile(profile.requesterProfile.id)}
                                            alt={"Profile Image"}
                                            style={{ height: '50px', width: '50px' }}
                                            src={user2}
                                          />
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={4}>
                                          <CardContent>
                                            <Typography className="profilename" onClick={() => navigateToProfile(profile.requesterProfile.id)} variant="p" component="div">
                                              {profile.requesterProfile?.firstName || profile.requesterProfile?.profileName}
                                            </Typography>
                                          </CardContent>
                                        </Grid>
                                        <Grid item lg={5} md={5} sm={5} xs={5}>
                                          {profile.status === 'Await' &&
                                            <>
                                              <Button onClick={() =>
                                                approveExpressionOfEnterest(profile.id)
                                              } className="dmsans purplebutton" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '25px', fontSize: '10px', margin: '2px' }}>Approve</Button>
                                              <Button onClick={() => rejectExpressionOfEnterest(profile.id)} className="dmsans purplebutton" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#EC5B5B', padding: '5px', height: '25px', fontSize: '10px', margin: '2px' }}>Reject</Button>
                                            </>
                                          }
                                        </Grid>
                                      </Card>
                                    ))
                                  ) : (
                                    <h6>No received requests</h6>
                                  )}
                                </div>
                              </TabPanel>
                              <TabPanel value="2" className="tabpanel">

                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                  {sentRequestsProfiles && sentRequestsProfiles.length > 0 ? (
                                    sentRequestsProfiles.map(profile => (
                                      <Card key={profile.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none' }}>
                                        <CardMedia
                                          component="img"
                                          onClick={() => navigateToProfile(profile.requesteeProfile.id)}
                                          alt={"Profile Image"}
                                          style={{ height: '50px', width: '50px' }}
                                          src={user2}
                                        />
                                        <CardContent>
                                          <Typography className="profilename" onClick={() => navigateToProfile(profile.requesteeProfile.id)} variant="p" component="div">
                                            {profile.requesteeProfile?.firstName || profile.requesteeProfile?.profileName}
                                          </Typography>
                                        </CardContent>
                                        {profile.status === 'Await' &&
                                          <Button onClick={() =>
                                            cancelExpressionOfEnterest(profile.id)
                                          } className="dmsans purplebutton cancelbtn" variant="outlined" style={{ borderRadius: '30px', color: '#EC5B5B', border: '2px solid #EC5B5B', padding: '5px', height: '25px', fontSize: '10px' }}>Cancel</Button>
                                        }
                                      </Card>
                                    ))
                                  ) : (
                                    <h6>No sent requests</h6>
                                  )}
                                </div>
                              </TabPanel>

                              <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                {allConnectedProfiles && allConnectedProfiles.length > 0 && (
                                  <TabPanel value="3" className="tabpanel">
                                    {/* {allConnectedProfiles.length > 0 ? (<h6>Connected people</h6>) : ""} */}
                                    {allConnectedProfiles.length > 0 && (
                                      allConnectedProfiles.map(profile => (
                                        <Card key={profile.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none' }}>
                                          <Grid item lg={3} md={3} sm={3} xs={3}>
                                            <CardMedia className="cardimg"
                                              component="img"
                                              onClick={() => navigateToProfile(profile.requesterProfile?.id || profile.requesteeProfile?.id)}
                                              alt="Sample Image"
                                              style={{
                                                height: '50px', width: '50px'
                                                // , filter: (profile.photoData?.isApproved) ? 'none' : (!profile.photoData?.isApproved && profile.photoData !== null) && 'blur(4px)' 
                                              }}
                                              src={profile.photoData === null ? user2 : (profile.photoData?.fileContents && (profile.photoData?.isApproved) ? `data:image;base64,${profile.photoData.fileContents}` : user2)}
                                            />
                                          </Grid>
                                          <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <CardContent>
                                              <Typography className="profilename" onClick={() => navigateToProfile(profile.requesterProfile?.id || profile.requesteeProfile?.id)} variant="p" component="div">
                                                {profile.requesterProfile?.firstName ? profile.requesterProfile.firstName : profile.requesteeProfile?.firstName ? profile.requesteeProfile.firstName : profile.requesterProfile?.profileName ? profile.requesterProfile.profileName : profile.requesteeProfile?.profileName ? profile.requesteeProfile.profileName : ''}
                                              </Typography>
                                            </CardContent>
                                          </Grid>
                                          <Grid className="alignRight" item lg={3} md={3} sm={3} xs={3}>
                                            <Button onClick={() =>
                                              disconnectExpressionOfEnterest(profile.id)
                                            } className="dmsans purplebutton cancelbtn" variant="outlined" style={{ borderRadius: '30px', color: '#EC5B5B', border: '2px solid #EC5B5B', padding: '5px', height: '25px', fontSize: '12px' }}>Cancel</Button>
                                          </Grid>
                                        </Card>
                                      ))
                                    )}
                                  </TabPanel>
                                )}
                              </div>
                            </TabContext>
                          </Box>
                        </Card>
                      </Grid>

                      <Grid item lg={4} md={4} sm={12} xs={12} >
                        <Card variant="outlined" className="card card2">
                          <h6 className="imgreq">Photo requests</h6>
                          <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={imgValue}>
                              <div className="imgreq">
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                  <TabList
                                    onChange={imgHandleChange3}
                                    aria-label="lab API tabs example"
                                    sx={{
                                      color: '#9E80BC !important',
                                      "& .Mui-selected": { color: '#9E80BC !important' },
                                      "& .MuiTabs-indicator": { backgroundColor: '#9E80BC' }
                                    }}
                                  >
                                    <Tab label="Received" value="4" style={{ fontFamily: 'DM Sans' }} />
                                    <Tab label="Sent" value="5" style={{ fontFamily: 'DM Sans' }} />
                                    {allImgReqConnectedProfiles && allImgReqConnectedProfiles.length > 0 && (<Tab label="Approved" value="6" style={{ fontFamily: 'DM Sans' }} />)}
                                  </TabList>
                                </Box>
                              </div>
                              <TabPanel value="4" className="tabpanel">

                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                  {pendingImgRequestsProfiles && pendingImgRequestsProfiles.length > 0 ? (
                                    pendingImgRequestsProfiles.map(profile => (
                                      <Card key={profile.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none' }}>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                          <CardMedia
                                            component="img"
                                            onClick={() => navigateToProfile(profile.photoRequesterProfile.id)}
                                            alt={"Profile Image"}
                                            style={{ height: '50px', width: '50px' }}
                                            src={user2}
                                          />
                                        </Grid>
                                        <Grid item lg={5} md={5} sm={5} xs={5}>
                                          <CardContent>
                                            <Typography className="profilename" onClick={() => navigateToProfile(profile.photoRequesterProfile.id)} variant="p" component="div">
                                              {profile.photoRequesterProfile?.firstName || profile.photoRequesterProfile?.profileName}
                                            </Typography>
                                          </CardContent>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={4} >
                                          {profile.status === 'Await' &&
                                            <>
                                              <Button onClick={() =>
                                                approveReceivedImageequest(profile.id)
                                              } className="dmsans purplebutton" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '25px', fontSize: '10px', margin: '2px' }}>Approve</Button>
                                              <Button onClick={() => rejectReceivedImageRequest(profile.id)} className="dmsans purplebutton" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#EC5B5B', padding: '5px', height: '25px', fontSize: '10px', margin: '2px' }}>Reject</Button>
                                            </>
                                          }
                                        </Grid>
                                      </Card>
                                    ))
                                  ) : (
                                    <h6>No received requests</h6>
                                  )}
                                </div>
                              </TabPanel>
                              <TabPanel value="5" className="tabpanel">

                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                  {sentImgRequestsProfiles && sentImgRequestsProfiles.length > 0 ? (
                                    sentImgRequestsProfiles.map(profile => (
                                      <Card key={profile.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none' }}>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                          <CardMedia
                                            component="img"
                                            onClick={() => navigateToProfile(profile.photoRequesteeProfile.id)}
                                            alt={"Profile Image"}
                                            style={{ height: '50px', width: '50px' }}
                                            src={user2}
                                          />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                          <CardContent>
                                            <Typography className="profilename" onClick={() => navigateToProfile(profile.photoRequesteeProfile.id)} variant="p" component="div">
                                              {profile.photoRequesteeProfile?.firstName || profile.photoRequesteeProfile?.profileName}
                                            </Typography>
                                          </CardContent>
                                        </Grid>
                                        <Grid className="alignRight" item lg={3} md={3} sm={3} xs={3}>
                                          {profile.status === 'Await' &&
                                            <Button onClick={() =>
                                              cancelSentImageRequest(profile.id)
                                            } className="dmsans purplebutton cancelbtn" variant="outlined" style={{ borderRadius: '30px', color: '#EC5B5B', border: '2px solid #EC5B5B', padding: '5px', height: '25px', fontSize: '10px' }}>Cancel</Button>
                                          }
                                        </Grid>
                                      </Card>
                                    ))
                                  ) : (
                                    <h6>No sent requests</h6>
                                  )}
                                </div>
                              </TabPanel>

                              <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                {allImgReqConnectedProfiles && allImgReqConnectedProfiles.length > 0 && (
                                  <TabPanel value="6" className="tabpanel">
                                    {/* {allImgReqConnectedProfiles.length > 0 ? (<h6>Connected people</h6>) : ""} */}
                                    {allImgReqConnectedProfiles.length > 0 && (
                                      allImgReqConnectedProfiles.map(profile => (
                                        <Card key={profile.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none' }}>
                                          <Grid item lg={3} md={3} sm={3} xs={3}>
                                            <CardMedia className="cardimg"
                                              component="img"
                                              onClick={() => navigateToProfile(profile.photoRequesterProfile?.id || profile.photoRequesteeProfile?.id)}
                                              alt="Sample Image"
                                              style={{
                                                height: '50px', width: '50px'
                                                // , filter: (profile.photoData?.isApproved) ? 'none' : (!profile.photoData?.isApproved && profile.photoData !== null) && 'blur(4px)'
                                              }}
                                              src={profile.photoData === null ? user2 : ((profile.photoData?.fileContents && (profile.photoData?.isApproved) && !(profile.photoRequesterProfile?.id && !profile.photoRequesteeProfile?.id)) ? `data:image;base64,${profile.photoData.fileContents}` : user2)}
                                            />
                                          </Grid>
                                          <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <CardContent>
                                              <Typography className="profilename" onClick={() => navigateToProfile(profile.photoRequesterProfile?.id || profile.photoRequesteeProfile?.id)} variant="p" component="div">
                                                {profile.photoRequesterProfile?.firstName ? profile.photoRequesterProfile.firstName : profile.photoRequesteeProfile?.firstName ? profile.photoRequesteeProfile.firstName : profile.photoRequesterProfile?.profileName ? profile.photoRequesterProfile.profileName : profile.photoRequesteeProfile?.profileName ? profile.photoRequesteeProfile.profileName : ''}
                                              </Typography>
                                            </CardContent>
                                          </Grid>
                                          <Grid className="alignRight" item lg={3} md={3} sm={3} xs={3}>
                                            {(profile.photoRequesterProfile?.id && !profile.photoRequesteeProfile?.id) &&
                                              <Button onClick={() =>
                                                disconnectApprovedImgRequest(profile.id)
                                              } className="dmsans purplebutton cancelbtn" variant="outlined" style={{ borderRadius: '30px', color: '#EC5B5B', border: '2px solid #EC5B5B', padding: '5px', height: '25px', fontSize: '12px' }}>Cancel</Button>
                                            }
                                          </Grid>
                                        </Card>
                                      ))
                                    )}
                                  </TabPanel>
                                )}
                              </div>
                            </TabContext>
                          </Box>
                        </Card>
                      </Grid>



                      
                    </>
                  }
                  {(userType === 'ACTIVE_PROFILE_USER' && suggestionProfiles.length > 0) &&
                    <Grid item xs={12}>
                      <Card className="card card3">
                        <h6>Vivaakam's top {suggestionProfiles.length} picks await your consideration!</h6>
                        <div className="imageSlider">
                          <ImageSlider suggestionProfiles={suggestionProfiles} />
                        </div>
                      </Card>
                    </Grid>
                  }
                </Grid>

              </Box>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;