import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './../../CSS/Proflies.css';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CreateIcon from '@mui/icons-material/Create';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import RemoveIcon from '@mui/icons-material/Remove';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import ImageUpload from './ImageUploader.js';
import Container from '@mui/material/Container';
import swal from 'sweetalert';
import { useMember } from "../../Contexts/memberContext.js";
import { useApp } from "../../Contexts/appContext.js";
import { getAge } from '../../Utils/getAge.js';
import { deleteMainProfilePhoto, getAllHoroscopes, getAllProfilePhotos, getProfilePhoto } from "../../Services/Profile/photoService.js";
import { Spinner } from "../Common/Spinner.js";
import User from '../../Assests/imgs/user2.png';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import { removeBookmark, getBookmarks, getPhotoStatuses, updatePhotoStatus } from "../../Services/Profile/profileService.js";
import { addTopVisitor, getRelationship } from '../../Services/Profile/expressionOfInterest';
import {
  hideProfile,
  softDelete,
  unDelete,
  unhideProfile,
} from "../../Services/Profile/hidedProfileService.js";
import { BasicDetailsEditModal } from "./BasicDetailsEditModal.js";
import { PhoneNumberEditModal } from "./phoneNumberEditModal.js";
import { HobbiesInterestsEditModal } from './HobbiesInterestsEditModal.js';
import { FamilyDetailsEditModal } from './FamilyDetailsEditModal.js';
import { LocationDetailsEditModal } from './LocationDetailsEditModal.js';
import { EducationProfessionEditModal } from './EducationProfessionEditModal.js';
import { PartnerCriteriaEditModal } from './PartnerCriteriaEditModal.js';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CarouselComponent from '../Mini Components/Carousel.js';
import { EmojiEmotions, Group, School, Work, LocationOn, Favorite, Star } from '@mui/icons-material';
import ImageUploadHoroscope from './ImageUploaderHoroscope.js';
import HoroscopeCarousel from '../Mini Components/HoroscopeCarousel.js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 2,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#65C466' : '#2ECA45',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#0a140b' : '#000000',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#b5b5b5' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function OtherProfile() {
  const { profile, setProfile, bookMarkedProfiles, setBookMarkedProfiles, allConnectedProfiles, allImgReqConnectedProfiles } = useMember();
  const { setUserType, metaData } = useApp();
  const navigate = useNavigate();
  const [loadingIcon, setSetLoadingIcon] = useState(true);
  const [reason, setReason] = useState("");
  const [isHidedState, setIsHidedState] = useState();
  const [interestStatus, setInterestStatus] = useState("NONE");
  // const [profile, setProfile] = useState(myProfile);
  const [aboutMe, setAboutMe] = useState(profile?.about || '');
  const [reasonErrorMessage, setReasonErrorMessage] = useState();
  const [profilePhotos, setProfilePhotos] = useState([]);
  const [horoscopePhotos, setHoroscopePhotos] = useState([]);
  const [photosLoading, setPhotosLoading] = useState(false);
  const [mainProfilePhoto, setMainProfilePhoto] = useState([]);
  const [photoStatuses, setPhotoStatuses] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchData = async () => {
      if (profile && metaData) {
        setPhotosLoading(true);
        setIsHidedState(profile.isHided);

      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (photosLoading) {
        const [photos, horoscopePhotos, photoStatuses] = await Promise.all([
          getAllProfilePhotos(profile.id),
          getAllHoroscopes(profile.id),
          getPhotoStatuses()
        ]);
        if (photoStatuses.status === 200) {
          setPhotoStatuses(photoStatuses.data);
        }
        if (horoscopePhotos.status === 200) {
          setHoroscopePhotos(horoscopePhotos.data);
        }
        if (photos.status === 200) {
          let mainProfilePic = photos.data.filter((data) => data.id === profile.mainProfilePhotoId);
          setMainProfilePhoto(mainProfilePic);
          setProfilePhotos(photos.data);
          setPhotosLoading(false);
          setSetLoadingIcon(false);
        }
      }
    }
    fetchData();
  }, [profile, photosLoading]);

  const handlePhotosLoading = () => setPhotosLoading(true);
  const handleRemovePhoto = (id) => setProfilePhotos((prevImg) => prevImg.filter((image) => image.id !== id));
  const handleHoroscopeRemove = (id) => setHoroscopePhotos((prevImg) => prevImg.filter((image) => image.id !== id));
  const handleMainProfilePic = (pic) => setMainProfilePhoto(pic);

  const removeMainProfilePic = async () => {
    swal({
      text: "Do you want to remove profile pic?",
      buttons: {
        cancel: 'Close',
        confirm: {
          text: 'Okay',
          className: 'request-button',
        },
      },
    }).then(async (response) => {
      if (response) {
        const res = await deleteMainProfilePhoto(profile.id);
        if (res.status === 204) {
          setMainProfilePhoto([]);
          setProfile(prevData => ({
            ...prevData,
            mainProfilePhotoId: 0
          }));
        }
        return;
      } else {
        setSetLoadingIcon(false);
      }
    });

  };

  const handleDelete = async (value) => {

    if (!profile?.isSoftDeleted && !value) {
      setReasonErrorMessage("Please select a reason before deleting the profile.");
      return;
    }
    const updateProfile = profile || " ";
    if (!profile?.isSoftDeleted) {
      setSetLoadingIcon(true);
      const body = { profileId: profile?.id, Reason: reason };
      const response = await softDelete(body);
      if (response.status === 201) {
        updateProfile.isSoftDeleted = true;
        swal({
          text: "Profile deleted",
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        swal({
          text: 'Profile deletion unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
      setSetLoadingIcon(false);
    } else if (profile?.isSoftDeleted) {
      setSetLoadingIcon(true);
      const response = await unDelete(profile?.id);
      if (response.status === 204) {
        updateProfile.isSoftDeleted = false;
        swal({
          text: "Profile restored",
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        swal({
          text: 'Profile restoring unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
      setSetLoadingIcon(false);
    }
    if (
      updateProfile.status === "Await" ||
      updateProfile.status === "Holded" ||
      updateProfile.isHided ||
      updateProfile.isSoftDeleted
    ) {
      setUserType("INACTIVE_PROFILE_USER");
    } else {
      setUserType("ACTIVE_PROFILE_USER");
    }
    setProfile(updateProfile);
    handleClose();
  };

  const handleToggle = async (value) => {
    const updateProfile = profile;
    if (value) {
      setSetLoadingIcon(true);
      const response = await hideProfile(profile.id);
      if (response.status === 201) {
        setIsHidedState(true);
        updateProfile.isHided = true;
        setSetLoadingIcon(false);
        swal({
          text: "Profile hided",
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        setSetLoadingIcon(false);
        swal({
          text: 'Profile hiding unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    } else {
      setSetLoadingIcon(true);
      const response = await unhideProfile(profile.id);
      if (response.status === 204) {
        setIsHidedState(false);
        updateProfile.isHided = false;
        setSetLoadingIcon(false);
        swal({
          text: "Profile visible",
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        });
      } else {
        setSetLoadingIcon(false);
        swal({
          text: 'Profile unhiding unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    }
    if (
      updateProfile.status === "Await" ||
      updateProfile.status === "Holded" ||
      updateProfile.isHided ||
      updateProfile.isSoftDeleted
    ) {
      setUserType("INACTIVE_PROFILE_USER");
    } else {
      setUserType("ACTIVE_PROFILE_USER");
    }
    setProfile(updateProfile);
  };

  const handleClick = async (bookmarkId) => {
    setSetLoadingIcon(true);
    swal({
      text: "Do you want to remove this profile form favourites?",
      buttons: {
        cancel: 'Close',
        confirm: {
          text: 'Okay',
          className: 'request-button',
        },
      },
    }).then(async (response) => {
      if (response) {
        const profilesObject = {
          profileId: profile.id,
          bookMarkedProfileId: bookmarkId
        };
        await removeFromBookmarked(profilesObject);
        setSetLoadingIcon(false);
      } else {
        setSetLoadingIcon(false);
      }
    });
  };

  const removeFromBookmarked = async (profilesObject) => {
    const removedResponse = await removeBookmark(profilesObject);
    if (removedResponse.status === 200) {
      swal({
        text: "Removed successfully",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
      // Remove the bookmarked profile from bookMarkedProfiles state
      const bookmarks = await getBookmarks(profile.id);
      if (bookmarks.status === 201) {
        setBookMarkedProfiles(bookmarks.data);
      }
    } else {
      swal({
        text: 'Unsuccessful. Please try again.',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const navigateToProfile = async (resultProfileId) => {
    setSetLoadingIcon(true);
    const relationshipResponse = await getRelationship(resultProfileId, profile.externalId);
    if (relationshipResponse.status === 200) {
      if (relationshipResponse.data.status === "Approved") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("APPROVEDBYME");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("APPROVED");
        }
      } else if (relationshipResponse.data.status === "Await") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("PENDING");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("SENT");
        }
      } else if (relationshipResponse.data.status === "Rejected") {
        if (relationshipResponse.data.requesterProfile) {
          setInterestStatus("REJECTEDBYME");
        }
        if (relationshipResponse.data.requesteeProfile) {
          setInterestStatus("REJECTED");
        }
      } else if (!relationshipResponse.data.status) {
        setInterestStatus("NONE");
      }
      if (interestStatus !== "APPROVEDBYME") {
        let obj = {};
        obj.requesterProfileId = profile.id;
        obj.requesteeProfileId = resultProfileId;
        const AddTopVisitor = await addTopVisitor(obj);
        if (AddTopVisitor.status === 500) {
          console.log("Error while saving");
        }
      }
    } else {
      console.log("Error in calling getRelationship");
    }
    navigate('/otherprofiles', { state: { resultProfileId } });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openBasicDetailsEditModal, setOpenBasicDetailsEditModal] = useState(false);
  const handleOpenBasicDetailsEditModal = () => { setOpenBasicDetailsEditModal(true); };
  const handleCloseBasicDetailsEditModal = () => { setOpenBasicDetailsEditModal(false); };

  const [openPhoneNumberEditModal, setOpenPhoneNumberEditModal] = useState(false);
  const handleOpenPhoneNumberEditModal = () => { setOpenPhoneNumberEditModal(true); };
  const handleClosePhoneNumberEditModal = () => { setOpenPhoneNumberEditModal(false); };

  const [openHobbiesInterestsEditModal, setOpenHobbiesInterestsEditModal] = useState(false);
  const handleOpenHobbiesInterestsEditModal = () => { setOpenHobbiesInterestsEditModal(true); };
  const handleCloseHobbiesInterestsEditModal = () => { setOpenHobbiesInterestsEditModal(false); };

  const [openFamilyDetailsEditModal, setOpenFamilyDetailsEditModal] = useState(false);
  const handleOpenFamilyDetailsEditModal = () => { setOpenFamilyDetailsEditModal(true); };
  const handleCloseFamilyDetailsEditModal = () => { setOpenFamilyDetailsEditModal(false); };

  const [openLocationDetailsEditModal, setOpenLocationDetailsEditModal] = useState(false);
  const handleOpenLocationDetailsEditModal = () => { setOpenLocationDetailsEditModal(true); };
  const handleCloseLocationDetailsEditModal = () => { setOpenLocationDetailsEditModal(false); };

  const [openEducationProfessionEditModal, setOpenEducationProfessionEditModal] = useState(false);
  const handleOpenEducationProfessionEditModal = () => { setOpenEducationProfessionEditModal(true); };
  const handleCloseEducationProfessionEditModal = () => { setOpenEducationProfessionEditModal(false); };

  const [openPartnerCriteriaEditModal, setOpenPartnerCriteriaEditModal] = useState(false);
  const handleOpenPartnerCriteriaEditModal = () => { setOpenPartnerCriteriaEditModal(true); };
  const handleClosePartnerCriteriaEditModal = () => { setOpenPartnerCriteriaEditModal(false); };

  const [mainProfilePhotoBookMarks, setMainProfilePhotoBookMarks] = useState(null);

  const isScreenLargerThan1150 = useMediaQuery('(min-width:1150px)');

  useEffect(() => {
    fetchProfilePhotos(bookMarkedProfiles);
  }, []);

  const fetchProfilePhotos = async (bookMarkedProfiles) => {
    const updatedProfiles = [];
    for (const profile of bookMarkedProfiles) {
      let photoData = null;
      const isProfileInArray = allConnectedProfiles.some(profileConnected => {
        return ((profileConnected.requesteeProfile?.id || profileConnected.requesterProfile?.id) === profile.bookMarkedProfile.id);
      });
      const isPhotosApproved = allImgReqConnectedProfiles.some(data => {
        return (data.photoRequesteeProfile?.id === profile.bookMarkedProfile.id);
      });
      if (isProfileInArray || isPhotosApproved || profile.photoStatus === "Public") {
        if (profile.bookMarkedProfile.mainProfilePhotoId) {
          try {
            const photoResponse = await getProfilePhoto(profile.bookMarkedProfile.mainProfilePhotoId);
            photoData = photoResponse.data;
          } catch (error) {
            console.error("Error fetching profile photo for profile ID", profile.bookMarkedProfile.id, ":", error);
          }
        }
      }
      profile.photoData = photoData;
      const updatedProfile = {
        ...profile,
        photoData: photoData
      };
      updatedProfiles.push(updatedProfile);
    }
    setBookMarkedProfiles(updatedProfiles);
  };

  const handlePhotoStatus = async (e) => {
    let data = {
      photoStatus: e
    };
    const statusRes = await updatePhotoStatus(profile.id, data);
    if (statusRes.status === 200) {
      swal({
        text: `Image privacy status changed to ${e === "Public" ? "Everyone" : e === 'Request' ? "Visible for accepted image requestees" : "Only for connected profiles"}`,
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      }).then((data) => {
        setProfile(prevData => ({
          ...prevData,
          photoStatus: e
        }));
      });

    }
  };

  return (
    <>
      <div className="OtherProfile topspaceprof">
        <Box sx={{ flexGrow: 1 }}>
          {(loadingIcon && photosLoading) ? (<Spinner></Spinner>) : (
            <div>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} lg={6} xs={12}>
                  <div className="sides">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                      {/* For screens larger than 600px */}
                      {isScreenLargerThan1150 && (
                        <>
                          {photosLoading ? (<Spinner></Spinner>) : (
                            <>
                              <Grid item md={9} sm={8} lg={9} xs={8}>
                                <div>
                                  {!mainProfilePhoto[0]?.fileContents ?
                                    <img className="otherproimg" src={User} alt='otherproimg' style={{ borderRadius: '10px' }} />
                                    :
                                    <img style={{ filter: (mainProfilePhoto[0]?.isApproved) ? 'none' : 'blur(4px)', borderRadius: '10px' }}
                                      className="otherproimg" src={!mainProfilePhoto[0]?.fileContents ? User : `data:image;base64,${mainProfilePhoto[0]?.fileContents}`} alt='otherproimg' onClick={removeMainProfilePic} />
                                  }
                                </div>
                              </Grid>
                              <Grid item md={3} sm={4} lg={3} xs={4}>
                                <CarouselComponent
                                  profilePhotos={profilePhotos}
                                  profileId={profile?.id}
                                  handlePhotosLoading={handlePhotosLoading}
                                  handleImgRemove={(id) => handleRemovePhoto(id)}
                                  handleMainProfile={(e) => handleMainProfilePic(e)}
                                />
                                <div className="miniImgsWeb">
                                  <Container maxWidth="sm">
                                    <ImageUpload profileId={profile?.id} profilePhotos={profilePhotos} handlePhotosLoading={handlePhotosLoading} />
                                  </Container>
                                </div>
                              </Grid>
                            </>
                          )}
                        </>
                      )}

                      {/* For screens smaller than or equal to 600px */}
                      {!isScreenLargerThan1150 && (
                        <>
                          {photosLoading ? (<Spinner></Spinner>) : (
                            <div>
                              <Grid item md={12} sm={12} lg={12} xs={12}>
                                <div>
                                  {!mainProfilePhoto[0]?.fileContents ?
                                    <img className="otherproimg" src={User} alt='otherproimg' style={{ borderRadius: '10px' }} />
                                    :
                                    <img style={{ filter: (mainProfilePhoto[0]?.isApproved) ? 'none' : 'blur(4px)', borderRadius: '10px' }}
                                      className="otherproimg" src={!mainProfilePhoto[0]?.fileContents ? User : `data:image;base64,${mainProfilePhoto[0]?.fileContents}`} alt='otherproimg' onClick={removeMainProfilePic} />
                                  }
                                </div>
                              </Grid>
                              <Grid item md={12} sm={12} lg={12} xs={12}>
                                <div className="miniImgsMobile">
                                  <CarouselComponent
                                    profilePhotos={profilePhotos}
                                    profileId={profile?.id}
                                    handlePhotosLoading={handlePhotosLoading}
                                    handleImgRemove={(id) => handleRemovePhoto(id)}
                                    handleMainProfile={(e) => handleMainProfilePic(e)}
                                  />
                                  <div className="miniImgsMobile">
                                    <Container maxWidth="">
                                      <ImageUpload profileId={profile?.id} profilePhotos={profilePhotos} handlePhotosLoading={handlePhotosLoading} />
                                    </Container>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <hr />
                    <div style={{ marginRight: '10px' }}>
                      <div
                      // className="firstdata"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <div>
                              <h6>Your profile is currently {isHidedState ? "hidden" : "visible"}. </h6>
                            </div>
                          </Grid>
                          <Grid item xs={3} className="btn1">
                            <div>
                              <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={isHidedState} />} onChange={(event) => handleToggle(event.target.checked)} style={{ margin: '0px' }} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <hr />
                      <div
                      // className="firstdata"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <div>
                              <h6>{profile?.isSoftDeleted ? "Restore profile" : "Delete profile"}</h6>
                            </div></Grid>
                          <Grid item xs={3} className="btn1">
                            <div>
                              {profile?.isSoftDeleted ? <RestoreFromTrashIcon style={{ color: 'green' }} onClick={handleOpen} /> :
                                <DeleteOutlineIcon style={{ color: 'red' }} onClick={handleOpen} />
                              }
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <hr />
                      <div
                      // className="firstdata"
                      >
                        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px' }}>
                          {/* <Grid item xs={9}> */}
                          <h6 className="imageprivacy">
                            <word>Image</word>
                            &nbsp;
                            <word>Privacy</word>
                          </h6>
                          {/* </Grid> */}
                          {/* <Grid item xs={3} className="btn1"> */}
                          <div >
                            <FormControl className="Privacy">
                              <Select
                                style={{ borderRadius: '50px' }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={profile?.photoStatus}
                                onChange={(e) => {
                                  handlePhotoStatus(e.target.value);
                                }}
                                label="Image Privacy"
                              >
                                {photoStatuses.map((data) => (
                                  <MenuItem className="dmsans" value={data} key={data.id}>{data === "Public" ? "Everyone" : data === 'Request' ? "Accepted image requests" : "Only for connected profiles"}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          {/* </Grid> */}
                        </Grid>
                      </div>
                      <hr />
                    </div>

                    <div>
                      <div
                        className=
                        // "firstdata 
                        "seconddata">
                        {profile?.firstName || profile?.profileName ? (
                          <div>
                            <h3>
                              {profile.firstName ? `${profile.firstName} ${profile.surname || ''}` : profile.profileName}
                            </h3>
                          </div>
                        ) : null}
                        {/* <div>
                    <FavoriteToggleButton /></div> */}
                      </div>
                      <div className="firstdata thirddata">
                        <div>
                          <h3>{getAge(profile?.dob)} Years Old</h3></div><div>
                          {/* <Button className="dmsans" variant="outlined" style={{ borderRadius: '30px', borderColor: 'red', color: 'red', padding: '5px', height: '20px', fontSize: '10px', fontWeight: '600' }}>Remove connection</Button> */}
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                        <p2>{aboutMe}</p2>
                      </div>
                      <p1>Created by : {profile?.createdBy?.name || "N/A"}</p1>
                      <p1>Height :{profile?.height?.name || "N/A"}</p1>
                      <p1>Marital status : {profile?.maritalStatus?.name || "N/A"}</p1>
                      <p1>Has children : {profile?.hasChildren ? "Yes" : "No"}</p1>
                      {profile?.hasChildren && <p1>Number of kids : {profile?.numberOfKids}</p1>}
                      <p1>Dietary Habit : {profile?.dietHabbit?.name || "N/A"}</p1>
                      <p1>Drinking Habit : {profile?.drinkingHabbit?.name || "N/A"}</p1>
                      <p1>Smoking Habit : {profile?.smokingHabbit?.name || "N/A"}</p1>
                      {/* <div className="editIcon">
                        <CreateIcon onClick={handleOpenBasicDetailsEditModal} />
                      </div> */}
                      <BasicDetailsEditModal open={openBasicDetailsEditModal} handleClose={handleCloseBasicDetailsEditModal} profile={profile} />
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box
                            component="form"
                            sx={{
                              '& > :not(style)': { m: 1, width: '350px' },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            {profile?.isSoftDeleted === false ?
                              <>
                                <Typography className="dmsans" id="modal-modal-title" component="h2">
                                  Confirm deletion of your profile? Please provide a reason why you are deleting the profile
                                </Typography>
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel className="dmsans" id="demo-simple-select-label">Reason</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={reason}
                                      onChange={(e) => {
                                        setReason(e.target.value);
                                        setReasonErrorMessage(!e.target.value ? "Please select a reason" : "");
                                      }}
                                      label="Reason"
                                    >
                                      <MenuItem className="dmsans" value="Found a partner">Found a partner</MenuItem>
                                      <MenuItem className="dmsans" value="Married">Married</MenuItem>
                                      <MenuItem className="dmsans" value="Personal reasons">Personal reasons</MenuItem>
                                      <MenuItem className="dmsans" value="Other">Other</MenuItem>
                                    </Select>
                                  </FormControl>
                                  <p style={{ color: 'red' }}>{reasonErrorMessage}</p>
                                </Box>
                              </>
                              : <Typography id="modal-modal-title" component="h2">
                                Confirm restore profile
                              </Typography>}
                            {/* <TextField id="outlined-basic" label="Name" variant="outlined" />
                          <TextField id="outlined-basic" label="Age" variant="outlined" /> */}
                            {/* <TextField
                            id="outlined-multiline-static"
                            label="About Me"
                            multiline
                            rows={4}
                            defaultValue="Description"
                          /> */}
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <Button variant="outlined" style={{ borderRadius: '30px', borderColor: 'solid #9E80BC 1px', color: '#9E80BC' }} onClick={handleClose}>Cancel</Button>&nbsp;
                              <Button onClick={() => handleDelete(reason)} className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: profile?.isSoftDeleted ? '#9F70CA' : '#D52828' }}>{profile?.isSoftDeleted ? "Restore" : "Delete"}</Button>
                            </div>
                          </Box>
                        </Box>
                      </Modal>

                    </div>
                    <div className="editIcon">
                      <CreateIcon onClick={handleOpenBasicDetailsEditModal} />
                    </div>
                    <hr />
                    <p1>Email : {profile?.contactDetails?.email || "N/A"}</p1>
                    <p1>Phone number : {profile?.contactDetails?.phoneNumber || "N/A"}</p1>
                    <div className="editIcon">
                      <CreateIcon onClick={handleOpenPhoneNumberEditModal} />
                    </div>
                    <PhoneNumberEditModal open={openPhoneNumberEditModal} handleClose={handleClosePhoneNumberEditModal} profile={profile} />
                    <hr />
                    <div>
                      <div className="topicprofile">
                        <EmojiEmotions />
                        <h5>Hobbies and Interests</h5>
                      </div>
                      <p1>Hobbies : {profile?.profileHobbies?.length === 0 ? "N/A" : profile?.profileHobbies?.map(hobby => hobby.name).join(", ")}</p1>
                      <p1>Interests : {profile?.profileInterests?.length === 0 ? "N/A" : profile?.profileInterests?.map(interest => interest.name).join(", ")}</p1>
                      <p1>Dress Styles : {profile?.profileDressStyles?.length === 0 ? "N/A" : profile?.profileDressStyles?.map(dress => dress.name).join(", ")}</p1>
                      <p1>Cuisines : {profile?.profileCuisines?.length === 0 ? "N/A" : profile?.profileCuisines?.map(cusines => cusines.name).join(", ")}</p1>
                      <p1>Movie Genres : {profile?.profileMovieGenres?.length === 0 ? "N/A" : profile?.profileMovieGenres?.map(movie => movie.name).join(", ")}</p1>
                      <p1>Music Genres : {profile?.profileMusicGenres?.length === 0 ? "N/A" : profile?.profileMusicGenres?.map(music => music.name).join(", ")}</p1>
                      <p1>Sports and Fitness Activities : {profile?.profileSportsAndFitnessActivities?.length === 0 ? "N/A" : profile?.profileSportsAndFitnessActivities?.map(sports => sports.name).join(", ")}</p1>
                      <div className="editIcon">
                        <CreateIcon onClick={handleOpenHobbiesInterestsEditModal} />
                      </div>
                      <HobbiesInterestsEditModal open={openHobbiesInterestsEditModal} handleClose={handleCloseHobbiesInterestsEditModal} profile={profile} />
                    </div>
                    <hr />
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="data">
                          <div className="topicprofile">
                            <Group />
                            <h5>Family Details</h5>
                          </div>
                          <p1>Father status : {profile?.family?.fatherStatus?.name || "N/A"}</p1>
                          <p1>Mother status : {profile?.family?.motherStatus?.name || "N/A"}</p1>
                          <p1>Number of sisters : {profile?.family?.numberOfSisters}</p1>
                          <p1>Number of married sisters : {profile?.family?.numberOfSistersMarried}</p1>
                          <p1>Number of brothers : {profile?.family?.numberOfBrothers}</p1>
                          <p1>Number of married brothers : {profile?.family?.numberOfBrothersMarried}</p1>
                        </div>
                      </div>
                      <div className="editIcon">
                        <CreateIcon onClick={handleOpenFamilyDetailsEditModal} />
                      </div>
                      <FamilyDetailsEditModal open={openFamilyDetailsEditModal} handleClose={handleCloseFamilyDetailsEditModal} profile={profile} />
                    </div>
                    <hr />
                  </div>
                </Grid>
                <Grid item md={6} sm={12} lg={6} xs={12}>
                  <div className="sides">
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="data">
                          <div className="topicprofile">
                            <LocationOn />
                            <h5>Location</h5>
                          </div>
                          <p1>Country : {profile?.location?.country?.name || "N/A"}</p1>
                          <p1>City : {profile?.location?.city?.name || "N/A"}</p1>
                          <p1>State : {profile?.location?.state?.name || "N/A"}</p1>
                          <p1>Residential status : {profile?.location?.residentialStatus?.name || "N/A"}</p1>
                          <p1>Ethnic origin : {profile?.location?.ethnicOrigin?.name || "N/A"}</p1>
                          <p1>Religion : {profile?.location?.religion?.name || "N/A"}</p1>
                          <p1>Mother tongue : {profile?.location?.motherTongue?.name || "N/A"}</p1>
                          <p1>Community : {profile?.location?.community?.name || "N/A"}</p1>
                          <p1>Spoken languages : {profile?.profileLanguages?.length === 0 ? "N/A" : profile?.profileLanguages?.map(language => language.name).join(", ")}</p1>
                          <p1>Caste  : {profile?.location?.cast?.name || "N/A"}</p1>
                          <p1>Caste no bar  : {profile?.location?.castNoBar ? "Yes" : "No"}</p1>
                          <p1>Growup countries  : {profile?.growUpInCountries?.length === 0 ? "N/A" : profile?.growUpInCountries?.map(grewUp => grewUp.name).join(", ")}</p1>
                        </div>
                      </div>
                      <div className="editIcon">
                        <CreateIcon onClick={handleOpenLocationDetailsEditModal} />
                      </div>
                      <LocationDetailsEditModal open={openLocationDetailsEditModal} handleClose={handleCloseLocationDetailsEditModal} profile={profile} />
                    </div>
                    <hr />
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="data">
                          <div className="topicprofile">
                            <School />
                            <h5>Education And Profession</h5>
                          </div>
                          <p1>Educational qualifications : {profile?.educationAndProfession?.highestQualification?.name || "N/A"}</p1>
                          <p1>Profession : {profile?.educationAndProfession?.profession?.name || "N/A"}</p1>
                          <p1>Job sector : {profile?.educationAndProfession?.jobSector?.name || "N/A"}</p1>
                        </div>
                      </div>
                      <div className="editIcon">
                        <CreateIcon onClick={handleOpenEducationProfessionEditModal} />
                      </div>
                      <EducationProfessionEditModal open={openEducationProfessionEditModal} handleClose={handleCloseEducationProfessionEditModal} profile={profile} />
                    </div>
                    <hr />
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="data">
                          <div className="topicprofile">
                            <Favorite />
                            <h5>Partner Criteria</h5>
                          </div>
                          <p1>Minimum Age : {profile?.partnerCriterion?.ageLowerBound?.name || "N/A"}</p1>
                          <p1>Maximum Age : {profile?.partnerCriterion?.ageUpperBound?.name || "N/A"}</p1>
                          <p1>Minimum Height : {profile?.partnerCriterion?.heightLowerBound?.name || "N/A"}</p1>
                          <p1>Maximum Height : {profile?.partnerCriterion?.heightUpperBound?.name || "N/A"}</p1>
                          <p1>Has children : {profile?.partnerCriterion?.hasChildren?.name || "N/A"}</p1>
                          <p1>Profile created by : {profile?.partnerCriterion?.creators?.length === 0 ? "N/A" : profile?.partnerCriterion?.creators?.map(status => status.name).join(", ")}</p1>
                          <p1>Countries : {profile?.partnerCriterion?.countries?.length === 0 ? "N/A" : profile?.partnerCriterion?.countries?.map(status => status.name).join(", ")}</p1>
                          <p1>Religions : {profile?.partnerCriterion?.religions?.length === 0 ? "N/A" : profile?.partnerCriterion?.religions?.map(status => status.name).join(", ")}</p1>
                          <p1>Mother Tongues : {profile?.partnerCriterion?.motherTongues?.length === 0 ? "N/A" : profile?.partnerCriterion?.motherTongues?.map(status => status.name).join(", ")}</p1>
                          <p1>Marital Statuses : {profile?.partnerCriterion?.maritalStatuses?.length === 0 ? "N/A" : profile?.partnerCriterion?.maritalStatuses?.map(status => status.name).join(", ")}</p1>
                          <p1>Dietary Habits : {profile?.partnerCriterion?.dietHabbits?.length === 0 ? "N/A" : profile?.partnerCriterion?.dietHabbits?.map(status => status.name).join(", ")}</p1>
                          <p1>Drinking Habits : {profile?.partnerCriterion?.drinkingHabbits?.length === 0 ? "N/A" : profile?.partnerCriterion?.drinkingHabbits?.map(status => status.name).join(", ")}</p1>
                          <p1>Smoking Habits : {profile?.partnerCriterion?.smokingHabbits?.length === 0 ? "N/A" : profile?.partnerCriterion?.smokingHabbits?.map(status => status.name).join(", ")}</p1>
                          <p1>Job sectors : {profile?.partnerCriterion?.jobSectors?.length === 0 ? "N/A" : profile?.partnerCriterion?.jobSectors?.map(status => status.name).join(", ")}</p1>
                          <p1>Professions : {profile?.partnerCriterion?.professions?.length === 0 ? "N/A" : profile?.partnerCriterion?.professions?.map(status => status.name).join(", ")}</p1>
                          <p1>Educational qualifications : {profile?.partnerCriterion?.qualifications?.length === 0 ? "N/A" : profile?.partnerCriterion?.qualifications?.map(status => status.name).join(", ")}</p1>
                        </div>
                      </div>
                      <div className="editIcon">
                        <CreateIcon onClick={handleOpenPartnerCriteriaEditModal} />
                      </div>
                      <PartnerCriteriaEditModal open={openPartnerCriteriaEditModal} handleClose={handleClosePartnerCriteriaEditModal} profile={profile} />
                    </div>
                    <hr />
                    <Card>
                      <div className="card card2" style={{}}>
                        <h5>Short Listed Profiles</h5>
                        <div style={{ overflowY: 'scroll' }}>
                          {bookMarkedProfiles && bookMarkedProfiles.length > 0 ? (
                            bookMarkedProfiles.map(profile => (
                              <div key={profile.profileId}>
                                <Card sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: 'none', boxShadow: 'none', marginBottom: '10px' }}>
                                  <CardMedia
                                    className="cardimg"
                                    onClick={() => navigateToProfile(profile.bookMarkedProfile?.id)}
                                    component="img"
                                    alt="Sample Image"
                                    style={{
                                      height: '50px', width: '50px'
                                      // , filter: (profile.photoData === null || profile.bookMarkedProfile?.mainProfilePhotoId === 0 || profile.photoData?.isApproved) ? 'none' : (!profile.photoData?.isApproved && profile.bookMarkedProfile?.mainProfilePhotoId !== 0) && 'blur(4px)' 
                                    }}
                                    image={profile.photoData === null ? User : (profile.photoData?.fileContents && (profile.bookMarkedProfile?.mainProfilePhotoId === 0 || profile.photoData?.isApproved) ? `data:image;base64,${profile.photoData.fileContents}` : User)}
                                  />
                                  <CardContent>
                                    <Typography onClick={() => navigateToProfile(profile.bookMarkedProfile?.id)} variant="p" component="div">
                                      {profile.bookMarkedProfile?.firstName ? `${profile.bookMarkedProfile?.firstName} ${profile.bookMarkedProfile?.surname || ''}` : profile.bookMarkedProfile?.profileName}
                                    </Typography>
                                  </CardContent>
                                  <IconButton color="primary" aria-label="Remove" sx={{ color: 'red', border: '2px solid red', width: '20px', height: '20px' }}>
                                    <RemoveIcon onClick={() => handleClick(profile.bookMarkedProfile?.id)} />
                                  </IconButton>
                                </Card>
                              </div>
                            ))
                          ) : (
                            <h6>No short listed profiles</h6>
                          )}</div>
                      </div>
                    </Card>

                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="data">
                          <div className="topicprofile">
                            <Star />
                            <h5>Horoscope </h5>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {/* <img className="horasimg" src={horasimg} alt='otherproimg' />
                          <img className="horasimg" src={horasimg} alt='otherproimg' /> */}
                            {photosLoading ? (<Spinner></Spinner>) : (
                              <>
                                <Grid item md={9} sm={8} lg={9} xs={8}>
                                  <HoroscopeCarousel
                                    horoscopePhotos={horoscopePhotos}
                                    profileId={profile?.id}
                                    handleImgRemove={(e) => handleHoroscopeRemove(e)}
                                  />
                                </Grid>
                                <Grid item md={3} sm={4} lg={3} xs={4}>
                                  <div className="miniImgs">
                                    <Container maxWidth="sm">
                                      <ImageUploadHoroscope profileId={profile?.id} horoscopePhotos={horoscopePhotos} handlePhotosLoading={handlePhotosLoading} />
                                    </Container>
                                  </div>
                                </Grid>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="editIcon">
                      <CreateIcon />
                    </div> */}
                    </div>
                    <hr />
                  </div>
                </Grid>
              </Grid>
            </div>
          )
          }
        </Box>
      </div >
    </>
  );
};