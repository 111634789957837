import axios from "axios";
import { MicrosoftLoginAgent } from "./Authentication/msal";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  async (request) => {
    request.headers["Authorization"] =
      "Bearer " + (await MicrosoftLoginAgent.getAccessToken());
    return request;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(null, (error) => {
  console.log('error :', error)
  const expectedError =
    //error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.log("An unexpected error occurred.");
    return Promise.reject(error);
  }
  return Promise.resolve(error.response);
});

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};

export default http;
