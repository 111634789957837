import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Spinner, ButtonSpinner } from "../Common/Spinner";
import Typography from '@mui/material/Typography';
import { updateProfileBasicDetails } from '../../Services/Profile/profileService.js';
import { useMember } from "../../Contexts/memberContext.js";
import { useApp } from "../../Contexts/appContext.js";
import Autocomplete from '@mui/material/Autocomplete';
import swal from 'sweetalert';
import {
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
} from '@material-ui/core';

export const BasicDetailsEditModal = ({ open, handleClose }) => {
  const { profile, setProfile } = useMember();
  const { setUserType, metaData } = useApp();
  const [modalLoading, setModalLoading] = useState(false);
  const [initialsErrorMessage, setInitialsErrorMessage] = useState('');
  const [profileNameErrorMessage, setProfileNameErrorMessage] = useState('');
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState();
  const [createdByErrorMessage, setCreatedByErrorMessage] = useState();
  const [userInitials, setUserInitials] = useState(profile?.surname || '');
  const [userProfileName, setUserProfileName] = useState(profile?.profileName || '');
  const [userFirstName, setUserFirstName] = useState(profile?.firstName || '');
  const [userCreatedBy, setUserCreatedBy] = useState(profile?.createdBy || {});
  const [inputCreatedBy, setInputCreatedBy] = useState("");
  const [userCreatedByEdited, setUserCreatedByEdited] = useState(profile?.createdBy?.id || null);

  const [userDietHabbit, setUserDietHabbit] = useState(profile?.dietHabbit || {});
  const [inputDietHabbit, setInputDietHabbit] = useState("");
  const [userDietHabbitEdited, setUserDietHabbitEdited] = useState(profile?.dietHabbit?.id || null);

  const [userDrinkingHabbit, setUserDrinkingHabbit] = useState(profile?.drinkingHabbit || {});
  const [inputDrinkingHabbit, setInputDrinkingHabbit] = useState("");
  const [userDrinkingHabbitEdited, setUserDrinkingHabbitEdited] = useState(profile?.drinkingHabbit?.id || null);

  const [userSmokingHabbit, setUserSmokingHabbit] = useState(profile?.smokingHabbit || {});
  const [inputSmokingHabbit, setInputSmokingHabbit] = useState("");
  const [userSmokingHabbitEdited, setUserSmokingHabbitEdited] = useState(profile?.smokingHabbit?.id || null);

  const [userMaritalStatus, setUserMaritalStatus] = useState(profile?.maritalStatus || {});
  const [inputMaritalStatus, setInputMaritalStatus] = useState("");
  const [userMaritalStatusEdited, setUserMaritalStatusEdited] = useState(profile?.maritalStatus?.id || null);
  const [maritalStatusErrorMessage, setMaritalStatusErrorMessage] = useState();

  const [userAboutMe, setUserAboutMe] = useState(profile?.about || "");
  const [aboutMeErrorMessage, setAboutMeErrorMessage] = useState();
  const [limitMessage, setLimitMessage] = useState('');

  const [userHeight, setUserHeight] = useState(profile?.height || {});
  const [inputHeight, setInputHeight] = useState("");
  const [userHeightEdited, setUserHeightEdited] = useState(profile?.height?.id || null);
  const [heightErrorMessage, setHeightErrorMessage] = useState();

  const [childrenCount, setChildrenCount] = useState(profile?.numberOfKids || 0);
  const [childrenCountErrorMessage, setChildrenCountErrorMessage] = useState('');
  const [hasChildren, setHasChildren] = useState(profile?.hasChildren || false);

  const RequiredErrror = 'This field is required';
  const initialsRegExp = /^[a-zA-Z]*$/;
  const characterRegExp = /^[a-zA-Z0-9]+$/;
  const firstNameCharacterRegExp = /^[a-zA-Z]+$/;
  const regularExpression_badWords = /\b(crap|ugly|brat|fool|fuck(?:ing|er)?|f\*ck(?:ing|er)?|bitch|b\*tch|shit|sh\*t|dumb|couch potato|arse(?:hole)?|asshole|(\*ssh\*l\*|\*\*\*\*|c\*ck|c\*cks\*ck\*r|c\*nt|dickhead|d\*c\*h\*a\*|f\*c\*|f\*ckw\*t|fuk(?:ing)?|f\*k(?:ing)?|mother\*\*\*\*er|m\*th\*rf\*ck\*r|\*\*\*\*\*\*|n\*gg\*r|pussy|p\*ssy|wanker|w\*nk\*r|whore|wh\*r\*|slag)\b)/gi;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    handleReset();
  }, [profile, metaData, open]);

  const checkValidation = async () => {
    setInitialsErrorMessage(!initialsRegExp.test(userInitials) ? 'Only alphabetic characters allowed' : '');
    setProfileNameErrorMessage(!userProfileName ? RequiredErrror : !characterRegExp.test(userProfileName) ? 'Only numbers and alphabetic characters allowed' : '');
    setFirstNameErrorMessage(userFirstName === '' ? RequiredErrror : !firstNameCharacterRegExp.test(userFirstName) ? 'Only alphabetic characters allowed' : '');
    setChildrenCountErrorMessage(hasChildren && (childrenCount <= 0 || isNaN(childrenCount)) ? 'Children count must be greater than 0' : childrenCount > 10 ? 'You can add a maximum of 10 children' : '');
    setCreatedByErrorMessage(userCreatedByEdited === '' || (!userCreatedByEdited) ? RequiredErrror : '');
    setMaritalStatusErrorMessage(userMaritalStatusEdited === '' || (!userMaritalStatusEdited) ? RequiredErrror : '');
    setHeightErrorMessage(userHeightEdited === '' || (!userHeightEdited) ? RequiredErrror : '');

  };

  const handleEdit = async () => {
    checkValidation();
    if (userAboutMe.length > 400) {
      setAboutMeErrorMessage('Maximum character limit exceeded (400 characters)');
      return;
    }
    if (userAboutMe.trim() === '' || !regularExpression_badWords.test(userAboutMe)) {
      if (regularExpression_badWords.test(userAboutMe)) {      //handled an error happens if user click submit btn more than one time
        return;
      }
      setAboutMeErrorMessage('');
    } else {
      setAboutMeErrorMessage('Please refrain from using offensive words');
      return;
    }
    if (!initialsRegExp.test(userInitials) || !userProfileName || !characterRegExp.test(userProfileName) ||
      userFirstName === '' || !firstNameCharacterRegExp.test(userFirstName) || !userCreatedByEdited || !userMaritalStatusEdited || !userHeightEdited ||
      (hasChildren && (childrenCount <= 0 || isNaN(childrenCount))) || childrenCount > 10
    ) {
      return;
    }
    const basic = {};
    basic.createdById = userCreatedByEdited;
    basic.profileName = userProfileName;
    basic.firstName = userFirstName;
    basic.surname = userInitials || null;
    basic.dob = profile?.dob;
    basic.heightId = userHeightEdited;
    basic.genderId = profile?.gender?.id;
    basic.maritalStatusId = userMaritalStatusEdited;
    basic.hasChildren = hasChildren;
    basic.about = userAboutMe || null;
    basic.NumberOfKids = hasChildren ? childrenCount : null;
    basic.dietHabbitId = userDietHabbitEdited || null;
    basic.drinkingHabbitId = userDrinkingHabbitEdited || null;
    basic.smokingHabbitId = userSmokingHabbitEdited || null;

    setModalLoading(true);
    const response = await updateProfileBasicDetails(profile?.id, basic);
    if (response.status === 200) {
      setProfile(response.data);
      handleClose();
      swal({
        text: "Profile details will update after moderator approval.",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      });
    } else if (response.status !== 200) {
      swal({
        text: 'Unsuccessful. Try again.',
        icon: 'error',
        dangerMode: true,
      });
      console.log("error in else");
    }
    setModalLoading(false);
  };

  const handleReset = async () => {
    setModalLoading(true);
    setUserInitials(profile?.surname || '');
    setUserProfileName(profile?.profileName || '');
    setUserFirstName(profile?.firstName || '');
    setUserCreatedBy(profile?.createdBy || {});
    setUserDietHabbit(profile?.dietHabbit || {});
    setUserDrinkingHabbit(profile?.drinkingHabbit || {});
    setUserSmokingHabbit(profile?.smokingHabbit || {});
    setUserMaritalStatus(profile?.maritalStatus || {});
    setUserAboutMe(profile?.about || "");
    setUserHeight(profile?.height || {});
    setChildrenCount(profile?.numberOfKids || 0);
    setHasChildren(profile?.hasChildren || false);
    setInitialsErrorMessage('');
    setProfileNameErrorMessage('');
    setFirstNameErrorMessage('');
    setChildrenCountErrorMessage('');
    setCreatedByErrorMessage('');
    setMaritalStatusErrorMessage('');
    setHeightErrorMessage('');
    setAboutMeErrorMessage('');
    setModalLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalLoading ? <Spinner></Spinner> :
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '350px' },
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ /* styles */ }}>
                {/* Modal content */}
                <Typography className="dmsans" id="modal-modal-title" variant="h6" component="h2">
                  Update basic details.
                </Typography>
                <br />
                <div style={{ overflowY: 'scroll', height: '500px' }}>
                  <FormControl fullWidth>
                    <TextField
                      label="Profile Name"
                      value={userProfileName}
                      fullWidth
                      required
                      onChange={(e) => {
                        setUserProfileName(e.target.value);
                        setProfileNameErrorMessage(!e.target.value ? RequiredErrror : !characterRegExp.test(e.target.value) ? 'Only numbers and alphabetic characters allowed' : '');
                      }}
                      // className={classes.textField}
                      InputLabelProps={{
                        style: { fontFamily: 'DM Sans' },
                      }}
                    />
                    <p style={{ color: 'red' }}>{profileNameErrorMessage}</p>
                    <TextField
                      label="First Name"
                      value={userFirstName}
                      fullWidth
                      required
                      onChange={(e) => {
                        setUserFirstName(e.target.value);
                        setFirstNameErrorMessage(e.target.value === '' ? RequiredErrror : !firstNameCharacterRegExp.test(e.target.value) ? 'Only alphabetic characters allowed' : '');
                      }}
                      // className={classes.textField}
                      InputLabelProps={{
                        style: { fontFamily: 'DM Sans' },
                      }}
                    />
                    <p style={{ color: 'red' }}>{firstNameErrorMessage}</p>
                    <TextField
                      label="Initials"
                      fullWidth
                      value={userInitials}
                      inputProps={{ maxLength: 1 }}
                      onChange={(e) => {
                        setUserInitials(e.target.value);
                        setInitialsErrorMessage(!initialsRegExp.test(e.target.value) ? 'Only alphabetic characters allowed' : '');
                      }}
                      // className={classes.textField}
                      InputLabelProps={{
                        style: { fontFamily: 'DM Sans' },
                      }}
                    />
                    <p style={{ color: 'red' }}>{initialsErrorMessage}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.creators.find(option => option.id === userCreatedBy?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserCreatedBy(newValue);
                            setUserCreatedByEdited(newValue.id);
                            setCreatedByErrorMessage('');
                          } else {
                            setUserCreatedBy(null);
                            setUserCreatedByEdited(null);
                            setCreatedByErrorMessage(RequiredErrror);
                          }
                        }}
                        inputValue={inputCreatedBy}
                        onInputChange={(event, newInputValue) => {
                          setInputCreatedBy(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.creators}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Created By *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{createdByErrorMessage}</p>

                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.dietHabbits.find(option => option.id === userDietHabbit?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserDietHabbit(newValue);
                            setUserDietHabbitEdited(newValue.id);
                          } else {
                            setUserDietHabbit(null);
                            setUserDietHabbitEdited(null);
                          }
                        }}
                        inputValue={inputDietHabbit}
                        onInputChange={(event, newInputValue) => {
                          setInputDietHabbit(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.dietHabbits}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Diet Habbit" />}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.drinkingHabbits.find(option => option.id === userDrinkingHabbit?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserDrinkingHabbit(newValue);
                            setUserDrinkingHabbitEdited(newValue.id);
                          } else {
                            setUserDrinkingHabbit(null);
                            setUserDrinkingHabbitEdited(null);
                          }
                        }}
                        inputValue={inputDrinkingHabbit}
                        onInputChange={(event, newInputValue) => {
                          setInputDrinkingHabbit(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.drinkingHabbits}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Drink Habbit" />}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.smokingHabbits.find(option => option.id === userSmokingHabbit?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserSmokingHabbit(newValue);
                            setUserSmokingHabbitEdited(newValue.id);
                          } else {
                            setUserSmokingHabbit(null);
                            setUserSmokingHabbitEdited(null);
                          }
                        }}
                        inputValue={inputSmokingHabbit}
                        onInputChange={(event, newInputValue) => {
                          setInputSmokingHabbit(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.smokingHabbits}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Smoking Habbit" />}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.maritalStatuses.find(option => option.id === userMaritalStatus?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserMaritalStatus(newValue);
                            setUserMaritalStatusEdited(newValue.id);
                            setMaritalStatusErrorMessage('');
                          } else {
                            setUserMaritalStatus(null);
                            setUserMaritalStatusEdited(null);
                            setMaritalStatusErrorMessage(RequiredErrror);
                          }
                        }}
                        inputValue={inputMaritalStatus}
                        onInputChange={(event, newInputValue) => {
                          setInputMaritalStatus(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.maritalStatuses}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Marital Status *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{maritalStatusErrorMessage}</p>

                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.heights.find(option => option.id === userHeight?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserHeight(newValue);
                            setUserHeightEdited(newValue.id);
                            setHeightErrorMessage('');
                          } else {
                            setUserHeight(null);
                            setUserHeightEdited(null);
                            setHeightErrorMessage(RequiredErrror);
                          }
                        }}
                        inputValue={inputHeight}
                        onInputChange={(event, newInputValue) => {
                          setInputHeight(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.heights}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Height *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{heightErrorMessage}</p>
                    <FormControlLabel
                      control={<Checkbox checked={hasChildren} onChange={() => setHasChildren(!hasChildren)} />}
                      label="Has Children"
                      style={{ marginTop: '16px', marginBottom: '8px' }}
                    />
                    {hasChildren && (
                      <>
                        <TextField
                          label={childrenCount ? "" : "Number of Children"}
                          type="number"
                          fullWidth
                          value={childrenCount}
                          onChange={(e) => {
                            const childrenCount = parseInt(e.target.value);
                            setChildrenCount(childrenCount);
                            setChildrenCountErrorMessage(hasChildren && ((childrenCount <= 0) || isNaN(childrenCount)) ? 'Children count must be greater than 0' : childrenCount > 10 ? 'You can add a maximum of 10 children' : '');
                          }}
                          inputProps={{ min: 1, max: 10 }}
                          // className={classes.textField}
                          InputLabelProps={{
                            style: { fontFamily: 'DM Sans' },
                          }}
                        />
                        <p style={{ color: 'red' }}>{childrenCountErrorMessage}</p>
                      </>
                    )}
                    <TextField
                      id="filled-multiline-flexible"
                      label="About me"
                      multiline
                      inputProps={{ maxLength: 400 }}
                      value={userAboutMe}
                      fullWidth
                      onChange={(e) => {
                        const inputText = e.target.value;
                        setUserAboutMe(inputText);
                        if (inputText.length > 399) {
                          setLimitMessage("Max 400 characters");
                        } else {
                          setLimitMessage('');
                        }
                        setAboutMeErrorMessage(inputText.trim() === '' || !regularExpression_badWords.test(inputText)
                          ? '' : 'Please refrain from using offensive words'
                        );
                      }}
                      variant="filled"
                    />
                    <p style={{ color: 'black' }}>{limitMessage}</p>
                    <p style={{ color: 'red' }}>{aboutMeErrorMessage}</p>
                  </FormControl>
                </div>
                <div className="editmodal">
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleClose}>Cancel</Button>
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleReset}>Reset</Button>
                  <Button className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC' }} onClick={handleEdit}>Save</Button>
                </div>
                {/* Other modal content */}
                {/* You can use the profile object here */}
              </Box>
            </Box>
          </Box>
        }
      </Modal>
    </>
  );
};