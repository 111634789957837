import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Spinner } from "../Common/Spinner";
import Typography from '@mui/material/Typography';
import { updateProfileEducationAndProfessionDetails } from '../../Services/Profile/profileService.js';
import { useMember } from "../../Contexts/memberContext.js";
import { useApp } from "../../Contexts/appContext.js";
import Autocomplete from '@mui/material/Autocomplete';
import swal from 'sweetalert';
import {
  TextField,
  FormControl,
} from '@material-ui/core';

export const EducationProfessionEditModal = ({ open, handleClose }) => {
  const { profile, setProfile } = useMember();
  const { setUserType, metaData } = useApp();
  const [modalLoading, setModalLoading] = useState(false)

  const [userHighestQualification, setUserHighestQualification] = useState(profile?.educationAndProfession?.highestQualification || {});
  const [inputHighestQualification, setInputHighestQualification] = useState("");
  const [userHighestQualificationEdited, setUserHighestQualificationIdEdited] = useState(profile?.educationAndProfession?.highestQualification?.id || null);

  const [userJobSector, setUserJobSector] = useState(profile?.educationAndProfession?.jobSector || {});
  const [inputJobSector, setInputJobSector] = useState("");
  const [userJobSectorEdited, setUserJobSectorEdited] = useState(profile?.educationAndProfession?.jobSector?.id || null);

  const [userProfession, setUserProfession] = useState(profile?.educationAndProfession?.profession || {});
  const [inputProfession, setInputProfession] = useState("");
  const [userProfessionEdited, setUserProfessionEdited] = useState(profile?.educationAndProfession?.profession?.id || null);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    handleReset();
  }, [profile, metaData, open]);

  const handleEdit = async () => {
    const educationAndProfession = {};
    educationAndProfession.highestQualificationId = userHighestQualificationEdited;
    educationAndProfession.jobSectorId = userJobSectorEdited;
    educationAndProfession.professionId = userProfessionEdited;
    setModalLoading(true);
    const response = await updateProfileEducationAndProfessionDetails(profile?.id, educationAndProfession);
    if (response.status === 200) {
      setProfile(response.data);
      handleClose();
      swal({
        text: "Profile updated.",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      })
    } else if (response.status !== 200) {
      swal({
        text: 'Unsuccessful. Try again.',
        icon: 'error',
        dangerMode: true,
      });
    }
    setModalLoading(false);
  };

  const handleReset = async () => {
    setModalLoading(true);
    setUserHighestQualification(profile?.educationAndProfession?.highestQualification || {});
    setUserJobSector(profile?.educationAndProfession?.jobSector || {});
    setUserProfession(profile?.educationAndProfession?.profession || {});
    setModalLoading(false);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalLoading ? <Spinner></Spinner> :
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '350px' },
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ /* styles */ }}>
                {/* Modal content */}
                <Typography className="dmsans" id="modal-modal-title" variant="h6" component="h2">
                Update Education and Professional Details.
                </Typography>
                <br />
                <FormControl fullWidth>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={metaData.qualifications.find(option => option.id === userHighestQualification?.id || null) || null}
                      clearIcon={null}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setUserHighestQualification(newValue);
                          setUserHighestQualificationIdEdited(newValue.id)
                        } else {
                          setUserHighestQualification(null);
                          setUserHighestQualificationIdEdited(null)
                        }
                      }}
                      inputValue={inputHighestQualification || ''}
                      onInputChange={(event, newInputValue) => {
                        setInputHighestQualification(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={metaData.qualifications}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Education Qualification" />}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={metaData.professions.find(option => option.id === userProfession?.id || null) || null}
                      clearIcon={null}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setUserProfession(newValue);
                          setUserProfessionEdited(newValue.id)
                        } else {
                          setUserProfession(null);
                          setUserProfessionEdited(null)
                        }
                      }}
                      inputValue={inputProfession}
                      onInputChange={(event, newInputValue) => {
                        setInputProfession(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={metaData.professions}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Profession" />}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={metaData.jobSectors.find(option => option.id === userJobSector?.id || null) || null}
                      clearIcon={null}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setUserJobSector(newValue);
                          setUserJobSectorEdited(newValue.id)
                        } else {
                          setUserJobSector(null);
                          setUserJobSectorEdited(null)
                        }
                      }}
                      inputValue={inputJobSector}
                      onInputChange={(event, newInputValue) => {
                        setInputJobSector(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={metaData.jobSectors}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Job Sector" />}
                    />
                  </FormControl>
                </FormControl>
                <div className="editmodal">
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleClose}>Cancel</Button>
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleReset}>Reset</Button>
                  <Button className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC' }} onClick={handleEdit}>Save</Button>
                </div>
              </Box>
            </Box>
          </Box>
        }
      </Modal>
    </>
  );
};