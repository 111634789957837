export const searchQueryString = (obj) => {
  let queryString = "";

  for (var i in obj) {
    if (obj[i].length > 0) {
      queryString = queryString.concat(i, "=");
      for (var j in obj[i]) {
        queryString = queryString.concat(obj[i][j]);
        if (j < obj[i].length - 1) {
          queryString = queryString.concat(",");
        }
      }
      queryString = queryString.concat("&");
    } else if (obj[i].constructor === Object) {
      if (obj[i].centimetreValue) {
        queryString = queryString.concat(i, "=");
        queryString = queryString.concat(obj[i].centimetreValue, "&");
      } else {
        queryString = queryString.concat(i, "=");
        queryString = queryString.concat(obj[i].name, "&");
      }
    } else if (obj[i].constructor === Boolean) {
      queryString = queryString.concat(i, "=");
      queryString = queryString.concat(obj[i], "&");
    }
  }

  queryString = queryString.slice(0, -1);
  return queryString;
};
