export function getOppositeGender(userGender) {
  let filteredGenders = [];
  switch (userGender) {
    case 'Male':
      filteredGenders.push('Female')
      break;
    case 'Female':
      filteredGenders.push('Male')
      break;
    default:
      break;
  }
  return filteredGenders;
}
