import React, { useState } from 'react';
import { Grid, Radio, FormControlLabel, RadioGroup, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './../../CSS/ResultPage.css';
import { useApp } from "../../Contexts/appContext";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const FilterBar = ({ data, handleSearchUpdate }) => {
  const { metaData, setUserType } = useApp();
  const [maritalStatus, setMaritalStatus] = useState(data.maritalStatus || []);
  const [language, setLanguage] = useState(data.language || []);
  const [dietHabbit, setDietHabbit] = useState(data.dietHabbit || []);
  const [drinkingHabbit, setDrinkingHabbit] = useState(data.drinkingHabbit || []);
  const [smokingHabbit, setSmokingHabbit] = useState(data.smokingHabbit || []);
  const [community, setCommunity] = useState(data.community || []);
  const [cast, setCast] = useState(data.cast || []);
  const [motherTongue, setMotherTongue] = useState(data.motherTongue || []);
  const [createdBy, setCreatedBy] = useState(data.createdBy || []);
  const [residentialStatus, setResidentialStatus] = useState(
    data.residentialStatus || []
  );
  const [hasChildren, setHasChildren] = useState(data.hasChildren === true ? "has" : data.hasChildren === false ? "no" : !data.hasChildren && "both");
  const [considerCast, setConsiderCast] = useState(data.castNoBar === true ? "has" : data.castNoBar === false ? "no" : !data.castNoBar && "both");

  const [hasSelectionMaritalStatus, setHasSelectionMaritalStatus] = useState(maritalStatus.length > 0);
  const [hasSelectionLanguage, setHasSelectionLanguage] = useState(language.length > 0);
  const [hasSelectionDietHabbit, setHasSelectionDietHabbit] = useState(dietHabbit.length > 0);
  const [hasSelectionDrinkingHabbit, setHasSelectionDrinkingHabbit] = useState(drinkingHabbit.length > 0);
  const [hasSelectionSmokingHabbit, setHasSelectionSmokingHabbit] = useState(smokingHabbit.length > 0);
  const [hasSelectionCommunity, setHasSelectionCommunity] = useState(community.length > 0);
  const [hasSelectionCast, setHasSelectionCast] = useState(cast.length > 0);
  const [hasSelectionMotherTongue, setHasSelectionMotherTongue] = useState(motherTongue.length > 0);
  const [hasSelectionCreatedBy, setHasSelectionCreatedBy] = useState(createdBy.length > 0);
  const [hasSelectionResidentialStatus, setHasSelectionResidentialStatus] = useState(residentialStatus.length > 0);

  const applyFilter = async () => {
    const filteredData = data;
    filteredData.maritalStatus = maritalStatus.length > 0 ? maritalStatus : [];
    filteredData.language = language.length > 0 ? language : [];
    filteredData.dietHabbit = dietHabbit.length > 0 ? dietHabbit : [];
    filteredData.drinkingHabbit = drinkingHabbit.length > 0 ? drinkingHabbit : [];
    filteredData.smokingHabbit = smokingHabbit.length > 0 ? smokingHabbit : [];
    filteredData.cast = cast.length > 0 ? cast : [];
    filteredData.motherTongue = motherTongue.length > 0 ? motherTongue : [];
    filteredData.community = community.length > 0 ? community : [];
    filteredData.createdBy = createdBy.length > 0 ? createdBy : [];
    filteredData.residentialStatus = residentialStatus.length > 0 ? residentialStatus : [];
    if (hasChildren === 'has' || hasChildren === 'no') {
      filteredData.hasChildren = hasChildren === "has";
    }
    if (hasChildren === 'both') {
      if (filteredData.hasChildren === true || filteredData.hasChildren === false) {
        delete filteredData.hasChildren;
      }
    }
    if (considerCast === 'has' || considerCast === 'no') {
      filteredData.castNoBar = considerCast === "has";
    }
    if (considerCast === 'both') {
      if (filteredData.castNoBar === true || filteredData.castNoBar === false) {
        delete filteredData.castNoBar;
      }
    }
    handleSearchUpdate(filteredData);
  }

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };


  return (
    <Grid spacing={2} direction="column" >
      {/* Input field and button */}
      {/* <Grid item>
        <Paper style={{ padding: 16 }}>
          <TextField
            label="Input Field"
            variant="outlined"
            fullWidth
            InputProps={{ sx: { borderRadius: 30 } }}
          />
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
            <Button className="dmsans" variant="contained" fullWidth
              style={{ marginTop:'5px', borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '30px', fontSize: '10px' }}>Save Filter</Button>
          </div>
        </Paper>
      </Grid> */}

      <Grid item>
        <Button fullWidth onClick={applyFilter} className="dmsans" variant="contained"
          style={{ margin: '10px', borderRadius: '30px', backgroundColor: '#9E80BC', padding: '5px', height: '30px', fontSize: '10px', width: '-webkit-fill-available' }}>
          Apply Filters
        </Button>
      </Grid>

      <div style={{ height: '100vh', overflowY: 'scroll', margin: '10px', backgroundColor: '#F4EDFA' }}>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionMaritalStatus ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Marital Status</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.maritalStatuses &&
                    metaData.maritalStatuses.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={maritalStatus.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setMaritalStatus(prevMaritalStatus => {
                              if (checked) {
                                setHasSelectionMaritalStatus(checked);
                                return [...prevMaritalStatus, value];
                              } else {
                                const updatedStatus = prevMaritalStatus.filter(status => status !== value);
                                setHasSelectionMaritalStatus(updatedStatus.length > 0); // Update hasSelection based on whether there are any selected values
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionLanguage ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Spoken languages</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.languages &&
                    metaData.languages.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={language.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setLanguage(prevLanguageStatus => {
                              if (checked) {
                                setHasSelectionLanguage(checked);
                                return [...prevLanguageStatus, value];
                              } else {
                                const updatedStatus = prevLanguageStatus.filter(status => status !== value);
                                setHasSelectionLanguage(updatedStatus.length > 0);
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionDietHabbit ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Dietary Habit</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.dietHabbits &&
                    metaData.dietHabbits.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={dietHabbit.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setDietHabbit(prevDietHabbitsStatus => {
                              if (checked) {
                                setHasSelectionDietHabbit(checked);
                                return [...prevDietHabbitsStatus, value];
                              } else {
                                const updatedStatus = prevDietHabbitsStatus.filter(status => status !== value);
                                setHasSelectionDietHabbit(updatedStatus.length > 0);
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionDrinkingHabbit ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Drinking Habit</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.drinkingHabbits &&
                    metaData.drinkingHabbits.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={drinkingHabbit.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setDrinkingHabbit(prevDrinkHabbitStatus => {
                              if (checked) {
                                setHasSelectionDrinkingHabbit(checked);
                                return [...prevDrinkHabbitStatus, value];
                              } else {
                                const updatedStatus = prevDrinkHabbitStatus.filter(status => status !== value);
                                setHasSelectionDrinkingHabbit(updatedStatus.length > 0);
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionSmokingHabbit ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Smoking Habit</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.smokingHabbits &&
                    metaData.smokingHabbits.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={smokingHabbit.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setSmokingHabbit(prevSmokingHabbitStatus => {
                              if (checked) {
                                setHasSelectionSmokingHabbit(checked);
                                return [...prevSmokingHabbitStatus, value];
                              } else {
                                const updatedStatus = prevSmokingHabbitStatus.filter(status => status !== value);
                                setHasSelectionSmokingHabbit(updatedStatus.length > 0);
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionMotherTongue ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Mother Tongue</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.motherTongues &&
                    metaData.motherTongues.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={motherTongue.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setMotherTongue(prevMotherTongueStatus => {
                              if (checked) {
                                setHasSelectionMotherTongue(checked);
                                return [...prevMotherTongueStatus, value];
                              } else {
                                const updatedStatus = prevMotherTongueStatus.filter(status => status !== value);
                                setHasSelectionMotherTongue(updatedStatus.length > 0);
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionResidentialStatus ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Residential status</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.residentialStatuses &&
                    metaData.residentialStatuses.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={residentialStatus.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setResidentialStatus(prevResidentialStatus => {
                              if (checked) {
                                setHasSelectionResidentialStatus(checked);
                                return [...prevResidentialStatus, value];
                              } else {
                                const updatedStatus = prevResidentialStatus.filter(status => status !== value);
                                setHasSelectionResidentialStatus(updatedStatus.length > 0);
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionCommunity ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Community</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.communities &&
                    metaData.communities.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={community.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setCommunity(prevCommunityStatus => {
                              if (checked) {
                                setHasSelectionCommunity(checked);
                                return [...prevCommunityStatus, value];
                              } else {
                                const updatedStatus = prevCommunityStatus.filter(status => status !== value);
                                setHasSelectionCommunity(updatedStatus.length > 0);
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionCast ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Cast</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.casts &&
                    metaData.casts.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={cast.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setCast(prevCastStatus => {
                              if (checked) {
                                setHasSelectionCast(checked);
                                return [...prevCastStatus, value];
                              } else {
                                const updatedStatus = prevCastStatus.filter(status => status !== value);
                                setHasSelectionCast(updatedStatus.length > 0);
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Typography className="acordion_items radiobtns">Children
              <RadioGroup className="radioGroup" value={hasChildren}
                onChange={(event) => setHasChildren(event.target.value)}>
                <div className="radio"><FormControlLabel value="has" control={<Radio />} /><Typography>Has children</Typography></div>
                <div className="radio"><FormControlLabel value="no" control={<Radio />} /><Typography>No children</Typography></div>
                <div className="radio"><FormControlLabel value="both" control={<Radio />} /><Typography>Both</Typography></div>
              </RadioGroup>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="acordion_items radiobtns"> Cast Consideration
              <Tooltip
                title="Other people's consideration about your cast"
                open={open}
                onClick={handleToggle}
                arrow
                placement="top"
              >
                <IconButton onClick={handleToggle}>
                  <InfoIcon style={{ width: '20px' }} />
                </IconButton>
              </Tooltip>
              <RadioGroup className="radioGroup" value={considerCast}
                onChange={(event) => setConsiderCast(event.target.value)}>
                <div className="radio"><FormControlLabel value="has" control={<Radio />} style={{ display: 'flex', alignItems: 'center' }} />
                  <Typography>Cast no bar</Typography>
                </div>
                <div className="radio"><FormControlLabel value="no" control={<Radio />} /><Typography>Individuals who are considering cast</Typography></div>
                <div className="radio"><FormControlLabel value="both" control={<Radio />} /><Typography>Both</Typography></div>
              </RadioGroup>
            </Typography>
          </Grid>
        </div>
        <div className="onefilter" >
          <Grid item>
            <Accordion className={hasSelectionCreatedBy ? "filteraccordion borderclr" : "filteraccordion"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="acordion_items">Created by</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {metaData.creators &&
                    metaData.creators.map((field) => (
                      <div key={field.name}>
                        <Checkbox
                          value={field.name}
                          checked={createdBy.includes(field.name)}
                          onChange={(event) => {
                            const { value, checked } = event.target;
                            setCreatedBy(prevCreatedByStatus => {
                              if (checked) {
                                setHasSelectionCreatedBy(checked);
                                return [...prevCreatedByStatus, value];
                              } else {
                                const updatedStatus = prevCreatedByStatus.filter(status => status !== value);
                                setHasSelectionCreatedBy(updatedStatus.length > 0);
                                return updatedStatus;
                              }
                            });
                          }}
                        />{' '}
                        {field.name}
                        <br />
                      </div>
                    ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </div>
      </div>
    </Grid>

  );
};

export default FilterBar;