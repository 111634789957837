import '../../CSS/FooterItems.css';

const TermsAndConditions = () => {
	return (
		<div className="bottomspacedash">
			<ul className="a">
				<li>This Agreement sets out the legally binding terms for your use of the
					site and membership. This agreement may be modified from time to time.
					If we make a material change to our privacy policy, we will send email
					communication.
				</li>
			</ul>

			<ul className="b">
				<li>vivaakam.com application is a serious matchmaking platform to seek a
					life partner.</li>
				<li>You must register as a member to use this service.</li>
				<li>You confirm that the content, information including the personal
					information provided by you is correct and accurate.</li>
				<li>
					You are responsible for maintaining the confidentiality of the login
					credentials you are using, and you are solely responsible for all
					activities that occur under your account. You should immediately
					notify us of any disclosure or unauthorized use of your account or any
					other breach of security and should ensure that you log out from your
					account at the end of each session.{" "}
				</li>
				<li>
					You cannot engage in advertising or solicitation of, other members to
					buy or sell any products or services through this service.{" "}
				</li>
				<li>
					{" "}
					You will not transmit any chain letters or junk emails to other
					members. Although we cannot monitor the conduct of our members off the
					site, it is also a violation of these rules to use any information
					obtained from other members to harass, abuse, or harm another person,
					or to contact, advertise to, solicit, or sell to any member without
					their prior explicit consent.{" "}
				</li>
				{/*7*/}
				<li>
					We reserve the right to take appropriate steps to protect our members
					from any abuse/misuse as it deems appropriate in its sole discretion.{" "}
				</li>
				{/*8*/}
				<li>
					Information in the public domain such as member profile or for which
					permission has been obtained from the user, you cannot copy, modify,
					publish, transmit, distribute, perform, display, or sell any such
					proprietary information. Any such act or an attempted act on your part
					shall constitute a violation of this agreement and your membership is
					liable to be terminated. We reserve the right to take legal action
					(civil and/or criminal) wherever applicable for any violations.{" "}
				</li>
				{/*9*/}
				<li>
					You understand and hereby agree that all information, data, text,
					photographs, graphics, communications, tags, or other content whether
					publicly posted or privately transmitted, or otherwise made available
					to the site are the sole responsibility of the person from whom such
					content originated and shall be at the member's/ person's sole risks
					and consequences. {" "}
				</li>
				{/*10*/}
				<li>
					You are solely responsible for all content that you upload, post,
					email, transmit or otherwise make available via the service. We do not
					control the content posted via the service and, as such, do not
					guarantee the accuracy, integrity, or quality of such content. We
					advise all user/s to independently verify the content /information
					posted by the other members before acting upon the same and under no
					circumstances will we be liable in any way for any content, including,
					but not limited to, any errors or omissions in any content, or any
					loss or damage of any kind incurred as a result of the use of any
					content posted, emailed, transmitted or otherwise made available via
					the service.{" "}
				</li>
				{/*11*/}
				<li>
					We do not assume any responsibility or liability for any illegal
					content posted on the site by any members, users, or any third party.
					All contents amounting to child abuse, harassment, prostitution,
					pornography are strictly prohibited and all immoral and illegal acts
					and conducts including cheating and misrepresentation are deemed
					violation of the user terms and conditions. All members and users are
					subject to the criminal jurisdiction and offences and we are not
					responsible for any offence or illegal immoral act or personal injury
					or harm or damage to reputation or nuisance caused by any member to
					another member or third party in violation of the terms and conditions
					hereof and the law.{" "}
				</li>
				{/*12*/}
				<li>
					All liability, whether civil or criminal arising out of any content
					that is posted on the site will be of that member/user / third party
					who has posted such content and we reserve its right to claim damages
					from such member/ user / third party that it may suffer because of
					such content posted on the site. we do not claim ownership of content
					you submit or make available for inclusion on the service.{" "}
				</li>
				{/*13*/}
				<li>
					To withdraw this consent, you must delete your account which will
					permanently delete your data from our database after 6 months period,
					while you have the right to request for immediate permanent deletion
					upon sending an email to support@vivaakam.com. Please note, however, we
					may need to retain certain information for record-keeping purposes.{" "}
				</li>
				{/*14*/}
				<li>
					We reserve the right in its sole discretion to review the activity and
					status of each account and block access to the member based on such
					review.{" "}
				</li>
				{/*15*/}
				<li>
					If at any time vivaakam.com is of the opinion (in its sole
					discretion) or has any reason to believe that you are not eligible to
					become a member or that you have made any misrepresentation,
					vivaakam.com reserves the right to forthwith terminate your
					membership and/or your right to use the service.{" "}
				</li>
				{/*16*/}
				<li>
					By becoming a member of vivaakam.com and/or using the Services of
					the site, you unconditionally and irrevocably confirm that you have
					read and understood the above provisions and agree to abide by them.
					If you are not agreeing to any of the above terms and conditions,
					please refrain from registering on the site.{" "}
				</li>
				{/*1*/}
				<li>
					By becoming a member of vivaakam.com and/or using the Services of
					the site, you unconditionally and irrevocably confirm that you have
					read and understood the above provisions and agree to abide by them.
					If you are not agreeing to any of the above terms and conditions,
					please refrain from registering on the site.{" "}
				</li>
			</ul>
		</div>
	);
};

export default TermsAndConditions;
