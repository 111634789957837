import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Spinner } from "../Common/Spinner";
import Typography from '@mui/material/Typography';
import { updateProfilePartnerCriterionDetails } from '../../Services/Profile/profileService.js';
import { useMember } from "../../Contexts/memberContext.js";
import { useApp } from "../../Contexts/appContext.js";
import Autocomplete from '@mui/material/Autocomplete';
import swal from 'sweetalert';
import {
  TextField,
  FormControl,
} from '@material-ui/core';

export const PartnerCriteriaEditModal = ({ open, handleClose }) => {
  const { profile, setProfile } = useMember();
  const { setUserType, metaData } = useApp();
  const [modalLoading, setModalLoading] = useState(false)

  const [userMinAge, setUserMinAge] = useState(profile?.partnerCriterion?.ageLowerBound || {});
  const [inputMinAge, setInputMinAge] = useState("");
  const [userMinAgeEdited, setUserMinAgeEdited] = useState(profile?.partnerCriterion?.ageLowerBound?.id || null);

  const [userMaxAge, setUserMaxAge] = useState(profile?.partnerCriterion?.ageUpperBound || {});
  const [inputMaxAge, setInputMaxAge] = useState("");
  const [userMaxAgeEdited, setUserMaxAgeEdited] = useState(profile?.partnerCriterion?.ageUpperBound?.id || null);

  const [userMinHeight, setUserMinHeight] = useState(profile?.partnerCriterion?.heightLowerBound || {});
  const [inputMinHeight, setInputMinHeight] = useState("");
  const [userMinHeightEdited, setUserMinHeightEdited] = useState(profile?.partnerCriterion?.heightLowerBound?.id || null);

  const [userMaxHeight, setUserMaxHeight] = useState(profile?.partnerCriterion?.heightUpperBound || {});
  const [inputMaxHeight, setInputMaxHeight] = useState("");
  const [userMaxHeightEdited, setUserMaxHeightEdited] = useState(profile?.partnerCriterion?.heightUpperBound?.id || null);

  const [userHasChildren, setUserHasChildren] = useState(profile?.partnerCriterion?.hasChildren || {});
  const [inputHasChildren, setInputHasChildren] = useState("");
  const [userHasChildrenEdited, setUserHasChildrenEdited] = useState(profile?.partnerCriterion?.hasChildren?.id || null);

  const [userCreatedBy, setUserCreatedBy] = useState(profile?.partnerCriterion?.creators || []);
  const [userCountries, setUserCountries] = useState(profile?.partnerCriterion?.countries || []);
  const [userReligions, setUserReligions] = useState(profile?.partnerCriterion?.religions || []);
  const [userMotherTongues, setUserMotherTongues] = useState(profile?.partnerCriterion?.motherTongues || []);
  const [userMaritalStatuses, setUserMaritalStatuses] = useState(profile?.partnerCriterion?.maritalStatuses || []);
  const [userDietHabbits, setUserDietHabbits] = useState(profile?.partnerCriterion?.dietHabbits || []);
  const [userDrinkingHabbits, setUserDrinkingHabbits] = useState(profile?.partnerCriterion?.drinkingHabbits || []);
  const [userSmokingHabbits, setUserSmokingHabbits] = useState(profile?.partnerCriterion?.smokingHabbits || []);

  const [userJobSectors, setUserJobSectors] = useState(profile?.partnerCriterion?.jobSectors || []);
  const [userQualifications, setUserQualifications] = useState(profile?.partnerCriterion?.qualifications || []);
  const [userProfessions, setUserProfessions] = useState(profile?.partnerCriterion?.professions || []);

  const [maxAgeErrorMessage, setMaxAgeErrorMessage] = useState('');
  const [minAgeErrorMessage, setMinAgeErrorMessage] = useState('');
  const [maxAgeaxHeightErrorMessage, setMaxHeightErrorMessage] = useState('');
  const [minHeightErrorMessage, setMinHeightErrorMessage] = useState('');
  const [hasChildrenErrorMessage, setHasChildrenErrorMessage] = useState('');

  const RequiredErrror = 'This field is required';

  const checkValidation = async () => {
    setMaxAgeErrorMessage(userMaxAgeEdited === null || (!userMaxAgeEdited) ? RequiredErrror : (parseInt(userMaxAge.name) < parseInt(userMinAge.name)) ? 'Maximum age should be greater than the minimum age' : '');
    setMinAgeErrorMessage(userMinAgeEdited === null || (!userMinAgeEdited) ? RequiredErrror : (parseInt(userMinAge.name) > parseInt(userMaxAge.name) ? 'Minimum age should less than the maximum age' : ''));
    setMaxHeightErrorMessage(userMaxHeightEdited === '' || (!userMaxHeightEdited) ? RequiredErrror : (parseFloat(userMaxHeight.centimetreValue) < parseFloat(userMinHeight.centimetreValue)) ? 'Maximum height should be greater than the minimum height' : '');
    setMinHeightErrorMessage(userMinHeightEdited === '' || (!userMinHeightEdited) ? RequiredErrror : (parseFloat(userMinHeight.centimetreValue) > parseFloat(userMaxHeight.centimetreValue)) ? 'Minimum height should less than the maximum height' : '');
    setHasChildrenErrorMessage(userHasChildrenEdited === '' || (!userHasChildrenEdited) ? RequiredErrror : '');
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    handleReset();
  }, [profile, metaData, open]);

  const handleEdit = async () => {
    checkValidation();
    if (!userHasChildrenEdited || !userMinHeightEdited || !userMaxHeightEdited || !userMinAgeEdited || !userMaxAgeEdited
      || parseInt(userMaxAge.name) < parseInt(userMinAge.name) || parseInt(userMinAge.name) > parseInt(userMaxAge.name) ||
      (parseFloat(userMaxHeight.centimetreValue) < parseFloat(userMinHeight.centimetreValue)) || (parseFloat(userMinHeight.centimetreValue) > parseFloat(userMaxHeight.centimetreValue))) {
      return;
    }
    const partnerCriteria = {};
    partnerCriteria.ageLowerBoundId = userMinAgeEdited;
    partnerCriteria.ageUpperBoundId = userMaxAgeEdited;
    partnerCriteria.heightLowerBoundId = userMinHeightEdited;
    partnerCriteria.heightUpperBoundId = userMaxHeightEdited;
    partnerCriteria.hasChildrenId = userHasChildrenEdited;
    partnerCriteria.creators = userCreatedBy;
    partnerCriteria.countries = userCountries;
    partnerCriteria.dietHabbits = userDietHabbits;
    partnerCriteria.drinkingHabbits = userDrinkingHabbits;
    partnerCriteria.jobSectors = userJobSectors;
    partnerCriteria.maritalStatuses = userMaritalStatuses;
    partnerCriteria.motherTongues = userMotherTongues;
    partnerCriteria.professions = userProfessions;
    partnerCriteria.qualifications = userQualifications;
    partnerCriteria.religions = userReligions;
    partnerCriteria.smokingHabbits = userSmokingHabbits;

    setModalLoading(true);
    const response = await updateProfilePartnerCriterionDetails(profile?.id, partnerCriteria);
    if (response.status === 200) {
      setProfile(response.data);
      handleClose();
      swal({
        text: "Profile updated.",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      })
    } else if (response.status !== 200) {
      swal({
        text: 'Unsuccessful. Try again.',
        icon: 'error',
        dangerMode: true,
      });
    }
    setModalLoading(false);
  };

  const handleReset = async () => {
    setModalLoading(true);
    setUserMaxHeight(profile?.partnerCriterion?.heightUpperBound || {});
    setUserMinHeight(profile?.partnerCriterion?.heightLowerBound || {});
    setUserMaxAge(profile?.partnerCriterion?.ageUpperBound || {});
    setUserMinAge(profile?.partnerCriterion?.ageLowerBound || {});
    setUserHasChildren(profile?.partnerCriterion?.hasChildren || {});
    setUserCreatedBy(profile?.partnerCriterion?.creators || []);
    setUserCountries(profile?.partnerCriterion?.countries || []);
    setUserReligions(profile?.partnerCriterion?.religions || []);
    setUserMotherTongues(profile?.partnerCriterion?.motherTongues || []);
    setUserMaritalStatuses(profile?.partnerCriterion?.maritalStatuses || []);
    setUserSmokingHabbits(profile?.partnerCriterion?.smokingHabbits || []);
    setUserDrinkingHabbits(profile?.partnerCriterion?.drinkingHabbits || []);
    setUserDietHabbits(profile?.partnerCriterion?.dietHabbits || []);
    setUserProfessions(profile?.partnerCriterion?.professions || []);
    setUserQualifications(profile?.partnerCriterion?.qualifications || []);
    setUserJobSectors(profile?.partnerCriterion?.jobSectors || []);
    setUserHasChildrenEdited(profile?.partnerCriterion?.hasChildren?.id || null);
    setUserMaxHeightEdited(profile?.partnerCriterion?.heightUpperBound?.id || null);
    setUserMinHeightEdited(profile?.partnerCriterion?.heightLowerBound?.id || null);
    setUserMaxAgeEdited(profile?.partnerCriterion?.ageUpperBound?.id || null);
    setUserMinAgeEdited(profile?.partnerCriterion?.ageLowerBound?.id || null);
    setHasChildrenErrorMessage('');
    setMinHeightErrorMessage('');
    setMaxHeightErrorMessage('');
    setMinAgeErrorMessage('');
    setMaxAgeErrorMessage('');
    setModalLoading(false);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalLoading ? <Spinner></Spinner> :
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '350px' },
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ /* styles */ }}>
                {/* Modal content */}
                <Typography className="dmsans" id="modal-modal-title" variant="h6" component="h2">
                Update Partner Criteria Details.
                </Typography>
                <br />
                <div style={{ overflowY: 'scroll', height: '500px' }}>
                  <FormControl fullWidth>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.ages.find(option => option.id === userMinAge.id) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserMinAge(newValue);
                            setUserMinAgeEdited(newValue.id)
                            if (userMaxAge.name) {
                              if (parseInt(newValue.name) > parseInt(userMaxAge.name)) {
                                setMinAgeErrorMessage('Minimum age should less than the maximum age')
                              } else {
                                setMinAgeErrorMessage('')
                                setMaxAgeErrorMessage('')
                              }
                            } else {
                              setMinAgeErrorMessage('')
                            }
                          } else {
                            setUserMinAge({});
                            setUserMinAgeEdited(null)
                            setMinAgeErrorMessage(RequiredErrror);
                          }
                        }}
                        inputValue={inputMinAge}
                        onInputChange={(event, newInputValue) => {
                          setInputMinAge(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.ages}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Minimum Age *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{minAgeErrorMessage}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.ages.find(option => option.id === userMaxAge.id) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserMaxAge(newValue);
                            setUserMaxAgeEdited(newValue.id)
                            if (userMinAge.name) {
                              if (parseInt(newValue.name) < parseInt(userMinAge.name)) {
                                setMaxAgeErrorMessage('Maximum age should be greater than the minimum age')
                              } else {
                                setMaxAgeErrorMessage('')
                                setMinAgeErrorMessage('')
                              }
                            } else {
                              setMaxAgeErrorMessage('')
                            }
                          } else {
                            setUserMaxAge({});
                            setUserMaxAgeEdited(null)
                            setMaxAgeErrorMessage(RequiredErrror);
                          }
                        }}
                        inputValue={inputMaxAge}
                        onInputChange={(event, newInputValue) => {
                          setInputMaxAge(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.ages}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Maximum Age *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{maxAgeErrorMessage}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.heights.find(option => option.id === userMinHeight.id) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserMinHeight(newValue);
                            setUserMinHeightEdited(newValue.id)
                            if (userMaxHeight.centimetreValue) {
                              if (parseFloat(newValue.centimetreValue) > parseFloat(userMaxHeight.centimetreValue)) {
                                setMinHeightErrorMessage('Minimum height should less than the maximum height')
                              } else {
                                setMinHeightErrorMessage('')
                                setMaxHeightErrorMessage('')
                              }
                            } else {
                              setMinHeightErrorMessage('')
                            }
                          } else {
                            setUserMinHeight({});
                            setUserMinHeightEdited(null)
                            setMinHeightErrorMessage(RequiredErrror);
                          }
                        }}
                        inputValue={inputMinHeight}
                        onInputChange={(event, newInputValue) => {
                          setInputMinHeight(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.heights}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Minimum Height *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{minHeightErrorMessage}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.heights.find(option => option.id === userMaxHeight.id) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserMaxHeight(newValue);
                            setUserMaxHeightEdited(newValue.id)
                            if (userMinHeight.centimetreValue) {
                              if (parseFloat(newValue.centimetreValue) < parseFloat(userMinHeight.centimetreValue)) {
                                setMaxHeightErrorMessage('Maximum height should be greater than the minimum height')
                              } else {
                                setMaxHeightErrorMessage('')
                                setMinHeightErrorMessage('')
                              }
                            } else {
                              setMaxHeightErrorMessage('')
                            }
                          } else {
                            setUserMaxHeight({});
                            setUserMaxHeightEdited(null)
                            setMaxHeightErrorMessage(RequiredErrror)
                          }
                        }}
                        inputValue={inputMaxHeight}
                        onInputChange={(event, newInputValue) => {
                          setInputMaxHeight(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.heights}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Maximum Heights *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{maxAgeaxHeightErrorMessage}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.partnerChildrenCriteria.find(option => option.id === userHasChildren.id) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserHasChildren(newValue);
                            setUserHasChildrenEdited(newValue.id)
                            setHasChildrenErrorMessage('');
                          } else {
                            setUserHasChildren({});
                            setUserHasChildrenEdited(null)
                            setHasChildrenErrorMessage(RequiredErrror);
                          }
                        }}
                        inputValue={inputHasChildren}
                        onInputChange={(event, newInputValue) => {
                          setInputHasChildren(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.partnerChildrenCriteria}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Has Children *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{hasChildrenErrorMessage}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userCreatedBy}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserCreatedBy(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.creators.filter(option => !userCreatedBy.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Created by" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userCountries}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserCountries(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.countries.filter(option => !userCountries.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Countries" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userReligions}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserReligions(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.religions.filter(option => !userReligions.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Religions" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userMotherTongues}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserMotherTongues(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.motherTongues.filter(option => !userMotherTongues.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Mother Tongues" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userMaritalStatuses}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserMaritalStatuses(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.maritalStatuses.filter(option => !userMaritalStatuses.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Marital Statuses" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userDietHabbits}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserDietHabbits(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.dietHabbits.filter(option => !userDietHabbits.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Diet Habbits" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userDrinkingHabbits}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserDrinkingHabbits(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.drinkingHabbits.filter(option => !userDrinkingHabbits.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Drinking Habbits" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userSmokingHabbits}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserSmokingHabbits(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.smokingHabbits.filter(option => !userSmokingHabbits.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Smoking Habbits" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userQualifications}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserQualifications(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.qualifications.filter(option => !userQualifications.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Educational qualifications" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userProfessions}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserProfessions(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.professions.filter(option => !userProfessions.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Professions" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userJobSectors}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserJobSectors(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.jobSectors.filter(option => !userJobSectors.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Job sectors" />}
                      />
                    </FormControl>
                  </FormControl>
                </div>
                <div className="editmodal">
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleClose}>Cancel</Button>
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleReset}>Reset</Button>
                  <Button className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC' }} onClick={handleEdit}>Save</Button>
                </div>
              </Box>
            </Box>
          </Box>
        }
      </Modal>
    </>
  );
};