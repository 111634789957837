import http from "../httpService";

const apiEndpoint = "/members";

export function createMember(body) {
  const member = { ...body };
  return http.post(apiEndpoint, member);
}

export function getMe(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function getMemberByEmail(email) {
  return http.get(`/member-availabilities?email=${email}`);
}

export function verifyEmail(userId, token) {
  return http.post(
    `/email-verified-members?memberId=${userId}&emailVerificationToken=${token}`
  );
}

export function updateMember(body, response) {
  const member = { ...body };
  member.updatedEmail = body.email;
  member.memberId = response.id;
  return http.post("/email-updated-members", member);
}
export function getMember() {
  return http.get("/users");
}