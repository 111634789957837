import { Spinner } from "../Common/Spinner";

const MemberDashBoard = (props) => {
  return (
    <Spinner>
    </Spinner>
  );
};

export default MemberDashBoard;
