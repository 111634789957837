import React, { useContext, useEffect, useRef, useState } from "react";
import { MicrosoftLoginAgent } from "../Services/Authentication/msal";
import { getProfile } from "../Services/Profile/profileService";
import { getMetaData } from "../Services/MetaData/MetaDataServices";
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const { search } = useLocation();
  const [account, setAccount] = useState(MicrosoftLoginAgent.getAccount());
  const [metaData, setMetaData] = useState({});
  const [isLoadingMetaData, setIsLoadingMetaData] = useState(false);
  const [option, setOption] = useState("EXISTING_PROFILE");
  const [userType, setUserType] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const initialRender = useRef(true);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [path, setPath] = useState('');
  const [professionChanged, setProfessionChanged] = useState(false);
  const navigate = useNavigate();
  //authentication check
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    async function fetchData() {
      setIsLoadingMetaData(true);
      const { data: metaData } = await getMetaData();
      setMetaData(metaData);
      // Function to check if the URL has either "user-id" or "token" parameter
      const urlParams = new URLSearchParams(search);
      setPath(urlParams.has('user-id') && urlParams.has('token'));
      setIsLoadingMetaData(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsLoadingUser(true);
      if (isAuthenticated) {
        try {
          const profileResponse = await getProfile("me");
          if (profileResponse.status === 200) {
            if (profileResponse.data.contactDetails.isEmailVerified === false) {
              if (!path) {
                setUserType("NEW_REGISTERED_MEMBER");
                navigate("/verifyemail");
              }
            }
            else if (profileResponse.data.contactDetails.isEmailVerified && profileResponse.data.maritalStatus != null) {
              if (
                profileResponse.data.status === "Await" ||
                profileResponse.data.status === "Holded" ||
                profileResponse.data.isHided ||
                profileResponse.data.isSoftDeleted
              ) {
                setUserType("INACTIVE_PROFILE_USER");
                navigate("/board");
              } else {
                setUserType("ACTIVE_PROFILE_USER");
                navigate("/board");
              }
            } else {
              setUserType("MEMBER_USER");
              navigate("/profilecreation");
            }
          }
          else if (profileResponse.status === 404) {
            setUserType("NON_MEMBER_USER");
            navigate("/registration");
          } else {
            navigate("/");
          }
        } catch (err) {
          console.error("Error fetching profile:", err);
        }
      }
      setIsLoadingUser(false);
    }
    fetchData();
  }, [isAuthenticated]);
  return (
    <AppContext.Provider
      value={{
        account,
        setAccount,
        metaData,
        option,
        setOption,
        userType,
        setUserType,
        isLoadingUser,
        setIsLoadingUser,
        isLoadingMetaData,
        recaptchaToken,
        setRecaptchaToken,
        professionChanged,
        setProfessionChanged,
        isAuthenticated
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
