import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyEmail } from '../../Services/Member/memberService';
import swal from 'sweetalert';
import '../../CSS/RegistrationSuccess.css';
import tickimg from '../../Assests/imgs/tick.jpg';
import { Spinner } from "../Common/Spinner";
import Footer from "../FooterPages/Footer.js";
import AppBar from "../NavBar/AppBar.js";

function VerifyEmail() {
  const { search } = useLocation();
  const [alreadyVerified, setAlreadyVerified] = useState('');
  const [loading, setoading] = useState(true);
  const searchParam = new URLSearchParams(search);
  const userId = searchParam.get("user-id");
  const token = searchParam.get("token");
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await verifyEmail(userId, token);
        if (response.status === 201) {
          setAlreadyVerified("verified");
        } else if (response.status !== 201) {
          if (response.data.isEmailVerified[0] === "Email already verified") {
            setAlreadyVerified("alreadyVerified")
          }
        }
      } catch (err) {
        setAlreadyVerified("failed")
      }
      setoading(false)
    }
    fetchData();
  }, []);


  return (
    <div>
      <AppBar />
      <div>
        {loading ? <Spinner></Spinner> :
          <div className="verify-email-container">
            <div className="verify-email-content">
              <div className="verify-email-header">
                {(alreadyVerified === "verified" || alreadyVerified === "alreadyVerified") &&
                  <img src={tickimg} height='100px' />
                }
              </div>
              <div className="verify-email-body">
                {alreadyVerified === "verified" ?
                  <h2>Verification successful</h2> :
                  alreadyVerified === "alreadyVerified" ?
                    <h2 style={{padding:'28px'}}>Email already verified</h2> :
                    alreadyVerified === "failed" ?
                      <h2>Verification unsuccessful. Please try again by clicking the Go to  Profile button in profile verification email.</h2> :
                      <h2></h2>
                }
                {alreadyVerified === "verified" &&
                  <button onClick={() => navigate('/board')} className="btn" style={{ border: '1px solid green', borderRadius: '30px', color: 'green' }}>Okay</button>
                }
              </div>
            </div>
          </div>
        }
      </div>
      <Footer />
    </div>
  );
}

export default VerifyEmail;