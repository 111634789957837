import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../Common/Spinner';
import '../../CSS/SingleSignOn.css';
import { MicrosoftLoginAgent } from '../../Services/Authentication/msal';

export default function SingleSignOn({ handleClose }) {

  const navigate = useNavigate();

  useEffect(() => {
    // when browser back button is pressed (action = pop) and member is in initial page before login(history.length=10)
    if (navigate.action === 'POP' && window.location.href.includes('/sso', window.location.href.length - 6)) {
      navigate.goBack();
      return;
    };
    const checkForRedirect = async () => {
      return newLogin();
    };
    checkForRedirect();
  }, []);

  const newLogin = async () => {
    try {
      await MicrosoftLoginAgent.login();
      handleClose();
    } catch (error) {
      console.log("Sign in error", error);
      console.error("login failed");
    }
  };

  return (
    <div>
      <Spinner>
      </Spinner>
    </div>
  );
}
