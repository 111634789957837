import http from "../httpService";

export function getPendingRequests(id) {
  return http.get(`await-requestee-profile-expressed-interest-requests/${id}`);
}

export function getSentRequests(id) {
  return http.get(`await-requester-profile-expressed-interest-requests/${id}`);
}

export function createRequest(body) {
  return http.post(`profile-expressed-interest-requests`, body);
}
//top visitor
export function addTopVisitor(body) {
  return http.post(`expIntAudit`, body);
}
export function getTopVisitors(id) {
  return http.get(`expIntAudit/topVisitors/${id}`);

}
export function cancelRequest(id) {
  return http.delete(`profile-expressed-interest-requests/${id}`);
}

export function approveRequest(id) {
  return http.put(`approved-profile-expressed-interest-requests/${id}`);
}

export function rejectRequest(id) {
  return http.put(`rejected-profile-expressed-interest-requests/${id}`);
}

export function getAcceptedRequestsAsRequester(id) {
  return http.get(
    `approved-requester-profile-expressed-interest-requests/${id}`
  );
}

export function getAcceptedRequestsAsRequestee(id) {
  return http.get(
    `approved-requestee-profile-expressed-interest-requests/${id}`
  );
}

export function getRelationship(id, myProfileId) {
  return http.get(
    `profile-expressed-interest-request-relationships?profileId=${id}&myProfileId=${myProfileId}`
  );
}

export function disconnectRelationship(id) {
  return http.put(
    `disconnected-profile-expressed-interest-request-relationships/${id}`
  );
}

export function getRecentlyViewedProfiles(id) {
  return http.get(
    `expIntAudit/recentlyAccess/${id}`
  );
}
//get profile by id
export function getProfile(id) {
  return http.get(`/profiles/${id}`);
}
