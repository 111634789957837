const data = {
  title: "FAQs",
  rows: [
    {
      title: "How does the website work?  ",
      content:
        "The website allows registered users to create a profile, search for potential matches based on certain criteria, and initiate contact with other users.  ",
    },
    {
      title: " Is registration free?",
      content:
        "For a limited time it will be made freely available for the users.",
    },
    {
      title: "How can I search for potential matches? ",
      content:
        "You can search for potential matches using various criteria such as age, location, religion, education, and profession. ",
    },
    {
      title:
        "Can I contact other users?  ",
      content:
        "Yes, but it depends on the consent given by both parties, you must send a request first to the other party, and if they accept, you will be able to contact them.  ",
    },
    {
      title: "Can I remove my profile from the website?   ",
      content:
        "Yes, you can go to your profile and delete your profile softly. After that, you might not be able to search or use other features until you are undelete within 180 days. The profile will automatically be deleted permanently after 180 days. Otherwise, the moderator can be reached.",
    },
    {
      title: " Is the website safe and secure?   ",
      content:
        "We advise users to be cautious when it comes to sharing personal information, also to keep the login information safe and to use secure devices and to avoid public devices accessing the site.  ",
    },


  ],
};

export default data;
