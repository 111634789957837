import React, { useEffect } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useApp } from '../../Contexts/appContext';

function PrivateRoute({ currentPath }) {
  //loading user todo
  const { isAuthenticated, userType, isLoadingUser } = useApp();
  const userRole = ["MEMBER_USER", "NON_MEMBER_USER", "ACTIVE_PROFILE_USER", "INACTIVE_PROFILE_USER", "NEW_REGISTERED_MEMBER"];
  // useEffect(() => {
  //   console.log(currentPath);
  //   console.log("role", isAuthenticated);

  // }, [currentPath]);
  return (
    isAuthenticated ? (
      userRole.includes(userType) ? (
        <Outlet />
      ) : <Navigate to="/board" replace />
    ) : <Navigate to="/sso" replace />
  );
}

export default PrivateRoute;