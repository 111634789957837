import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Spinner } from "../Common/Spinner";
import Typography from '@mui/material/Typography';
import { updateProfileLocationDetails } from '../../Services/Profile/profileService.js';
import { getCitiesByState } from '../../Services/MetaData/cityService.js';
import { getStatesByCountry } from '../../Services/MetaData/stateService.js';
import { useMember } from "../../Contexts/memberContext.js";
import { useApp } from "../../Contexts/appContext.js";
import Autocomplete from '@mui/material/Autocomplete';
import swal from 'sweetalert';
import {
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';

export const LocationDetailsEditModal = ({ open, handleClose }) => {
  const { profile, setProfile } = useMember();
  const { metaData } = useApp();
  const [modalLoading, setModalLoading] = useState(false)

  const [userCountry, setUserCountry] = useState(profile?.location?.country || {});
  const [inputCountry, setInputCountry] = useState("");
  const [userCountryEdited, setUserCountryEdited] = useState(profile?.location?.country?.id || null);

  const [userResidentialStatus, setUserResidentialStatus] = useState(profile?.location?.residentialStatus || {});
  const [inputResidentialStatus, setInputResidentialStatus] = useState("");
  const [userResidentialStatusEdited, setUserResidentialStatusEdited] = useState(profile?.location?.residentialStatus?.id || null);

  const [userEhnicOrigin, setUserEhnicOrigin] = useState(profile?.location?.ethnicOrigin || {});
  const [inputEhnicOrigin, setInputEhnicOrigin] = useState("");
  const [userEhnicOriginEdited, setUserEhnicOriginEdited] = useState(profile?.location?.ethnicOrigin?.id || null);

  const [userReligion, setUserReligion] = useState(profile?.location?.religion || {});
  const [inputReligion, setInputReligion] = useState("");
  const [userReligionEdited, setUserReligionEdited] = useState(profile?.location?.religion?.id || null);

  const [userMotherTongue, setUserMotherTongue] = useState(profile?.location?.motherTongue || {});
  const [inputMotherTongue, setInputMotherTongue] = useState("");
  const [userMotherTongueEdited, setUserMotherTongueEdited] = useState(profile?.location?.motherTongue?.id || null);

  const [userCommunity, setUserCommunity] = useState(profile?.location?.community || {});
  const [inputCommunity, setInputCommunity] = useState("");
  const [userCommunityEdited, setUserCommunityEdited] = useState(profile?.location?.community?.id || null);

  const [userCast, setUserCast] = useState(profile?.location?.cast || {});
  const [inputCast, setInputCast] = useState("");
  const [userCastEdited, setUserCastEdited] = useState(profile?.location?.cast?.id || null);

  const [userSpokenLanguages, setUserSpokenLanguages] = useState(profile?.profileLanguages || []);
  const [userGrowupCountries, setUserGrowupCountries] = useState(profile?.growUpInCountries || []);
  const [castNoBar, setCastNoBar] = useState(profile?.location?.castNoBar || false);

  const [userState, setUserState] = useState(profile?.location?.state || {});
  const [inputState, setInputState] = useState("");
  const [userStateEdited, setUserStateEdited] = useState(profile?.location?.state?.id || null);

  const [userCity, setUserCity] = useState(profile?.location?.city || {});
  const [inputCity, setInputCity] = useState("");
  const [userCityEdited, setUserCityEdited] = useState(profile?.location?.city?.id || null);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryChange, setCountryChange] = useState(false);
  const [stateChange, setStateChange] = useState(false);

  const [countryErrorMessage, setCountryErrorMessage] = useState('');
  const [stateErrorMessage, setStateErrorMessage] = useState('');
  const [cityErrorMessage, setCityErrorMessage] = useState('');
  const [communityErrorMessage, setCommunityErrorMessage] = useState('');
  const [religionErrorMessage, setReligionErrorMessage] = useState('');
  const [resStatusErrorMessage, setResStatusErrorMessage] = useState('');

  const RequiredError = 'This field is required';

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    handleReset();
  }, [profile, metaData, open]);

  //Updates states based on current country
  useEffect(() => {
    async function fetchData() {
      if (userCountryEdited) {
        const { data: states } = await getStatesByCountry(userCountryEdited);
        setStates(states);
        //Reset Loading Flags
        setCountryChange(false);
      }
    }
    fetchData();
  }, [profile?.location?.country?.id, countryChange, userCountry, profile, userCountryEdited, open]);

  //Updates cities based on current state
  useEffect(() => {
    async function fetchData() {
      if (userStateEdited) {
        const { data: cities } = await getCitiesByState(userStateEdited);
        setCities(cities);
        setStateChange(false)
      }
    }
    fetchData();
  }, [profile, userState, stateChange, userCountryEdited, userStateEdited, open]);

  const checkValidation = async () => {
    setCountryErrorMessage(!userCountryEdited ? RequiredError : '');
    setStateErrorMessage(!userStateEdited ? RequiredError : '');
    setCityErrorMessage(!userCityEdited ? RequiredError : '');
    setCommunityErrorMessage(!userCommunityEdited ? RequiredError : '');
    setReligionErrorMessage(!userReligionEdited ? RequiredError : '');
    setResStatusErrorMessage(!userResidentialStatusEdited ? RequiredError : '');
  }

  const handleEdit = async () => {
    checkValidation();
    if (!userCountryEdited || !userStateEdited || !userCityEdited || !userCommunityEdited || !userReligionEdited || !userResidentialStatusEdited) {
      return;
    }

    const location = {};
    location.countryId = userCountryEdited;
    location.stateId = userStateEdited;
    location.cityId = userCityEdited;
    location.residentialStatusId = userResidentialStatusEdited;
    location.ethnicOriginId = userEhnicOriginEdited;
    location.religionId = userReligionEdited;
    location.motherTongueId = userMotherTongueEdited;
    location.communityId = userCommunityEdited;
    location.castId = userCastEdited;
    location.castNoBar = castNoBar;
    //Grown up countries mapping
    location.growUpInCountries = userGrowupCountries.map((gui) => {
      return { countryId: gui.id };
    });
    location.profileLanguages = userSpokenLanguages.map((gui) => {
      return { id: gui.id };
    });

    setModalLoading(true);
    const response = await updateProfileLocationDetails(profile?.id, location);
    if (response.status === 200) {
      setProfile(response.data);
      handleClose();
      swal({
        text: "Profile updated.",
        icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            className: 'request-button',
          },
        },
      })
    } else if (response.status !== 200) {
      swal({
        text: 'Unsuccessful. Try again.',
        icon: 'error',
        dangerMode: true,
      });
    }
    setModalLoading(false);
  };

  const handleReset = async () => {
    setModalLoading(true);
    setUserCountry(profile?.location?.country || {});
    setUserCountryEdited(profile?.location?.country?.id || null);
    setUserResidentialStatus(profile?.location?.residentialStatus || {});
    setUserResidentialStatusEdited(profile?.location?.residentialStatus?.id || null);
    setUserEhnicOrigin(profile?.location?.ethnicOrigin || {});
    setUserEhnicOriginEdited(profile?.location?.ethnicOrigin?.id || null);
    setUserReligion(profile?.location?.religion || {});
    setUserReligionEdited(profile?.location?.religion?.id || null);
    setUserMotherTongue(profile?.location?.motherTongue || {});
    setUserMotherTongueEdited(profile?.location?.motherTongue?.id || null);
    setUserCommunity(profile?.location?.community || {});
    setUserCommunityEdited(profile?.location?.community?.id || null);
    setUserCast(profile?.location?.cast || {});
    setUserCastEdited(profile?.location?.cast?.id || null);
    setUserSpokenLanguages(profile?.profileLanguages || []);
    setUserGrowupCountries(profile?.growUpInCountries || []);
    setCastNoBar(profile?.location?.castNoBar || false);
    setUserState(profile?.location?.state || {});
    setUserStateEdited(profile?.location?.state?.id || null);
    setUserCity(profile?.location?.city || {});
    setUserCityEdited(profile?.location?.city?.id || null);
    setStates([]);
    setCities([]);
    setCountryChange(true);
    setStateChange(true);
    setCountryErrorMessage('');
    setStateErrorMessage('');
    setCityErrorMessage('');
    setCommunityErrorMessage('');
    setReligionErrorMessage('');
    setResStatusErrorMessage('');
    setModalLoading(false);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalLoading ? <Spinner></Spinner> :
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '350px' },
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ /* styles */ }}>
                {/* Modal content */}
                <Typography className="dmsans" id="modal-modal-title" variant="h6" component="h2">
                Update Location Details.
                </Typography>
                <br />
                <div style={{ overflowY: 'scroll', height: '500px' }}>
                  <FormControl fullWidth>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.countries.find(option => option.id === userCountry.id) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserCountry(newValue);
                            setUserCountryEdited(newValue.id)
                            setUserState({})
                            setUserCity({})
                            setUserCityEdited(null)
                            setUserStateEdited(null)
                            setStateErrorMessage(RequiredError)
                            setCityErrorMessage(RequiredError)
                            setCountryErrorMessage('')
                          } else {
                            setUserCountry({});
                            setUserCountryEdited(null)
                            setCountryErrorMessage(RequiredError)
                          }
                        }}
                        inputValue={inputCountry}
                        onInputChange={(event, newInputValue) => {
                          setInputCountry(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.countries}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Country *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{countryErrorMessage}</p>
                    {(Object.keys(userCountry).length > 0) &&
                      <>
                        <FormControl fullWidth>
                          <Autocomplete
                            value={states.find(option => option.id === userState?.id) || null}
                            clearIcon={null}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setUserState(newValue);
                                setUserStateEdited(newValue.id)
                                setStateErrorMessage('')
                                setUserCity({})
                                setUserCityEdited(null)
                                setCityErrorMessage(RequiredError)
                              } else {
                                setUserState({});
                                setUserStateEdited(null)
                                setStateErrorMessage(RequiredError)
                              }
                            }}
                            inputValue={inputState}
                            onInputChange={(event, newInputValue) => {
                              setInputState(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={states}
                            getOptionLabel={(option) => option.name}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="State *" />}
                            onFocus={() => setCountryChange(true)}
                          />
                        </FormControl>
                        <p style={{ color: 'red' }}>{stateErrorMessage}</p>
                      </>
                    }
                    {(Object.keys(userCountry).length > 0 && Object.keys(userState).length > 0) &&
                      <>
                        <FormControl fullWidth>
                          <Autocomplete
                            value={cities.find(option => option.id === userCity?.id) || null}
                            clearIcon={null}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setUserCity(newValue);
                                setUserCityEdited(newValue.id)
                                setCityErrorMessage('')
                              } else {
                                setUserCity({});
                                setUserCityEdited(null)
                                setCityErrorMessage(RequiredError)
                              }
                            }}
                            inputValue={inputCity}
                            onInputChange={(event, newInputValue) => {
                              setInputCity(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={cities}
                            getOptionLabel={(option) => option.name}
                            sx={{ width: 300 }}
                            onFocus={() => { setCountryChange(true); setStateChange(true); }}
                            renderInput={(params) => <TextField {...params} label="City *" />}
                          />
                        </FormControl>
                        <p style={{ color: 'red' }}>{cityErrorMessage}</p>
                      </>
                    }
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.residentialStatuses.find(option => option.id === userResidentialStatus?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserResidentialStatus(newValue);
                            setUserResidentialStatusEdited(newValue.id)
                            setResStatusErrorMessage('');
                          } else {
                            setUserResidentialStatus(null);
                            setUserResidentialStatusEdited(null);
                            setResStatusErrorMessage(RequiredError);
                          }
                        }}
                        inputValue={inputResidentialStatus}
                        onInputChange={(event, newInputValue) => {
                          setInputResidentialStatus(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.residentialStatuses}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Residential Status *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{resStatusErrorMessage}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.ethnicOrigins.find(option => option.id === userEhnicOrigin?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserEhnicOrigin(newValue);
                            setUserEhnicOriginEdited(newValue.id)
                          } else {
                            setUserEhnicOrigin(null);
                            setUserEhnicOriginEdited(null)
                          }
                        }}
                        inputValue={inputEhnicOrigin}
                        onInputChange={(event, newInputValue) => {
                          setInputEhnicOrigin(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.ethnicOrigins}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Ehnic Origin" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.religions.find(option => option.id === userReligion?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserReligion(newValue);
                            setUserReligionEdited(newValue.id);
                            setReligionErrorMessage('');
                          } else {
                            setUserReligion(null);
                            setUserReligionEdited(null);
                            setReligionErrorMessage(RequiredError);
                          }
                        }}
                        inputValue={inputReligion}
                        onInputChange={(event, newInputValue) => {
                          setInputReligion(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.religions}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Religion *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{religionErrorMessage}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.motherTongues.find(option => option.id === userMotherTongue?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserMotherTongue(newValue);
                            setUserMotherTongueEdited(newValue.id)
                          } else {
                            setUserMotherTongue(null);
                            setUserMotherTongueEdited(null)
                          }
                        }}
                        inputValue={inputMotherTongue}
                        onInputChange={(event, newInputValue) => {
                          setInputMotherTongue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.motherTongues}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Mother Tongue" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.communities.find(option => option.id === userCommunity?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserCommunity(newValue);
                            setUserCommunityEdited(newValue.id);
                            setCommunityErrorMessage('');
                          } else {
                            setUserCommunity(null);
                            setUserCommunityEdited(null);
                            setCommunityErrorMessage(RequiredError);
                          }
                        }}
                        inputValue={inputCommunity}
                        onInputChange={(event, newInputValue) => {
                          setInputCommunity(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.communities}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Community *" />}
                      />
                    </FormControl>
                    <p style={{ color: 'red' }}>{communityErrorMessage}</p>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={metaData.casts.find(option => option.id === userCast?.id || null) || null}
                        clearIcon={null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setUserCast(newValue);
                            setUserCastEdited(newValue.id)
                          } else {
                            setUserCast(null);
                            setUserCastEdited(null)
                          }
                        }}
                        inputValue={inputCast}
                        onInputChange={(event, newInputValue) => {
                          setInputCast(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={metaData.casts}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Cast" />}
                      />
                    </FormControl>
                    <FormControlLabel
                      control={<Checkbox checked={castNoBar} onChange={() => setCastNoBar(!castNoBar)} />}
                      label="Cast No Bar"
                      style={{ marginTop: '16px', marginBottom: '8px' }}
                    />
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userGrowupCountries}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserGrowupCountries(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.countries.filter(option => !userGrowupCountries.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Grew Up Countries" />}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        value={userSpokenLanguages}
                        clearIcon={null}
                        onChange={(event, newValues) => {
                          setUserSpokenLanguages(newValues);
                        }}
                        id="controllable-states-demo"
                        options={metaData.languages.filter(option => !userSpokenLanguages.some(selectedOption => selectedOption.id === option.id))}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Spoken Languages" />}
                      />
                    </FormControl>
                  </FormControl>
                </div>
                <div className="editmodal">
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleClose}>Cancel</Button>
                  <Button variant="outlined" style={{ borderRadius: '30px', borderColor: '#9E80BC', color: '#9E80BC' }} onClick={handleReset}>Reset</Button>
                  <Button className="dmsans" variant="contained" style={{ borderRadius: '30px', backgroundColor: '#9E80BC' }} onClick={handleEdit}>Save</Button>
                </div>
              </Box>
            </Box>
          </Box>
        }
      </Modal>
    </>
  );
};