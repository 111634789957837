import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ResultCard from '../ResultPage/ResultCard.js';
import FilterBar from '../ResultPage/FilterBar.js';
import './../../CSS/ResultPage.css';
import { useLocation } from 'react-router-dom';
import { getSearchedProfiles } from "../../Services/Profile/profileService.js";
import { searchQueryString } from "../../Utils/searchQueryString.js";
import { useMember } from "../../Contexts/memberContext.js";
import { Spinner } from "../Common/Spinner";
import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import MobileFilter from '../ResultPage/MobileFilter.js';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Modal } from 'antd';

const ResultPage = () => {
  const location = useLocation();
  const dataSearchData = location.state.data;
  const { profile } = useMember();
  const [loading, setLoading] = useState(true);
  const [searchedProfiles, setSearchedProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const profilesPerPage = 24;

  // Logic to get current profiles
  const indexOfLastProfile = currentPage * profilesPerPage;
  const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;
  const currentProfiles = searchedProfiles.slice(indexOfFirstProfile, indexOfLastProfile);

  // Change page
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  // Function to update state with new data
  const handleSearchUpdate = (filteredData) => {
    fetchData();
  };

  const fetchData = async () => {
    setLoading(true)
    let queryString = searchQueryString(dataSearchData);
    const response = await getSearchedProfiles(profile.id, queryString);
    if (response.status === 200) {
      setSearchedProfiles(response.data);
    } else if (response.status === 404) {
      setSearchedProfiles([]);
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchData();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div  className="bottomspacedash" style={{ minHeight: '100vh' }}>
      {loading ? (<Spinner></Spinner>) : (
        <div className="topspaceres">
          <div>
            <Grid item xs={3} sx={{ display: { md: "none", lg: "none", sm: "block", xs: "block" } }}>
              {/* <>
                <Button type="primary" onClick={showModal}>
                  <FilterListIcon />
                </Button>
                <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                  <MobileFilter />
                </Modal>
              </> */}
            </Grid>
          </div>
          <>
            <Grid container spacing={2}>
              <Grid item xs={3} sx={{ display: { md: "block", lg: "block", sm: "none", xs: "none" } }}>
                <Paper style={{ padding: 16, backgroundColor: '#F4EDFA' }}>
                  <FilterBar data={dataSearchData} handleSearchUpdate={handleSearchUpdate} />
                </Paper>
              </Grid>

              {/* Right side - Takes up 9 columns */}
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <div style={{ overflowY: 'scroll' }}>
                  <Paper style={{ padding: 16 }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> */}
                    {searchedProfiles.length >= 1 ? (
                      <>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                          <Typography variant="h6">Search Results</Typography>
                          <div className="row" style={{ borderRadius: '15px', padding: '3px' }}>
                            You've got {searchedProfiles.length} match(es)
                          </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                          <div className="filter-icon">
                            <>
                              <Button type="primary" onClick={showModal}>
                                <FilterListIcon />
                              </Button>
                              <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                <FilterBar data={dataSearchData} handleSearchUpdate={handleSearchUpdate} />
                              </Modal>
                            </>
                          </div>
                        </div>

                        <Grid container spacing={2}> {/* Container for Grid */}
                          {currentProfiles.map(resultProfile => (
                            <Grid item key={resultProfile.id} xs={6} sm={4} md={3} lg={2}> {/* Grid item with specified column widths */}
                              <ResultCard resultProfile={resultProfile} />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    ) : (<Typography variant="h6">No profiles found..</Typography>)}
                    {/* </div> */}
                    <Stack spacing={2}>
                      <Pagination
                        className="pagination"
                        count={Math.ceil(searchedProfiles.length / profilesPerPage)}
                        variant="outlined"
                        page={currentPage}
                        onChange={handleChangePage}
                      />
                    </Stack>
                  </Paper>
                </div>
              </Grid>
            </Grid>
          </>
        </div>
      )}
    </div>
  );
};

export default ResultPage;